const CONTAINER_SELECTOR = '[data-passwordcheck]'
const INPUT_SELECTOR = '[data-passwordcheck-input]'
const MATCHLIST_SELECTOR = '[data-passwordcheck-matchlist]'
const MATCH_LENGTH_SELECTOR = '[data-passwordcheck-length]'
const MATCH_UPPERCASE_SELECTOR = '[data-passwordcheck-uppercase]'
const MATCH_NUMBER_SELECTOR = '[data-passwordcheck-number]'
const MATCH_SPECIAL_SELECTOR = '[data-passwordcheck-special]'
const MATCH_NON_VALID_PASSWORD_MESSAGE = '[data-non-valid-password]'
const INPUT_OPTIN_COMMUNICATION_SELECTOR = '[data-optin-Communication-input]'
const INPUT_OPTIN_CGU_SELECTOR = '[data-optin-CGU-input]'

function init() {

  const CONTAINERS = document.querySelectorAll(CONTAINER_SELECTOR)
  const MATCHLIST = document.querySelector(MATCHLIST_SELECTOR)

  if (!CONTAINERS || !MATCHLIST) return

  const MATCH_LENGTH = MATCHLIST.querySelector(MATCH_LENGTH_SELECTOR)
  const MATCH_UPPERCASE = MATCHLIST.querySelector(MATCH_UPPERCASE_SELECTOR)
  const MATCH_NUMBER = MATCHLIST.querySelector(MATCH_NUMBER_SELECTOR)
  const MATCH_SPECIAL = MATCHLIST.querySelector(MATCH_SPECIAL_SELECTOR)

  const COMMUNICATION_CHECKBOX = document.querySelector(INPUT_OPTIN_COMMUNICATION_SELECTOR)
  const CGU_CHECKBOX = document.querySelector(INPUT_OPTIN_CGU_SELECTOR)

  if (COMMUNICATION_CHECKBOX && CGU_CHECKBOX) {
    
    let timestamp = new Date
    timestamp = typeof timestamp.now === "function" ? timestamp.now : timestamp.getTime()

    COMMUNICATION_CHECKBOX.addEventListener('change', function(event) {
      COMMUNICATION_CHECKBOX.value = Math.floor(timestamp / 1000)
    })

    CGU_CHECKBOX.addEventListener('change', function(event) {
      CGU_CHECKBOX.value = Math.floor(timestamp / 1000)
    })
  }

  CONTAINERS.forEach(container => {

    const input = container.querySelector(INPUT_SELECTOR)

    input.addEventListener('keyup', function() {

      const inputValue = input.value
      MATCH_LENGTH.dataset.passwordcheckMatch = checkLength(inputValue)
      MATCH_UPPERCASE.dataset.passwordcheckMatch = checkUppercase(inputValue)
      MATCH_NUMBER.dataset.passwordcheckMatch = checkNumber(inputValue)
      MATCH_SPECIAL.dataset.passwordcheckMatch = checkSpecial(inputValue)

      if (!checkLength(inputValue) || !checkUppercase(inputValue) || !checkNumber(inputValue) || !checkSpecial(inputValue)) {
        
        input.setCustomValidity('Le mot de passe doit au moins contenir 8 caractères, une majuscule, un chiffre et un caractère spécial')

      } else {
        
        input.setCustomValidity('')

        const messages = document.querySelectorAll(MATCH_NON_VALID_PASSWORD_MESSAGE)

        messages.forEach(function(message) {
          message.setAttribute('aria-hidden', 'true')
        });

      }
    })
  })
}

function checkLength(value) {
  const lengthRegex = new RegExp('^(?=.{8,})')
  return lengthRegex.test(value) || false
}

function checkUppercase(value) {
  const uppercaseRegex = new RegExp('^(?=.*[A-Z])')
  return uppercaseRegex.test(value) || false
}

function checkNumber(value) {
  const numberRegex = new RegExp('^(?=.*[0-9])')
  return numberRegex.test(value) || false
}

function checkSpecial(value) {
  const specialRegex = new RegExp('^(?=.*(\\W|_))')
  return specialRegex.test(value) || false
}

export default { init }