var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        _vm.quiz.type === "Activity"
          ? "QuizQuestionActivity-QuizInputList"
          : "QuizInputList"
    },
    _vm._l(_vm.step.responses, function(question, index) {
      return _c(
        "div",
        {
          key: _vm.currentStepIndex + "-" + index,
          staticClass: "QuizInput QuizQuestionWrapper",
          class: "QuizInput-" + _vm.step.type
        },
        [_vm._t("default", null, { index: index })],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }