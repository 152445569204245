import Swiper from "swiper/bundle";

const CARD_SLIDER_SELECTOR = "[data-card-slider]";

function init() {
  const cardSliders = document.querySelectorAll(CARD_SLIDER_SELECTOR);

  if (!cardSliders) return;

  cardSliders.forEach(cardSlider => {
    const numberOfSlides = cardSlider.querySelectorAll(".swiper-slide").length;

    const swiper = new Swiper(cardSlider, {
      simulateTouch: true,
      spaceBetween: 20,
      pagination:
        numberOfSlides <= 1
          ? false
          : {
              el: ".swiper-pagination",
              clickable: true
            },
      keyboard: {
        enabled: true,
        onlyInViewport: false
      },
      on: {
        slideChangeTransitionEnd: function() {
          let currentElement = this.$el[0].querySelector(
            ".swiper-slide-active"
          );
          let focusableElement = currentElement.querySelector(".Cta");
          focusableElement.focus();
        }
      },
      a11y: {
        prevSlideMessage: "Slide précédent",
        nextSlideMessage: "Slide suivant"
      }
    });

    cardSlider.addEventListener("keydown", e => {
      let keyCode = e.keyCode || e.which;

      if (keyCode == 9) {
        if (e.shiftKey) {
          //Focus previous input
          if (!swiper.isBeginning) {
            e.preventDefault();
            swiper.slidePrev();
          }
        } else {
          //Focus next input
          if (!swiper.isEnd) {
            e.preventDefault();
            swiper.slideNext();
          }
        }
      }
    });
  });
}

export default { init };
