<template>
  <a class="SPFPlayer-transcription" v-if="transcriptionUrl" :href="transcriptionUrl" target="_blank" rel="nofollow"
     data-tracking='action' :data-tracking-name=" transcriptionUrl + '_#page_template#_mb'" data-tracking-chapter1='fichiers ressources'    :popin_name= " isModal ? 'popin_'+ transcriptionUrl+ '_mb' : '' " >{{ transcriptionLabel }}</a>
</template>

<script>
export default {
  name: 'Transcription',
  props: {
    eventBus: {
      required: true,
      type: Object
    },
    originTranscriptionUrl: {
      required: false,
      type: String
    },
    transcriptionLabel: {
      required: false,
      type: String,
      default: 'Transcription'
    },
    isModal: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      transcriptionUrl: ''
    }
  },
  mounted() {
    this.transcriptionUrl = this.originTranscriptionUrl
    this.eventBus.$on('Player::UpdateMedia', ({ media } = payload) => {
      this.transcriptionUrl = media.transcription_url
    })
  },
}
</script>
