var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "QuizInput-row" }, [
    _c("div", [
      _c("label", { staticClass: "QuizInput-label" }, [
        _vm._v("Nombre d'heures")
      ]),
      _vm._v(" "),
      _vm.step.type === "checkbox"
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.selectedHoursResponse,
                expression: "selectedHoursResponse"
              }
            ],
            staticClass: "QuizInput-Number",
            attrs: {
              id: "step.name" + _vm.index,
              name: _vm.step.name[_vm.index],
              min: "0",
              autocomplete: "off",
              type: "checkbox"
            },
            domProps: {
              value: _vm.step.responses[_vm.index].value,
              checked: Array.isArray(_vm.selectedHoursResponse)
                ? _vm._i(
                    _vm.selectedHoursResponse,
                    _vm.step.responses[_vm.index].value
                  ) > -1
                : _vm.selectedHoursResponse
            },
            on: {
              input: _vm.handleEmitResponse,
              change: [
                function($event) {
                  var $$a = _vm.selectedHoursResponse,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = _vm.step.responses[_vm.index].value,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.selectedHoursResponse = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.selectedHoursResponse = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.selectedHoursResponse = $$c
                  }
                },
                _vm.handleEmitResponse
              ]
            }
          })
        : _vm.step.type === "radio"
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.selectedHoursResponse,
                expression: "selectedHoursResponse"
              }
            ],
            staticClass: "QuizInput-Number",
            attrs: {
              id: "step.name" + _vm.index,
              name: _vm.step.name[_vm.index],
              min: "0",
              autocomplete: "off",
              type: "radio"
            },
            domProps: {
              value: _vm.step.responses[_vm.index].value,
              checked: _vm._q(
                _vm.selectedHoursResponse,
                _vm.step.responses[_vm.index].value
              )
            },
            on: {
              input: _vm.handleEmitResponse,
              change: [
                function($event) {
                  _vm.selectedHoursResponse =
                    _vm.step.responses[_vm.index].value
                },
                _vm.handleEmitResponse
              ]
            }
          })
        : _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.selectedHoursResponse,
                expression: "selectedHoursResponse"
              }
            ],
            staticClass: "QuizInput-Number",
            attrs: {
              id: "step.name" + _vm.index,
              name: _vm.step.name[_vm.index],
              min: "0",
              autocomplete: "off",
              type: _vm.step.type
            },
            domProps: {
              value: _vm.step.responses[_vm.index].value,
              value: _vm.selectedHoursResponse
            },
            on: {
              input: [
                function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.selectedHoursResponse = $event.target.value
                },
                _vm.handleEmitResponse
              ],
              change: _vm.handleEmitResponse
            }
          }),
      _vm._v(" "),
      !_vm.isNumberValid(_vm.selectedHoursResponse, 0, 23)
        ? _c("div", { staticClass: "error" }, [
            _vm._v("\n      chiffre invalide (entre 0 et 23)\n    ")
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("div", [
      _c("label", { staticClass: "QuizInput-label" }, [
        _vm._v("Nombre de minutes")
      ]),
      _vm._v(" "),
      _vm.step.type === "checkbox"
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.selectedMinutesResponse,
                expression: "selectedMinutesResponse"
              }
            ],
            staticClass: "QuizInput-Number",
            attrs: {
              id: "step.name" + _vm.index,
              name: _vm.step.name[_vm.index],
              min: "0",
              autocomplete: "off",
              type: "checkbox"
            },
            domProps: {
              value: _vm.step.responses[_vm.index].value,
              checked: Array.isArray(_vm.selectedMinutesResponse)
                ? _vm._i(
                    _vm.selectedMinutesResponse,
                    _vm.step.responses[_vm.index].value
                  ) > -1
                : _vm.selectedMinutesResponse
            },
            on: {
              input: _vm.handleEmitResponse,
              change: [
                function($event) {
                  var $$a = _vm.selectedMinutesResponse,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = _vm.step.responses[_vm.index].value,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        (_vm.selectedMinutesResponse = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.selectedMinutesResponse = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.selectedMinutesResponse = $$c
                  }
                },
                _vm.handleEmitResponse
              ]
            }
          })
        : _vm.step.type === "radio"
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.selectedMinutesResponse,
                expression: "selectedMinutesResponse"
              }
            ],
            staticClass: "QuizInput-Number",
            attrs: {
              id: "step.name" + _vm.index,
              name: _vm.step.name[_vm.index],
              min: "0",
              autocomplete: "off",
              type: "radio"
            },
            domProps: {
              value: _vm.step.responses[_vm.index].value,
              checked: _vm._q(
                _vm.selectedMinutesResponse,
                _vm.step.responses[_vm.index].value
              )
            },
            on: {
              input: _vm.handleEmitResponse,
              change: [
                function($event) {
                  _vm.selectedMinutesResponse =
                    _vm.step.responses[_vm.index].value
                },
                _vm.handleEmitResponse
              ]
            }
          })
        : _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.selectedMinutesResponse,
                expression: "selectedMinutesResponse"
              }
            ],
            staticClass: "QuizInput-Number",
            attrs: {
              id: "step.name" + _vm.index,
              name: _vm.step.name[_vm.index],
              min: "0",
              autocomplete: "off",
              type: _vm.step.type
            },
            domProps: {
              value: _vm.step.responses[_vm.index].value,
              value: _vm.selectedMinutesResponse
            },
            on: {
              input: [
                function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.selectedMinutesResponse = $event.target.value
                },
                _vm.handleEmitResponse
              ],
              change: _vm.handleEmitResponse
            }
          }),
      _vm._v(" "),
      !_vm.isNumberValid(_vm.selectedMinutesResponse, 0, 59)
        ? _c("div", { staticClass: "error" }, [
            _vm._v("chiffre invalide (entre 0 et 59)")
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }