import Drawer from '../../scripts/drawer' ; 
import modal from '../../embed/modal/modal';
import vueAppHandler from '../spfplayer/lib/vueAppHandler';
import "matchmedia-polyfill/matchMedia"
import "matchmedia-polyfill/matchMedia.addListener"

class glossaryDrawer extends Drawer {

  constructor(inPageButtons, drawer, drawerButton, glossaryDrawerContent, glossarydrawerTitle, glossaryRessourcesContainer) {
    super(inPageButtons, drawer, drawerButton)
    this.glossaryContent = glossaryDrawerContent
    this.glossaryTitle = glossarydrawerTitle
    this.glossaryRessourcesContainer = glossaryRessourcesContainer
  }
  
  onOpenDrawer (button) {

    this.resetGlossaryContent([
      button, this.glossaryContent, this.glossaryTitle, this.glossaryRessourcesContainer
    ])
    this.addGlossaryContent(button, this.glossaryTitle, this.glossaryContent, this.glossaryRessourcesContainer)
    this.scrollToElement(button)
    
    // prevent the prevent scroll /!\ TODO (yes i know it's dumb to it it this way)
    const bodyEl = document.querySelector('body')
    bodyEl.style.overflowY = "visible" 
  }
  
  resetGlossaryContent (glossaryElements) {
    glossaryElements.forEach(element => {
      if(element !== undefined){
        
        let elementAttr = element.hasAttribute('aria-expanded')
        if(elementAttr){
          element.setAttribute('aria-expanded', "false")
        }else{
          element.innerHTML = ''
        }

      }
    });
  }

  onCloseDrawer (button, drawer, inPageButtons) {
    const bodyEl = document.querySelector('body')
    bodyEl.removeAttribute("style")
  }

  
  addGlossaryContent (button, titleContainer, contentContainer, resourcesContainer) {
    const title = button.getAttribute('data-title')
    const content = button.getAttribute('data-content')
    const resourcesData = button.getAttribute('data-ressources')

    contentContainer.innerHTML = content
    titleContainer.innerHTML = title
    
    // because of html encoded value, we have to first decode it by trick before instering it
    if(resourcesData !== null && resourcesData !== '' && resourcesData !== undefined){
      const tempDomNode = document.createElement('textarea')
      tempDomNode.innerHTML = resourcesData
      const resources =  tempDomNode.value
      resourcesContainer.innerHTML = resources
      modal.init('[data-drawer-resources]');
      vueAppHandler.init();
    }
    
  }

  scrollToElement(button){
    const buttonPos = button.getBoundingClientRect();
    const windowHeight = window.innerHeight
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop; // getscroll position
    const buttonPosTop = buttonPos.top + scrollTop;
    const scrollToOptions = {
      left: 0,
      top: buttonPosTop - windowHeight/2,
      behavior:'smooth'
    }
    window.scrollTo(scrollToOptions);
  }
  
}

const BUTTON_INPAGE_SELECTOR = "[data-drawer-glossary-inPageBtn]";
const BUTTON_SELECTOR = "[data-drawer-btn]";
const DRAWER_SELECTOR = "[data-drawer]";

const GLOSSARY_TEXT_SELECTOR = "[data-glossary-drawer-content]";
const GLOSSARY_RESOURCES_CONTAINER_SELECTOR = "[data-drawer-resources]";
const GLOSSARY_TITLE_SELECTOR = "[data-drawer-title]";

// Get necessary element so the Drawer can be used
const inPageButtons = document.querySelectorAll(BUTTON_INPAGE_SELECTOR);
const drawerContainer = document.querySelector(DRAWER_SELECTOR)
const drawerButtons = document.querySelectorAll(BUTTON_SELECTOR)

const glossaryContent = document.querySelector(GLOSSARY_TEXT_SELECTOR)
const glossaryTitle = document.querySelector(GLOSSARY_TITLE_SELECTOR)
const glossaryRessourcesContainer = document.querySelector(GLOSSARY_RESOURCES_CONTAINER_SELECTOR)

// Create an instance of the drawerContainer
const glossaryDrawerInstance = new glossaryDrawer(inPageButtons, drawerContainer, drawerButtons, glossaryContent, glossaryTitle, glossaryRessourcesContainer)

// Export the instance 
export default glossaryDrawerInstance














