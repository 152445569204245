import Drawer from '../../scripts/drawer' ; 

class QuizDrawer extends Drawer {

  constructor(inPageButtons, drawer, drawerButton) {
    super(inPageButtons, drawer, drawerButton)
  }

  onHashChange(drawer) {

    if (location.hash === '#quiz') {
      this.openDrawer(drawer)
    }

    window.addEventListener('hashchange', (e) =>{
      if (location.hash === '#quiz') {
        this.openDrawer(drawer)
      }
    })

  }

}

const BUTTON_INPAGE_SELECTOR = "[data-drawer-quiz-inPageBtn]";
const BUTTON_SELECTOR = "[data-drawer-btn]";
const DRAWER_SELECTOR = "[data-drawer-quiz]";

// Get necessary element so the Drawer can be used
const inPageButtons = document.querySelectorAll(BUTTON_INPAGE_SELECTOR);
const drawerContainer = document.querySelector(DRAWER_SELECTOR)
const drawerButtons = document.querySelectorAll(BUTTON_SELECTOR)

// Create an instance of the drawerContainer
const quizDrawerInstance = new QuizDrawer(inPageButtons, drawerContainer, drawerButtons)
// Export the instance 
export default quizDrawerInstance