var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.transcriptionUrl
    ? _c(
        "a",
        {
          staticClass: "SPFPlayer-transcription",
          attrs: {
            href: _vm.transcriptionUrl,
            target: "_blank",
            rel: "nofollow",
            "data-tracking": "action",
            "data-tracking-name": _vm.transcriptionUrl + "_#page_template#_mb",
            "data-tracking-chapter1": "fichiers ressources",
            popin_name: _vm.isModal
              ? "popin_" + _vm.transcriptionUrl + "_mb"
              : ""
          }
        },
        [_vm._v(_vm._s(_vm.transcriptionLabel))]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }