/**
 * JS script for copy URL ro clipboard
 **/

import flashMessage from "../flashMessage/flashMessage";


const URL_BUTTON_CLIPBOARD_SELECTOR = "[data-url-to-clipboard-button]"
const urlClipboardButton = document.querySelector(URL_BUTTON_CLIPBOARD_SELECTOR)

const MOBILE_SHARE_BUTTON_SELECTOR = "[data-mobile-share-button]"
const mobileShareButton = document.querySelector(MOBILE_SHARE_BUTTON_SELECTOR)

const MOBILE_BUTTON_LIST_SELECTOR = "[data-mobile-share-button-list]"
const mobileShareButtonList = document.querySelector(MOBILE_BUTTON_LIST_SELECTOR)

const DESKTOP_BUTTON_LIST_SELECTOR  = "[data-desktop-share-button-list]"
const desktopShareButtonList = document.querySelector(DESKTOP_BUTTON_LIST_SELECTOR)

const shareData = {
  title: document.title,
  text: document.querySelector('meta[name="description"]') !== null ?
    document.querySelector('meta[name="description"]').content :
    document.title
  ,
  url: window.location.protocol + '//' + window.location.host + window.location.pathname
}

function init() {

  if (urlClipboardButton) {
    urlClipboardButton.addEventListener('click', handleUrlClipboard)
  }

  if (mobileShareButton) {
    if ( typeof navigator.canShare === "function" && typeof navigator.share === "function" && navigator.canShare(shareData) ) {
      mobileShareButton.addEventListener('click', handleMobileShare)
    } else {
      handleShareButtons()
    }
  }
}

function handleShareButtons () {
  console.log(mobileShareButton)
  mobileShareButton.addEventListener('click', (e) => {

    mobileShareButtonList.setAttribute('aria-hidden', 'true')
    desktopShareButtonList.setAttribute('aria-hidden', 'false')
  })

}

function handleUrlClipboard() {
  navigator.clipboard.writeText(shareData.url).then(
    () => {
      // case if success
      flashMessage.init({
        'for': '',
        'action': 'success',
        'msg': 'Le lien a bien été copié !',
      })
    }, () => {
      //case if failed
      flashMessage.init({
        'for': 'Copie du lien',
        'action': 'failed',
        'msg': 'Navigateur incompatible',
      })
    })
}

function handleMobileShare() {


  
  if(navigator.canShare(shareData)) {

    // Hides Share button and directly display socialMedia buttons
    mobileShareButtonList.setAttribute('aria-hidden', 'true')
    desktopShareButtonList.setAttribute('aria-hidden', 'false')

    navigator.share(
      shareData
      )
      .then(() =>
      flashMessage.init({
        'for': 'Partage du lien',
        'action': 'success',
        'msg': 'Reussie',
      })
      )
      .catch(() =>
      flashMessage.init({
        'for': 'Partage du lien',
        'action': 'failed',
        'msg': 'Appareil incompatible',
      }),
      handleShareButtons()
    )
  }else {
    handleShareButtons()
  }
}


export default { init }