var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "QuizEnd",
      class: _vm.quiz.type === "Activity" ? "QuizEnd--activity" : "QuizEnd"
    },
    [
      _vm.quiz.type === "Activity"
        ? _c("div", { staticClass: "QuizEnd-container" }, [
            _c("div", { staticClass: "QuizResults" }, [
              _c("div", { staticClass: "QuizResults-row" }, [
                _c(
                  "h2",
                  { staticClass: "QuizResults-title title2 titleBackground" },
                  [
                    _vm.quiz.quizResult
                      ? _c("span", [
                          _vm._v(
                            "\n                      " +
                              _vm._s(_vm.quiz.quizResult.render.title) +
                              "\n          "
                          )
                        ])
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _vm.quiz.quizResult.render.picture
                  ? _c("img", {
                      staticClass: "QuizResults-image",
                      attrs: {
                        src: _vm.quiz.quizResult.render.picture.desktop,
                        alt: "Résultat du quiz d'activité physique"
                      }
                    })
                  : _vm._e()
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "QuizResults-row" }, [
              _vm.quiz.quizResult
                ? _c("div", {
                    staticClass: "QuizResults-levelText Richtext",
                    attrs: { id: "lexicon_content" },
                    domProps: {
                      innerHTML: _vm._s(_vm.quiz.quizResult.render.text)
                    }
                  })
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "QuizResults" }, [
              !_vm.quiz.isConnected
                ? _c(
                    "a",
                    {
                      staticClass: "Cta Cta--dark",
                      attrs: { href: _vm.quiz.keycloakConnectionUrl }
                    },
                    [
                      _c("span", { staticClass: "Cta-label" }, [
                        _vm._v("Je me connecte pour sauvegarder mon résultat")
                      ])
                    ]
                  )
                : !_vm.quiz.quizResult.isSave
                ? _c(
                    "button",
                    {
                      staticClass: "Cta Cta--dark",
                      attrs: { "data-save-quiz": "" },
                      on: { click: _vm.saveQuiz }
                    },
                    [
                      _c("span", {
                        staticClass: "Cta-label",
                        domProps: { textContent: _vm._s(_vm.buttonText) }
                      })
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "QuizEnd-CtaContainer" }, [
                _c(
                  "button",
                  {
                    staticClass: "Cta",
                    class:
                      _vm.quiz.quizResult.isSave == true
                        ? " Cta--dark"
                        : " Cta--light",
                    on: { click: _vm.resetQuiz }
                  },
                  [
                    _c("span", { staticClass: "Cta-label" }, [
                      _vm._v("Refaire le test")
                    ])
                  ]
                )
              ])
            ])
          ])
        : _c("div", { staticClass: "QuizEnd-container" }, [
            _c("img", {
              staticClass: "QuizEnd-image",
              attrs: {
                src: _vm.assets.quizEndPicture,
                alt: "Image d'une personne "
              }
            }),
            _vm._v(" "),
            _c("h2", { staticClass: "QuizEnd-title title2 titleBackground" }, [
              _c("span", [_vm._v(_vm._s(_vm.labels.endTitle))])
            ]),
            _vm._v(" "),
            !_vm.quiz.isConnected
              ? _c("p", { staticClass: "QuizEnd-text" }, [
                  _vm._v(_vm._s(_vm.labels.endText))
                ])
              : _vm._e(),
            _vm._v(" "),
            !_vm.quiz.isConnected
              ? _c(
                  "a",
                  {
                    staticClass: "Cta Cta--dark",
                    attrs: {
                      href: _vm.keycloakAccountCreationUrl,
                      "data-tracking": "action",
                      "data-tracking-name":
                        "amorcer_creation_compte_#page_template#_mb",
                      "data-tracking-chapter1": "action mon compte mb"
                    }
                  },
                  [
                    _c("span", { staticClass: "Cta-label" }, [
                      _vm._v(_vm._s(_vm.labels.endConnectionButtonLabel))
                    ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.quiz.isConnected
              ? _c("p", { staticClass: "QuizEnd-connectionText" }, [
                  _vm._v(_vm._s(_vm.labels.endConnectionLabel))
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "Cta Cta--light",
                staticStyle: { "text-align": "center" },
                attrs: {
                  "data-quiz-apply-result": "",
                  "data-quiz-reset-btn": "",
                  "data-tracking": "action",
                  "data-tracking-name":
                    "voir_ma_selection_de_contenu_#page_template#_mb",
                  "data-tracking-chapter1": "quiz personnalisation contenu mb"
                },
                on: { click: _vm.resetSoft }
              },
              [
                _c("span", { staticClass: "Cta-label" }, [
                  _vm._v(_vm._s(_vm.labels.endButtonLabel))
                ])
              ]
            )
          ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }