/**
 * ActivityQuiz Gridcard for filter
 * Handle Gridcard for ActivityQuiz
 */

const ACTIVITYQUIZ_SELECTOR = "[quiz-filter]"

let activityQuizFilterButtons

function init() {
  activityQuizFilterButtons = document.querySelectorAll(ACTIVITYQUIZ_SELECTOR)

  if (!activityQuizFilterButtons) {
    return
  } else {
    activityQuizFilterButtons.forEach((activityQuizFilterButton) => {
      activityQuizFilterButton.addEventListener('click', (e) => {
        displayGridcardHandle(activityQuizFilterButton)
        gridcardFiltersHandle(activityQuizFilterButton)
      })
    })
  }
}

function displayGridcardHandle(activityQuizFilterButton) {

  if (activityQuizFilterButton.dataset.quizFilterIdentifier === 'No') {
    document.querySelector('#quiz-gridcard-no').style.display = 'block'
    document.querySelector('#quiz-gridcard-yes').style.display = 'none'
  } else {
    document.querySelector('#quiz-gridcard-yes').style.display = 'block'
    document.querySelector('#quiz-gridcard-no').style.display = 'none'
  }
}

function gridcardFiltersHandle(activityQuizFilterButtonActivated) {
  activityQuizFilterButtons.forEach((activityQuizFilterButton) => {
    if (activityQuizFilterButton === activityQuizFilterButtonActivated) {
      activityQuizFilterButton.classList.add('SearchResults-filter--active')
    } else {
      activityQuizFilterButton.classList.remove('SearchResults-filter--active')
    }
  })
}

export default {init}