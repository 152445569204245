/**
 * Search Loader
 * Handle home list card loading
 */

import axios from 'axios'

/**
 * Search page section
 */
const FILTERTAGS_SELECTOR = "[data-search-loader-filter]"
const FILTERTAGS_SEARCH_NAME = "[data-search-loader-name]"


/**
 *
 * CardList Section
 */
const LIST_CONTAINER_SELECTOR = '[data-search-loader-container]'
const LOAD_MORE_BUTTON_SELECTOR = '[data-search-loader-load-more]'
const LOADING_SKELETON_SELECTOR = '[data-search-laoder-loading-skeleton]'
const SEARCH_CARD_SELECTOR = '[data-search-card]'


let baseUrl = ''
let searchName = null
let filter = null

function init() {
  const loadMoreButton = document.querySelector(LOAD_MORE_BUTTON_SELECTOR)
  if (!document.querySelector(FILTERTAGS_SEARCH_NAME)) {
    return
  }
  searchName = document.querySelector(FILTERTAGS_SEARCH_NAME).dataset.searchname

  if (loadMoreButton) {
    loadMoreButton.addEventListener('click', handleLoadMoreClick)
  }

  const filterTags = document.querySelectorAll(FILTERTAGS_SELECTOR)
  if (filterTags) {
    if (filterTags.length === 1) {
      filter = filterTags[0].dataset.searchLoaderFilterIdentifier
    } else {
      filterTags.forEach(filterTag => {
        if (filterTag.classList.contains('SearchResults-filter--active')) {
          filter = filterTag.dataset.searchLoaderFilterIdentifier
        }
      });
    }
  }
}

async function handleLoadMoreClick() {
  const axiosInstance = axios.create();
  axiosInstance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

  const queryParameters = []

  const searchCards = document.querySelectorAll(SEARCH_CARD_SELECTOR)

  const idsToExclude = []
  let FAMPage = 1;
  searchCards.forEach((searchCard) => {
    if (typeof searchCard.dataset.contentId !== 'undefined') {
      idsToExclude.push(searchCard.dataset.contentId)
    } else if (typeof searchCard.dataset.recipePage !== 'undefined') {
      FAMPage = searchCard.dataset.recipePage
    }
  })

  queryParameters.push(`ajax=true`)

  if (searchName) {
    queryParameters.push(`searchName=` + searchName)
  }

  queryParameters.push(`filter=` + filter)

  const idsToExcludeString = idsToExclude.join(',')
  queryParameters.push(`idsToExclude[]=` + idsToExcludeString)

  queryParameters.push(`FAMPage=` + FAMPage)

  const queryString = queryParameters.join('&')

  let url = baseUrl

  if (queryString !== "") {
    url = `${baseUrl}?${queryString}`
  }

  const currentResults = document.querySelector(LIST_CONTAINER_SELECTOR).innerHTML
  document.querySelector(LIST_CONTAINER_SELECTOR).innerHTML += document.querySelector(LOADING_SKELETON_SELECTOR).innerHTML

  const response = await axiosInstance.get(url)

  if (response.data.last_page) {
    document.querySelector(LOAD_MORE_BUTTON_SELECTOR).setAttribute('aria-hidden', true)
  }

  if (response.data === 'no_more_results') {
    document.querySelector(LOAD_MORE_BUTTON_SELECTOR).setAttribute('aria-hidden', true)
    document.querySelector(LIST_CONTAINER_SELECTOR).innerHTML = currentResults
  } else {
    document.querySelector(LIST_CONTAINER_SELECTOR).innerHTML = currentResults + response.data.content
  }
}

export default {init}