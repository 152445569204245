<template>
  <div class="QuizQuestionActivity">

    <QuizCta
      :isNext="false"
      :labels="labels"
      :currentStepIndex="currentStepIndex"
      :steps="steps"
      :selectedResponse="selectedResponse"
      @QuizPrevious::previous-step="handlePreviousStep"
    />

    <QuizHeader :step="step" />
          
      <div :class="quiz.type === 'Activity' ?'QuizQuestionActivity-QuizInputList' : 'QuizInputList' ">

        <div
          v-for="(question, index) in step.responses"
          :key="`${currentStepIndex}-${index}`"
          :class="`QuizInput-${step.type}`" class="QuizInput QuizQuestionWrapper"
        >

        <component
          :is="getcomponentType"
          :step="step"
          :currentStepIndex="currentStepIndex"
          :index="index"
          @QuizRadio::handle-selected-response="handleSelectedResponse"
          @QuizCheckbox::handle-selected-response="handleSelectedResponse"
          @QuizSelect::handle-selected-response="handleSelectedResponse"
          @QuizNumber::handle-selected-response="handleSelectedResponse"
        ></component>

      </div>
    </div>


    <div class="QuizQuestionActivity-footer">
      <QuizCta
        :isNext="true"
        :labels="labels"
        :steps="steps"
        :quiz="quiz"
        :currentStepIndex="currentStepIndex"
        :selectedResponse="selectedResponse"
        @QuizNext::next-step="handleQuestionSubmit"
      />
    </div> 

    <QuizQuote v-if="step.quote" :step="step" />
    
  </div>
</template>

<script>
import QuizCta from '../QuizCta/QuizCta.vue'
import QuizQuote from '../QuizQuote/QuizQuote.vue'
import QuizHeader from '../QuizHeader/QuizHeader.vue'
import QuizQuestionWrapper from '../QuizQuestionWrapper/QuizQuestionWrapper.vue'
import QuizRadio from '../QuizRadio/QuizRadio.vue'
import QuizSelect from '../QuizSelect/QuizSelect.vue'
import QuizNumber from '../QuizNumber/QuizNumber.vue'

export default {
  name: "QuizQuestionActivity",
  components:{
    QuizCta,
    QuizQuote,
    QuizHeader,
    QuizQuestionWrapper,
    QuizRadio,
    QuizSelect,
    QuizNumber
  },
  props:{
    step: {
      required: true,
      type: Object
    },
    steps: {
      required: true,
      type: Array
    },
    currentStepIndex: {
      required: true,
      type: Number
    },
    labels: {
      required: true,
      type: Object
    },
    quiz:{
      required: true,
      type: Object,
    },
  },
  data(){
    return {
      selectedResponse: [],
    }
  },
  computed:{
    getcomponentType: function() {
      // Capitalize the type to match the component name
      return `Quiz${this.step.type.charAt(0).toUpperCase() + this.step.type.slice(1)}`
    }
  },
  methods:{
    handleQuestionSubmit() {
      this.handleNextStep()
      this.handleStageAnswer()
    },
    handleNextStep() {
      if(typeof this.selectedResponse !== 'undefined'){
        this.$emit("QuizQuestion::next-step", this.selectedResponse)
      }
    },
    handlePreviousStep() {
      this.$emit("QuizQuestion::previous-step")
    },
    resetQuestionValue() {
      this.selectedResponse = []
    },
    handleStageAnswer() {
      if(this.selectedResponse !== [] || typeof this.selectedResponse !== 'object'){
        this.$emit('QuizQuestion::on-stage-answer', this.selectedResponse)
        this.resetQuestionValue()
      }
    },
    handleSelectedResponse(value) {
      this.selectedResponse = value ;
    }
  },
}
</script>