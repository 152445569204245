import { render, staticRenderFns } from "./Description.vue?vue&type=template&id=39a292ad&"
import script from "./Description.vue?vue&type=script&lang=js&"
export * from "./Description.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/production/spf-manger-bouger-ezplatform/src/AppBundle/Resources/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('39a292ad')) {
      api.createRecord('39a292ad', component.options)
    } else {
      api.reload('39a292ad', component.options)
    }
    module.hot.accept("./Description.vue?vue&type=template&id=39a292ad&", function () {
      api.rerender('39a292ad', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/spfplayer/lib/Description.vue"
export default component.exports