function init() {
  clickExitInit()
  clickActionInit()
  clickNavigationInit()
  clickDownloadInit()
}

// get query from url
function getFilterFromUrl(){
  const urlSearchParams = new URLSearchParams(window.location.search);
  return Object.fromEntries(urlSearchParams.entries())
}

function trackingMethod(selector,type){
  selector.forEach((select) => {
    select.addEventListener('click', (e) => {

      let trackingNameAttribut = select.getAttribute('data-tracking-name');
      let trackingChapter1 = select.getAttribute('data-tracking-chapter1');
      let finalTrackingChapter1 = trackingChapter1 ? trackingChapter1 : tc_vars.page_cat1;

      let trackingChapter2 = select.getAttribute('data-tracking-chapter2');
      let finalTrackingChapter2 = trackingChapter2 ? trackingChapter2 : tc_vars.page_cat2;

      let trackingChapter3 = select.getAttribute('data-tracking-chapter3');
      let finalTrackingChapter3 = trackingChapter3 ? trackingChapter3 : tc_vars.page_name;

      let trackingName = trackingNameAttribut.replace("#page_template#", tc_vars.page_template)
      select.setAttribute('data-tracking-name',trackingName);

      // catalogue d'activités physique
      if(trackingChapter1 === "filtre mb"){
        var homeSelector = "data-home-loader-filter"
        let isHome = select.hasAttribute(homeSelector)

        tc_vars.contains_filter_infos = true

        if(isHome){
          var filter = select.getAttribute('data-filter-type')

          tc_vars.list_filters = {"filter": filter};
        } else {
          tc_vars.list_filters = getFilterFromUrl();
        }

        tC.container.reload({events: {pageLoad: [{},{}]}});
      }

      if(select.getAttribute('popin_name')){
        let popin = select.getAttribute('popin_name');

        console.log({
          elem: this,
          'popin_name': popin,
          'click_name' : trackingName,
          'click_type' : type,
          'chapter1' : finalTrackingChapter1,
          'chapter2' : finalTrackingChapter2,
          'chapter3' : finalTrackingChapter3
        });

        return tC.event.popin(this,{
          elem: this,
          'popin_name': popin,
          'click_name' : trackingName,
          'click_type' : type,
          'chapter1' : finalTrackingChapter1,
          'chapter2' : finalTrackingChapter2,
          'chapter3' : finalTrackingChapter3
        })
      }else{

        console.log({
          elem: this,
          'click_name' : trackingName,
          'click_type' : type,
          'chapter1' : finalTrackingChapter1,
          'chapter2' : finalTrackingChapter2,
          'chapter3' : finalTrackingChapter3
        });

        return tC.event.click(this,{
          elem: this,
          'click_name' : trackingName,
          'click_type' : type,
          'chapter1' : finalTrackingChapter1,
          'chapter2' : finalTrackingChapter2,
          'chapter3' : finalTrackingChapter3
        })
      }
    })
  })
}


function clickExitInit(){
  const allExitBtns = document.querySelectorAll('[data-tracking="exit"]');
  trackingMethod(allExitBtns, "exit");
}

function clickActionInit(){
  const allActionBtns = document.querySelectorAll('[data-tracking="action"]');
  trackingMethod(allActionBtns, "action");
}

function clickNavigationInit(){
  const allNavigationBtns = document.querySelectorAll('[data-tracking="navigation"]');
  trackingMethod(allNavigationBtns,"navigation");
}

function clickDownloadInit(){
  const allDownloadBtns = document.querySelectorAll('[data-tracking="download"]');
  trackingMethod(allDownloadBtns,"download");
}


export default { init }
export { clickActionInit, clickNavigationInit }