<template>
  <div>
    <input
      class="QuizInput-inputCheckbox"
      :type="step.type"
      :id="`step.name${index}`"
      :name="`step.name${index}`"
      :value="step.responses[index].value"
      v-model="selectedResponse"
      @change="handleEmitResponse"
    >
    <label
      class="QuizInput-label"
      :for="`step.name${index}`"
    >{{ step.responses[index].label }}</label>
  </div> 
</template>

<script>
export default {
  name: "QuizCheckbox",
  props:{
    step: {
      required: true,
      type: Object
    },
    index: {
      required: true,
      type: Number
    },
  },
  data(){
    return {
      selectedResponse: []
    }
  },
  methods:{
    handleEmitResponse() {
      this.$emit('QuizCheckbox::handle-selected-response', this.step.responses[this.index].value)
    }
  },
}
</script>