const PASSWORD_SELECTOR = '[data-passwordsimilarity-password]'
const CONFIRM_PASSWORD_SELECTOR = '[data-passwordsimilarity-confirmpassword]'
const MESSAGE_SELECTOR = '[data-passwordsimilarity-message]'

function init() {

  const password = document.querySelector(PASSWORD_SELECTOR)
  const confirmPassword = document.querySelector(CONFIRM_PASSWORD_SELECTOR)
  const errorMessage = document.querySelector(MESSAGE_SELECTOR)

  if (!password || !confirmPassword) return


  confirmPassword.addEventListener('blur', function() {

    const isSimilar = checkSimilarityPassword(password.value, confirmPassword.value)

    if (isSimilar === false) {

      confirmPassword.setCustomValidity('Les mots de passe ne sont pas identiques')

    } else {

      confirmPassword.setCustomValidity('')

    }
    
    errorMessage.setAttribute('aria-hidden', isSimilar)

  })

}

function checkSimilarityPassword(firstPassword, secondPassword) {
  return (firstPassword == secondPassword)
}

export default { init }