/**
 * Filtered List Loader
 * Handle list with filters and load more
 */

import axios from 'axios'

const FILTERS_CONTAINER_SELECTOR = '[data-list-loader-filters]'
const FILTER_CHECKBOX_SELECTOR = '[data-list-loader-filter]'
const LIST_CONTAINER_SELECTOR = '[data-list-loader-container]'
const LOAD_MORE_BUTTON_SELECTOR = '[data-list-loader-load-more]'
const LOADING_SKELETON_SELECTOR = '[data-list-laoder-loading-skeleton]'
const FILTER_COUNT_SELECTOR = '[data-list-loader-filter-count]'
const FILTERS_DRAWER_BUTTON = '[data-drawer-filter-inpagebtn]'

let activeFilters = {}
let page = 2
let baseUrl = ''

let intensityArray = []
const lowLabel = 'Intensité modérée'
const mediumLabel = 'Intensité élevée'
const highLabel = 'Intensité très élevée'
const filteredContainerAttr = 'data-activity-gridcard'


function init() {
  const listFilterContainer = document.querySelector(FILTERS_CONTAINER_SELECTOR)

  if (listFilterContainer) {
    baseUrl = listFilterContainer.dataset.listLoaderUrl
    activeFilters = JSON.parse(listFilterContainer.dataset.listLoaderActiveFilters)

    setFilterCount()

    initListFilters(listFilterContainer)

    const loadMoreButton = document.querySelector(LOAD_MORE_BUTTON_SELECTOR)

    initListLoadMore(loadMoreButton)
    reloadList()
  }

}

function initListFilters(listFilterContainer) {
  const filters = listFilterContainer.querySelectorAll(FILTER_CHECKBOX_SELECTOR)

  initIntensityArray(filters, intensityArray)


  filters.forEach(filter => {
    filter.addEventListener('click', handleFilterClick)
  })

  if (filters.length === 0) {
    document.querySelector(FILTERS_DRAWER_BUTTON).setAttribute('aria-hidden', true)
  }
}

function initIntensityArray(filters, intensityArray) {

  let isChecked, isLow, isMedium, isHigh

  filters.forEach(filter => {
    isChecked = filter.checked
    isLow = filter.value === lowLabel
    isMedium = filter.value === mediumLabel
    isHigh = filter.value === highLabel

    if (isChecked) {
      if (isLow || isMedium || isHigh) {
        intensityArray.push(filter.value)
      }
    }
  });
}

function handleFilterClick(clickedFilter) {
  // display load more button in case it was previously hidden
  document.querySelector(LOAD_MORE_BUTTON_SELECTOR).removeAttribute('aria-hidden')
  // reset to first page on new filter
  page = 2

  const filterName = clickedFilter.target.name
  const filterValue = clickedFilter.target.value

  if (clickedFilter.target.checked) {
    activeFilters[filterName] ? activeFilters[filterName].push(filterValue) : activeFilters[filterName] = [filterValue]
  } else {

    activeFilters[filterName] = activeFilters[filterName].filter(filterInArray => {
      return filterInArray !== filterValue
    })

    if (activeFilters[filterName].length === 0) {
      delete activeFilters[filterName]
    }
  }

  handleIntensityArray(clickedFilter, filterName, filterValue)
  setFilterCount()
  reloadList()
}

function handleIntensityArray(clickedFilter, filterName, filterValue) {
  const filterIntensityName = 'intensity'

  if (filterName === filterIntensityName) {
    if (clickedFilter.target.checked) {
      addToArray(filterValue)
    } else {
      removeFromArray(filterValue)
    }
  }
}

function addToArray(element) {
  intensityArray.push(element)
}

function removeFromArray(element) {
  if (intensityArray.includes(element)) {
    for (let i = 0; i < intensityArray.length; i++) {

      if (intensityArray[i] === element) {
        intensityArray.splice(i, 1);
      }

    }
  }
}

async function reloadList() {
  const axiosInstance = axios.create();
  axiosInstance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

  const queryParameters = ['ajax=true']

  for (const filterName in activeFilters) {
    queryParameters.push(`${encodeURIComponent(filterName)}=${encodeURIComponent(activeFilters[filterName].join(','))}`)
  }

  const queryString = queryParameters.join('&')

  let url = baseUrl

  if (queryString !== "") {
    url = `${baseUrl}?${queryString}`
  }

  document.querySelector(LIST_CONTAINER_SELECTOR).innerHTML = document.querySelector(LOADING_SKELETON_SELECTOR).innerHTML

  const response = await axiosInstance.get(url)

  window.history.pushState({}, '', url.replace('ajax=true', ''));

  if (response.data.no_more_results === true) {
    document.querySelector(LOAD_MORE_BUTTON_SELECTOR).setAttribute('aria-hidden', true)
  } else {
    document.querySelector(LOAD_MORE_BUTTON_SELECTOR).setAttribute(
      'aria-hidden',
      response.data.last_page ?? false
    )
  }
  document.querySelector(LIST_CONTAINER_SELECTOR).innerHTML = typeof response.data.content !== 'undefined' ?
    response.data.content :
    document.querySelector(LIST_CONTAINER_SELECTOR).innerHTML
  ;

  if (typeof response.data.content === 'undefined') {
    document.querySelector(LOAD_MORE_BUTTON_SELECTOR).setAttribute('aria-hidden', true)
    document.querySelector(LIST_CONTAINER_SELECTOR).innerHTML = '<h3 class="title2 titleBackground" style="width: 100%;text-align: center;padding-left: 40px;"><span>OUPS !</span></h3><p class="Gridcard-emptyText">Aucune activité ne correspond</p>'
  }

  const gridCardFiltered = document.querySelector('[data-activity-gridcard]')

  if (gridCardFiltered) {
    setLowerIntensityData(gridCardFiltered, intensityArray)
  }
}

function setLowerIntensityData(filteredContainer, tab) {

  if (tab.length > 1) {
    if (tab.includes(lowLabel) && tab.includes(mediumLabel) && tab.includes(highLabel)) {
      filteredContainer.setAttribute(filteredContainerAttr, 'low')
    }
    if (tab.includes(lowLabel) && tab.includes(mediumLabel) && !tab.includes(highLabel)) {
      filteredContainer.setAttribute(filteredContainerAttr, 'low')
    }
    if (tab.includes(lowLabel) && tab.includes(highLabel) && !tab.includes(mediumLabel)) {
      filteredContainer.setAttribute(filteredContainerAttr, 'low')
    }
    if (tab.includes(mediumLabel) && tab.includes(highLabel) && !tab.includes(lowLabel)) {
      filteredContainer.setAttribute(filteredContainerAttr, 'medium')
    }
  } else if (tab.length == 1) {
    if (intensityArray[0] === lowLabel) {
      filteredContainer.setAttribute(filteredContainerAttr, 'low')
    }
    if (intensityArray[0] === mediumLabel) {
      filteredContainer.setAttribute(filteredContainerAttr, 'medium')
    }
    if (intensityArray[0] === highLabel) {
      filteredContainer.setAttribute(filteredContainerAttr, 'high')
    }
  }
}

function initListLoadMore(loadMoreButton) {
  loadMoreButton.addEventListener('click', handleLoadMoreClick)
}

async function handleLoadMoreClick() {
  const axiosInstance = axios.create();
  axiosInstance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

  const queryParameters = []

  for (const filterName in activeFilters) {
    queryParameters.push(`${encodeURIComponent(filterName)}=${encodeURIComponent(activeFilters[filterName].join(','))}`)
  }

  queryParameters.push(`page=${page}`)
  queryParameters.push(`ajax=true`)

  const queryString = queryParameters.join('&')

  let url = baseUrl

  if (queryString !== "") {
    url = `${baseUrl}?${queryString}`
  }

  const currentResults = document.querySelector(LIST_CONTAINER_SELECTOR).innerHTML
  document.querySelector(LIST_CONTAINER_SELECTOR).innerHTML += document.querySelector(LOADING_SKELETON_SELECTOR).innerHTML

  const response = await axiosInstance.get(url)

  if (response.data === 'no_more_results') {
    document.querySelector(LOAD_MORE_BUTTON_SELECTOR).setAttribute('aria-hidden', true)
    document.querySelector(LIST_CONTAINER_SELECTOR).innerHTML = currentResults
  } else {
    page++

    if (response.data.last_page) {
      document.querySelector(LOAD_MORE_BUTTON_SELECTOR).setAttribute('aria-hidden', true)
    }

    document.querySelector(LIST_CONTAINER_SELECTOR).innerHTML = currentResults + response.data.content
  }
}

function setFilterCount() {
  const filterCount = document.querySelector(FILTER_COUNT_SELECTOR)

  if (filterCount) {
    const count = Object.values(activeFilters).reduce((accumulator, currentValue) => {
      return parseInt(accumulator) + parseInt(currentValue.length)
    }, 0)

    filterCount.innerHTML = count

    if (count > 0) {
      filterCount.setAttribute('aria-hidden', false)
    } else {
      filterCount.setAttribute('aria-hidden', true)

    }

  }
}

export default {init}