<template>
  <div>

    <label class="QuizInput-selectLabel" :for="`step.name${index}`">Nombre de jours</label>

    <select
      class="QuizInput-selectInput"
      :name="step.name"
      :id="`step.name${index}`"
      v-model="selectedResponse"
      @change="handleEmitResponse"
    >

      <option value="" selected disabled>Choisir Une option</option>

      <option
        v-for="(option, index) in step.options"
        :key="`${currentStepIndex}-${index}`"
        :value="option.value"
      >{{option.label}}</option>

    </select>
  </div> 

</template>

<script>
export default {
  name: "QuizSelect",
  props:{
    step: {
      required: true,
      type: Object
    },
    index: {
      required: true,
      type: Number
    },
    currentStepIndex: {
      required: true,
      type: Number
    },
  },
  data(){
    return {
      selectedResponse: ''
    }
  },
  methods:{
    handleEmitResponse() {
      if(this.selectedResponse.length){
        this.$emit('QuizSelect::handle-selected-response', this.selectedResponse)
      }
    }
  },
}
</script>