<template>
  <div class="QuizQuestion">
    <div class="QuizQuestion-container">
      <div class="QuizQuestion-left">

        <QuizCta
          :isNext="false"
          :labels="labels"
          :currentStepIndex="currentStepIndex"
          :steps="steps"
          :selectedResponse="selectedResponse"
          @QuizPrevious::previous-step="handlePreviousStep"
        />

        <figure class="QuizQuestion-image" :class="{ 'QuizQuestion-image--standalone' : currentStepIndex == 0 }">
          <img :src="step.picture" :alt="step.pictureDesc">
        </figure>

      </div>

      <div class="QuizQuestion-right">

        <h3 class="QuizQuestion-title title3 titleBackground">
          <span>{{step.title}}</span>
        </h3>
      
        <p class="QuizQuestion-text">{{step.text}}</p>


        <!-- Remove the QuizQuestionWrapper -->
        <div :class="quiz.type === 'Activity' ?'QuizQuestionActivity-QuizInputList' : 'QuizInputList' ">
          <div
            v-for="(question, index) in step.responses"
            :key="`${currentStepIndex}-${index}`"
            :class="`QuizInput-${step.type}`" class="QuizInput QuizQuestionWrapper"
          >
            <component
              :is="questionType"
              :step="step"
              :index="index"
              @QuizRadio::handle-selected-response="handleSelectedResponse"
              @QuizCheckbox::handle-selected-response="handleSelectedResponse"
            ></component>
          </div>
        </div>

        <div class="QuizQuestion-footer">
          <QuizCta
            :isNext="true"
            :labels="labels"
            :steps="steps"
            :quiz="quiz"
            :currentStepIndex="currentStepIndex"
            :selectedResponse="selectedResponse"
            @QuizNext::next-step="handleQuestionSubmit"
          />
        </div>
      </div>

    </div>


  </div>
</template>

<script>

import QuizCta from '../QuizCta/QuizCta.vue'
import QuizQuestionWrapper from '../QuizQuestionWrapper/QuizQuestionWrapper.vue'
import QuizRadio from '../QuizRadio/QuizRadio.vue'
import QuizCheckbox from '../QuizCheckbox/QuizCheckbox.vue'

export default {
  name: "QuizQuestion",
  components:{
    QuizCta,
    QuizQuestionWrapper,
    QuizRadio,
    QuizCheckbox
  },
  props:{
    step: {
      required: true,
      type: Object
    },
    steps: {
      required: true,
      type: Array
    },
    currentStepIndex: {
      required: true,
      type: Number
    },
    labels: {
      required: true,
      type: Object
    },
    quiz:{
      required: true,
      type: Object,
    },
  },
  data(){
    return {
      selectedResponse: [],
      tabResponse: [],
      questionType: this.step.type === "radio" ? "QuizRadio" : "QuizCheckbox" 
    }
  },
  methods:{
    handleQuestionSubmit() {
      this.handleNextStep()
      this.handleStageAnswer()
    },
    handleNextStep() {
      if(this.selectedResponse.length || Object.keys(this.selectedResponse).length ){
        this.$emit("QuizQuestion::next-step", this.selectedResponse)
      }
    },
    handlePreviousStep() {
      this.$emit("QuizQuestion::previous-step")
    },
    resetQuestionValue() {
      this.selectedResponse = []
    },
    handleStageAnswer() {
      if (this.selectedResponse.length) {
        this.$emit('QuizQuestion::on-stage-answer', this.selectedResponse)
        this.resetQuestionValue()
      }
    },
    handleSelectedResponse(value) {

      if(this.questionType === 'QuizRadio'){
        this.selectedResponse = value
      }else{

        const index = this.selectedResponse.indexOf(value);

        if(index !== -1) {
          if (index > -1) {
            this.selectedResponse.splice(index, 1);
          }
        }else{
          this.selectedResponse.push(value)
        }
      }
    }
  },
}
</script>