import { axiosClient } from '../../scripts/axiosClient';
import crosslinkLoader from '../../scripts/crosslinkLoader';

import Drawer from '../../scripts/drawer';

class FoodListDrawer extends Drawer {

  constructor(inPageButtons, drawer, drawerButton, twoStepButton) {
    super(inPageButtons, drawer, drawerButton)
    this.twoStepButton = twoStepButton
    this.drawerContent = this.drawer.querySelector(DRAWER_CONTENT_SELECTOR)
  }

  initTwoStepOpener(drawer) {
    this.twoStepButton.addEventListener('click', () => {
      this.addHeightToDrawer(drawer, '99%')
      this.removeElementAttribute(this.drawerContent, 'style')
    })
  }

  onOpenDrawer (button, drawer) {

    if (window.matchMedia("(min-width: 700px)").matches) {
      this.addHeightToDrawer(drawer, '280px')
    } else {
      this.addHeightToDrawer(drawer, '200px')
    }

    this.drawerContent.innerHTML = '<div class="Drawer-skeleton Drawer-skeleton-food"></div>'
    axiosClient.get('/food', { params: { id: button.dataset.foodContentId } })
      .then((response) => {
        if (window.location.hostname === 'localhost') {
          this.drawerContent.innerHTML = '<div class="Drawer-skeleton Drawer-skeleton-food"></div>'
        } else {
          this.drawerContent.innerHTML = response.data
          crosslinkLoader.init('/food', [`id=${button.dataset.foodContentId}`])
        }
      })
      .catch((error) => {
        console.log(error);
      });

  }

  removeElementAttribute(element, attribute) {
    element.removeAttribute(attribute)
  }

  addHeightToDrawer(drawer, heightValue) {
    drawer.style.height = heightValue;
  }

  addDrawerContentOverflow(drawer) {
    const drawerContent = drawer.querySelector(DRAWER_CONTENT_SELECTOR)
    drawerContent.style.overflowY = 'visible'
  }

  closeDrawer(drawer) {
    super.closeDrawer(drawer)
    this.drawerContent.innerHTML = ''
  }

  onButtonClick(button) {
    button.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"})
    // this.inPageButtons.forEach(button => {
    //   button.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"})
    // });
  }

}

const DRAWER_CONTENT_SELECTOR = "[data-drawer-content]";
const TWO_STEP_BUTTON_SELECTOR = "[data-drawer-twoStepBtn]";
const BUTTON_INPAGE_SELECTOR = "[data-drawer-food-inPageBtn]";
const BUTTON_SELECTOR = "[data-drawer-btn]";
const DRAWER_SELECTOR = "[data-drawer-twostep]";

function init() {
  // Get necessary element so the Drawer can be used
  const inPageButtons = document.querySelectorAll(BUTTON_INPAGE_SELECTOR);
  const drawerContainer = document.querySelector(DRAWER_SELECTOR)
  const drawerButtons = document.querySelectorAll(BUTTON_SELECTOR)
  const twoStepButton = document.querySelector(TWO_STEP_BUTTON_SELECTOR)

  if (drawerContainer) {
    // Create an instance of the drawerContainer
    const foodListDrawer = new FoodListDrawer(inPageButtons, drawerContainer, drawerButtons, twoStepButton)
    foodListDrawer.init()
  }
}

export default { init }
