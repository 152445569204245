// ------
// Styles
// ------

import "normalize.css/normalize.css";
import "./styles/index.scss";

// ---------
// SVG icons
// ---------

import "./icons";

// -------
// Scripts
// -------

import "./components/hamburger/hamburger.js";
import "./components/navigation/navigation.js";
import "./components/navigationtopbar/navigationtopbar.js";
import "./scripts/foodListSeasonfilter.js";
import "what-input"

import vueAppHandler from './components/spfplayer/lib/vueAppHandler';
import Quiz from './components/quiz/quizAppHandler';
vueAppHandler.init();
Quiz.init();

import backButton from "./components/backbutton/backbutton.js";
import bookmark from "./scripts/bookmark";
import passwordReveal from './components/form/passwordReveal';
import passwordCheck from './components/form/passwordCheck';
import passwordSimilarity from './components/form/passwordSimilarity';
import cardSlider from './components/cardslider/cardslider';
import filterTags from './components/filtertags/filtertags';
import filterDrawer from './components/filterdrawer/filterdrawer';
import foodlListDrawer from './components/foodlistdrawer/foodlistdrawer';
import glossaryDrawer from './components/glossarydrawer/glossarydrawer';
import searchbar from './components/searchbar/searchbar';
import userMenu from './components/navigation/usermenu';
import quizDrawer from './components/quizpush/quizpush';
import quizPush from './components/quizpush/quizpush';
import homeLoader from './scripts/homeLoader';
import listLoader from './scripts/listLoader';
import modal from './embed/modal/modal';
import professionalResourceLoader from './scripts/professionalResourceLoader';
import subProfessionalResourceLoader from './scripts/subProfessionalResourceLoader';
import tagLoader from './scripts/tagLoader';
import SearchLoader from './scripts/SearchLoader';
import foodListSeasonfilterSearch from './scripts/foodListSeasonfilterSearch';
import rubricLoader from './scripts/rubricLoader';
import subRubricLoader from './scripts/subRubricLoader';
import seeMore from './components/seemore/seemore';
import slideContent from './scripts/slidecontent';
import activityQuizGridcard from './scripts/activityQuizGridcard';
import crosslinkLoader from './scripts/crosslinkLoader';
import share from './components/share/share';
import tracking from './scripts/tracking';
import flashMessage from './components/flashMessage/flashMessage';
import stickyseasonfilter from './components/seasonfilter/stickyseasonfilter';
import cta from './components/cta/cta';
import gridCard from './embed/gridcard/gridcard';
import landingCards from './embed/landingCards/landingCards';


if (document.readyState === "loading") {
  document.addEventListener("DOMContentLoaded", init);
} else {
  init();
}

function init() {
  bookmark.init()
  crosslinkLoader.init()
  backButton.init()
  cardSlider.init()
  filterDrawer.init()
  filterTags.init()
  passwordReveal.init()
  passwordCheck.init()
  passwordSimilarity.init()
  foodlListDrawer.init()
  glossaryDrawer.init()
  gridCard.init()
  homeLoader.init()
  listLoader.init()
  modal.init()
  professionalResourceLoader.init()
  subProfessionalResourceLoader.init()
  tagLoader.init()
  searchbar.init()
  SearchLoader.init()
  foodListSeasonfilterSearch.init()
  seeMore.init()
  rubricLoader.init()
  subRubricLoader.init()
  slideContent.init()
  userMenu.init()
  share.init()
  quizDrawer.init()
  quizPush.init()
  activityQuizGridcard.init()
  tracking.init()
  flashMessage.init()
  stickyseasonfilter.init()
  cta.init()
  landingCards.init()
}
