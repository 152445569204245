
<template>
  <div class="QuizStart">

    <img class="QuizStart-image" :src="picture" alt="Image du Quiz"/>

    <h2 class="QuizStart-title title2 titleBackground">
      <span>{{labels.startTitle}}</span>
    </h2>

    <button
      @click="handleNextStep()"
      class="Cta Cta--dark"
      data-tracking='action'
      data-tracking-name='amorcer_commencer_quizz_#page_template#_mb'
      data-tracking-chapter1='quiz personnalisation contenu mb'
      data-tracking-chapter2=""
      data-tracking-chapter3=""
    >
      <span class="Cta-label">{{labels.startButtonLabel}}</span>
    </button>

    <p v-if="!quiz.isConnected" class="QuizStart-connectionText">{{labels.startConnectionLabel}}</p>
    
    <a v-if="!quiz.isConnected" :href="keycloakConnectionUrl" class="Cta Cta--light"
      data-tracking='action'
      data-tracking-name='amorcer_je_m identifie_#page_template#_mb'
      data-tracking-chapter1='action mon compte mb'
      data-tracking-chapter2=""
      data-tracking-chapter3="">
      <span class="Cta-label">{{labels.startConnectionButtonLabel}}</span>
    </a>

  </div>
</template>

<script>
  export default {
    name: "QuizStart",
    props:{
      labels:{
        required: true,
        type: Object,
      },
      quiz:{
        required: true,
        type: Object,
      },
      picture: {
        required: true,
        type: String,
      },
    },
    computed: {
      keycloakConnectionUrl() {
        return String(this.quiz.keycloakConnectionUrl).replace(/&amp;/g, '&');
      }
    },
    data(){
      return {
        isNextQuestion: false,
        selectedResponse: null,
        responseStatus: null,
      }
    },
    methods:{
      handleNextStep() {
        // reset old quiz result if exist
        if (localStorage.getItem('Quiz'+this.quiz.type+'Results')) {
          localStorage.removeItem('Quiz'+this.quiz.type+'Results')
        }

        this.$emit("QuizStart::next-step")
      },
    },
  }
</script>