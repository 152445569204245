/**
 * Filtered List Loader
 * Handle list with filters and load more
 */

import axios from 'axios'
import {hashCode} from "./utils";


const LIST_CONTAINER_SELECTOR = '[data-rubric-subcontent-loader-container]'
const LOAD_MORE_BUTTON_SELECTOR = '[data-rubric-subcontent-loader-load-more]'
const LOADING_SKELETON_SELECTOR = '[data-rubric-subcontent-loader-loading-skeleton]'
const SUBRUBRIC_CARD_SELECTOR = '[data-rubric-subcontent-card]'
const SUBRUBRIC_PARENT_SELECTOR = '[data-rubric-subcontent-parent-container]'
const SUBRUBRIC_TITLE = '[data-rubric-subcontent-title]'

let baseUrl = ''

function init() {
  const loadMoreButton = document.querySelector(LOAD_MORE_BUTTON_SELECTOR)
  if (loadMoreButton) {
    loadMoreButton.addEventListener('click', function (){
      handleLoadMoreClick(false)
    })
    handleLoadMoreClick(true)
  }
}

async function handleLoadMoreClick(isFirstTime = false) {
  const axiosInstance = axios.create();
  axiosInstance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

  const queryParameters = []

  const subRubricCards = document.querySelectorAll(SUBRUBRIC_CARD_SELECTOR)
  const idsToExclude = []
  const tagIdsToExclude = []

  subRubricCards.forEach((subRubricCard) => {
    idsToExclude.push(subRubricCard.dataset.contentId)
    if (typeof subRubricCard.dataset.tagId !== 'undefined') {
      tagIdsToExclude.push(subRubricCard.dataset.tagId)
    }
  })

  queryParameters.push(`ajax=true`)

  if (!isFirstTime) {
    const idsToExcludeString = idsToExclude.join(',')
    queryParameters.push(`idsToExclude[]=` + idsToExcludeString)

    const TagIdsToExcludeString = tagIdsToExclude.join(',')
    queryParameters.push(`tagIdsToExclude[]=` + TagIdsToExcludeString)
  } else {
    queryParameters.push(`isFirstTime=true`)
  }

  let queryString = queryParameters.join('&')

  let quizResult = localStorage.getItem('QuizPersoResults');

  // generate an unique hash from quizResult to add to ajax url, in order to generate a new varnish cache variation
  if( quizResult ) {
    let quizHash = hashCode(quizResult)
    queryString = queryString + '&perso=' + quizHash
  }

  let url = baseUrl

  if (queryString !== "") {
    url = `${baseUrl}?${queryString}`
  }

  const currentResults = document.querySelector(LIST_CONTAINER_SELECTOR).innerHTML
  if (isFirstTime) {
    document.querySelector(LIST_CONTAINER_SELECTOR).innerHTML = document.querySelector(LOADING_SKELETON_SELECTOR).innerHTML
  } else {
    document.querySelector(LIST_CONTAINER_SELECTOR).innerHTML += document.querySelector(LOADING_SKELETON_SELECTOR).innerHTML
  }

  const response = await axiosInstance.get(url)

  if (response.data === 'no_more_results') {
    document.querySelector(LOAD_MORE_BUTTON_SELECTOR).setAttribute('aria-hidden', true)
    if (isFirstTime) {
      document.querySelector(SUBRUBRIC_PARENT_SELECTOR).style.display = 'none'
    } else {
      document.querySelector(LIST_CONTAINER_SELECTOR).innerHTML = currentResults
    }
  } else {
    document.querySelector(LOAD_MORE_BUTTON_SELECTOR).setAttribute('aria-hidden', response.data.last_page)
    if (isFirstTime) {
      document.querySelector(SUBRUBRIC_TITLE).innerHTML = response.data.isConnected ?
        'Pour vous...' :
        document.querySelector(SUBRUBRIC_TITLE).innerHTML
      document.querySelector(LIST_CONTAINER_SELECTOR).innerHTML = response.data.content
    } else {
      document.querySelector(LIST_CONTAINER_SELECTOR).innerHTML = currentResults + response.data.content
    }
  }
  
}

export default {init}