import {axiosClient} from './axiosClient'

  //Selectors
  const BOOKMARK_STATUS_URL_SELECTOR = '[data-bookmark-status-url]'
  const BOOKMARKED_SELECTOR = '[data-bookmarked]'

  const init = () => {
    const bookmark = document.querySelector(BOOKMARK_STATUS_URL_SELECTOR);
    const bookmarked = document.querySelector(BOOKMARKED_SELECTOR);

    if (!bookmark || !bookmarked) {
      return
    }

    ajaxManageBookmark();

    /****************/
    /* Ajax handler */

    // Call ajax to get bookmark status for current page
    function ajaxManageBookmark() {

      const url = bookmark.dataset.bookmarkStatusUrl

      axiosClient.get(url)
        .then((response) => {
          if (window.location.hostname === 'localhost') {
            console.log('response', response)
          } else {

            if(response.data.bookmarked === true) {

              bookmarked.style.display = "block"
              bookmark.style.display = "none"

            } else {
              bookmarked.style.display = "none"
              bookmark.style.display = "block"
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

  }

export default { init }