var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "QuizVue",
      attrs: { "data-quiz-name": "customisation", "data-theme": "green" }
    },
    [
      _vm.currentStepIndex !== null && _vm.quiz.type !== "Activity"
        ? _c(
            "h3",
            {
              staticClass:
                "Quiz-name title2 titleBackground titleBackground--green"
            },
            [_c("span", [_vm._v("Quiz de personnalisation")])]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _vm.currentStepIndex !== null
            ? _c("QuizStepper", {
                attrs: {
                  currentStepIndex: _vm.currentStepIndex,
                  steps: _vm.steps
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _c(_vm.componentType, {
            key: _vm.currentStepIndex,
            tag: "component",
            attrs: {
              labels: _vm.labels,
              quiz: _vm.quiz,
              steps: _vm.steps,
              assets: _vm.assets,
              step: _vm.steps[_vm.currentStepIndex],
              picture: _vm.assets.quizStartPicture,
              currentStepIndex: _vm.currentStepIndex,
              buttonText: _vm.buttonText
            },
            on: {
              "QuizStart::next-step": _vm.handleNextStep,
              "QuizStep::next-step": _vm.handleNextStep,
              "QuizStep::previous-step": _vm.handlePreviousStep,
              "QuizStep::on-stage-answer": _vm.handleStageAnswer,
              "QuizStep::reset-step": _vm.handleResetStep,
              "QuizStep::save-quiz": _vm.handleSaveQuiz,
              "QuizEnd::reset-soft": _vm.resetSoft
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
        _c("div", {
          staticClass: "Quiz-loader",
          attrs: { "aria-hidden": "true", "data-quiz-loader": "" }
        })
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }