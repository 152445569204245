<template>
  <div class="QuizStepper">
    <div
      v-for="(step, index) in steps"
      :key="step.id"
      :class="index <= currentStepIndex ? 'QuizStepper-step--active' : ''"
      class="QuizStepper-step"
    >
    <div class="QuizStepper-stepActive"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "QuizStepper",
  props:{
    currentStepIndex: {
      required: true,
      type: Number
    },
    steps: {
      required: true,
      type: Array
    },
  },
}
</script>