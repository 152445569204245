import "matchmedia-polyfill/matchMedia"
import "matchmedia-polyfill/matchMedia.addListener"

// Selector declarations
const TOPBAR_SELECTOR = '[data-topbar]'
const BACKBUTTON_SELECTOR = '[data-backButton]'
const BACKBUTTON_LABEL = '.Cta-label'
const BACKBUTTON_ICON = '.icon'

const backButton = document.querySelector(BACKBUTTON_SELECTOR);
const topBar = document.querySelector(TOPBAR_SELECTOR);

let backButtonLabel
let backButtonIcon
let topbarHeight
let scrollValue = window.scrollY;
let topValue

function init() {

  if(!backButton)return

  backButtonLabel = backButton.querySelector(BACKBUTTON_LABEL);
  backButtonIcon = backButton.querySelector(BACKBUTTON_ICON);

  topbarHeight = topBar.offsetHeight; // Should be around 110px

  initBackButton()

}

function initBackButton() {

  hideOnScroll()
  window.addEventListener("scroll", throttle (hideOnScroll, 20))

}

function hideOnScroll () {

  scrollValue = window.pageYOffset;
  topValue = topbarHeight - scrollValue

  if (window.matchMedia("screen and (min-width: 1100px)").matches) {

    if ( scrollValue >= topbarHeight ){

      hideLabel(backButton, backButtonLabel, backButtonIcon)

    }else if( scrollValue >= 0 && scrollValue <= topbarHeight ){

      showLabel(backButton, backButtonLabel, backButtonIcon)

    }

  } else {

    hideLabel(backButton, backButtonLabel, backButtonIcon)
    
  }

}

function throttle (callback, limit) {
  var tick = false;
  return function () {
    if (!tick) {
      callback.call();
      tick = true;
      setTimeout(function () {
        tick = false;
      }, limit);
    }
  }
}

function hideLabel (button, label, icon) {
  button.style.maxWidth = "47px"
  label.style.opacity = 0
  icon.style.marginLeft = "-2px"
  icon.style.marginRight = "0"
  icon.style.top = "1px"
}

function showLabel (button, label, icon) {
  button.style.maxWidth = "100%"
  label.style.opacity = 1
  icon.style.marginLeft = "0px"
  icon.style.marginRight = "10px"
  icon.style.top = 0
}

export default { init }
