var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "QuizStepper" },
    _vm._l(_vm.steps, function(step, index) {
      return _c(
        "div",
        {
          key: step.id,
          staticClass: "QuizStepper-step",
          class: index <= _vm.currentStepIndex ? "QuizStepper-step--active" : ""
        },
        [_c("div", { staticClass: "QuizStepper-stepActive" })]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }