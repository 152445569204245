import { render, staticRenderFns } from "./QuizHeader.vue?vue&type=template&id=3b5ce690&"
import script from "./QuizHeader.vue?vue&type=script&lang=js&"
export * from "./QuizHeader.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/production/spf-manger-bouger-ezplatform/src/AppBundle/Resources/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3b5ce690')) {
      api.createRecord('3b5ce690', component.options)
    } else {
      api.reload('3b5ce690', component.options)
    }
    module.hot.accept("./QuizHeader.vue?vue&type=template&id=3b5ce690&", function () {
      api.rerender('3b5ce690', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/quiz/components/QuizHeader/QuizHeader.vue"
export default component.exports