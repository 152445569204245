<template>

  <div class="QuizEnd" :class="quiz.type === 'Activity' ? 'QuizEnd--activity': 'QuizEnd'">
    <div v-if="quiz.type === 'Activity'" class="QuizEnd-container">
      <div class="QuizResults">

        <div class="QuizResults-row">

          <h2 class="QuizResults-title title2 titleBackground">
            <span v-if="quiz.quizResult">
                        {{ quiz.quizResult.render.title }}
            </span>
          </h2>

          <img 
            class="QuizResults-image"
            v-if="quiz.quizResult.render.picture"
            :src="quiz.quizResult.render.picture.desktop"
            alt="Résultat du quiz d'activité physique"
          >
        </div>
      </div>

      <div class="QuizResults-row">
          <div id="lexicon_content" class="QuizResults-levelText Richtext" v-if="quiz.quizResult" v-html="quiz.quizResult.render.text">
          </div>
        </div>

      <div class="QuizResults">

        <a v-if="!quiz.isConnected" class="Cta Cta--dark" :href="quiz.keycloakConnectionUrl">
            <span class="Cta-label">Je me connecte pour sauvegarder mon résultat</span>
        </a>
        
        <button v-else-if="!quiz.quizResult.isSave" class="Cta Cta--dark" data-save-quiz @click="saveQuiz">
          <span class="Cta-label" v-text="buttonText"></span>
        </button>

        <div class="QuizEnd-CtaContainer">
          <button :class="quiz.quizResult.isSave == true ? ' Cta--dark' : ' Cta--light'" class="Cta" @click="resetQuiz">
            <span class="Cta-label">Refaire le test</span>
          </button>
        </div>
        
      </div>
    </div>

    <div v-else class="QuizEnd-container">

      <img class="QuizEnd-image" :src="assets.quizEndPicture" alt="Image d'une personne "/>

      <h2 class="QuizEnd-title title2 titleBackground">
        <span>{{ labels.endTitle }}</span>
      </h2>

      <p v-if="!quiz.isConnected" class="QuizEnd-text">{{ labels.endText }}</p>

      <a v-if="!quiz.isConnected" :href="keycloakAccountCreationUrl" class="Cta Cta--dark" data-tracking='action' data-tracking-name='amorcer_creation_compte_#page_template#_mb' data-tracking-chapter1='action mon compte mb'   >
        <span class="Cta-label">{{ labels.endConnectionButtonLabel }}</span>
      </a>

      <p v-if="!quiz.isConnected" class="QuizEnd-connectionText">{{ labels.endConnectionLabel }}</p>
      <a @click="resetSoft" data-quiz-apply-result data-quiz-reset-btn class="Cta Cta--light" data-tracking='action' data-tracking-name='voir_ma_selection_de_contenu_#page_template#_mb' data-tracking-chapter1='quiz personnalisation contenu mb'  style="text-align:center" >
        <span class="Cta-label">{{ labels.endButtonLabel }}</span>
      </a>

    </div>
  </div>
</template>

<script>

import homeLoader from "../../../../scripts/homeLoader";
import quizDrawerInstance from "../../../quizpush/quizpush";

export default {
  name: "QuizEnd",
  props: {
    labels: {
      required: true,
      type: Object,
    },
    quiz: {
      required: true,
      type: Object,
    },
    picture: {
      required: true,
      type: String,
    },
    steps: {
      required: true,
      type: Array
    },
    currentStepIndex: {
      required: true,
      type: Number
    },
    assets: {
      required: true,
      type: Object
    },
    buttonText: {
      type: String,
      default: "Je sauvegarde mon résultat"
    },
  },
  computed: {
    keycloakAccountCreationUrl() {
      return String(this.quiz.keycloakAccountCreationUrl).replace(/&amp;/g, '&');
    }
  },
  methods: {
    resetSoft(){
      this.$emit("QuizEnd::reset-soft", true)
    },
    resetAttributes(buttons) {
      buttons.forEach(button => {
        let isActive = (button.getAttribute('data-quizresults-gridcard-button') === 'true') // transform string to Boolean
        if (isActive) {
          button.setAttribute('data-quizresults-gridcard-button', 'false')
        }
      });
    },
    resetQuiz() {
      this.$emit("QuizStep::reset-step")
    },
    saveQuiz() {
      const saveButton = document.querySelector('[data-save-quiz]')
      saveButton.setAttribute("disabled", "disabled")
      this.buttonText = "Sauvegarde en cours"
      this.$emit("QuizStep::save-quiz", true)
    }
  },
  mounted: function () {

    const quizGridCard = document.querySelector('[data-quiz-gridCard]')

    if (quizGridCard) {

      quizGridCard.setAttribute('data-quiz-gridCard', 'visible')
      const BUTTONS_SELECTOR = '[data-quizresults-gridcard-button]';
      const buttons = document.querySelectorAll(BUTTONS_SELECTOR)
      const vm = this // bind this of the VM instance

      if (!buttons.length) return

      buttons.forEach(button => {
        button.addEventListener('click', () => {

          vm.resetAttributes(buttons)
          button.setAttribute('data-quizresults-gridcard-button', 'true')
          vm.ToggleCardList()

        })
      });

    }

    const quizCtaApplyResultButton = document.querySelector('[data-quiz-apply-result]')
    if (quizCtaApplyResultButton) {

      let ContentSection = null
      
      if (document.querySelector(homeLoader.getFilterTagsSelector()) !== null) { /* if HomePage, scroll to CardList section */
        quizCtaApplyResultButton.addEventListener('click', () => {

          ContentSection = document.querySelector(homeLoader.getFilterTagsSelector())
          ContentSection.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"})
          homeLoader.handleFilterClick()
          quizDrawerInstance.closeDrawer(quizDrawerInstance.drawer)

        })
      } else { /* else redirect the user to url in data json */
        
        quizCtaApplyResultButton.addEventListener('click', () => {
          document.location.replace(this.quiz.contentSelectionUrl)
        })

      }
    }

    //tracking
    tc_vars.form_is_confirmation = true;
    tc_vars.form_step_name= this.currentStepIndex+1 +"- " + "validation contenu personnalisé"
    tc_vars.form_step_number = this.currentStepIndex+1
    tC.container.reload({events: {pageLoad: [{},{}]}});
    tC.event.form_validation(this,{
      'contains_form_infos': tc_vars.contains_form_infos,
      'form_name':tc_vars.form_name,
      'form_has_step':tc_vars.form_has_step,
      'form_step_number': tc_vars.form_step_number,
      'form_step_name': tc_vars.form_step_name,
      'form_is_confirmation':  tc_vars.form_is_confirmation,
    });

  },
}
</script>