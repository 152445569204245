var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "QuizQuote-separator" }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "QuizQuote-quote Blockquote Blockquote--question" },
      [
        _c("div", { staticClass: "Blockquote-header" }, [
          _c("div", { staticClass: "Blockquote-iconContainer" }, [
            _c(
              "svg",
              {
                attrs: {
                  xmlns: "http://www.w3.org/2000/svg",
                  viewBox: "0 0 50 60"
                }
              },
              [
                _c("path", {
                  attrs: {
                    fill: "#44ab46",
                    d:
                      "M23.31 42.57v-3.1c.2-1.62.7-2.97 1.47-4.05a15.6 15.6 0 012.67-2.89c1-.84 2.07-1.68 3.2-2.52a16.07 16.07 0 003.08-3.04c.93-1.2 1.7-2.63 2.33-4.3.63-1.69.94-3.83.94-6.43 0-1.57-.31-3.26-.94-5.07a14.37 14.37 0 00-3.12-5.08 17.22 17.22 0 00-5.75-3.94C24.8 1.09 20.96.69 18.8.69c-2.76 0-5.78.38-8 1.38a16.6 16.6 0 00-5.72 4.18 19.35 19.35 0 00-3.69 6.34A24.87 24.87 0 000 20.63h11.05c0-1.46.16-2.83.46-4.1.3-1.27.76-2.38 1.39-3.33a7.17 7.17 0 012.4-2.28 6.84 6.84 0 013.5-.85c2 0 3.57.6 4.7 1.79 1.13 1.19 1.7 3.03 1.7 5.52a7.1 7.1 0 01-.72 3.66c-.53.97-1.22 1.87-2.07 2.68-.85.81-1.78 1.62-2.78 2.44a19.6 19.6 0 00-2.86 2.88 17 17 0 00-2.37 4.02 17.74 17.74 0 00-1.24 5.85v3.66h10.15zM23 59V48H13v11h10z"
                  }
                })
              ]
            )
          ]),
          _vm._v(" "),
          _c("h3", { staticClass: "Blockquote-title" }, [
            _vm._v(_vm._s(_vm.step.quote.title))
          ])
        ]),
        _vm._v(" "),
        _c("blockquote", { staticClass: "Blockquote-content Richtext" }, [
          _c("p", [_vm._v(_vm._s(_vm.step.quote.content))]),
          _vm._v(" "),
          _vm.step.quote.author
            ? _c("figcaption", { staticClass: "Blockquote-author" }, [
                _vm._v("- " + _vm._s(_vm.step.quote.author))
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.step.quote.warning
            ? _c("div", { staticClass: "Blockquote-warning" }, [
                _vm._v(_vm._s(_vm.step.quote.warning))
              ])
            : _vm._e()
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }