var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.step.type === "checkbox"
      ? _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.selectedResponse,
              expression: "selectedResponse"
            }
          ],
          staticClass: "QuizInput-inputRadio",
          attrs: {
            id: "step.name" + _vm.index,
            name: _vm.step.name,
            type: "checkbox"
          },
          domProps: {
            value: _vm.step.responses[_vm.index].value,
            checked: Array.isArray(_vm.selectedResponse)
              ? _vm._i(
                  _vm.selectedResponse,
                  _vm.step.responses[_vm.index].value
                ) > -1
              : _vm.selectedResponse
          },
          on: {
            change: [
              function($event) {
                var $$a = _vm.selectedResponse,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = _vm.step.responses[_vm.index].value,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.selectedResponse = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.selectedResponse = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.selectedResponse = $$c
                }
              },
              _vm.handleEmitResponse
            ]
          }
        })
      : _vm.step.type === "radio"
      ? _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.selectedResponse,
              expression: "selectedResponse"
            }
          ],
          staticClass: "QuizInput-inputRadio",
          attrs: {
            id: "step.name" + _vm.index,
            name: _vm.step.name,
            type: "radio"
          },
          domProps: {
            value: _vm.step.responses[_vm.index].value,
            checked: _vm._q(
              _vm.selectedResponse,
              _vm.step.responses[_vm.index].value
            )
          },
          on: {
            change: [
              function($event) {
                _vm.selectedResponse = _vm.step.responses[_vm.index].value
              },
              _vm.handleEmitResponse
            ]
          }
        })
      : _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.selectedResponse,
              expression: "selectedResponse"
            }
          ],
          staticClass: "QuizInput-inputRadio",
          attrs: {
            id: "step.name" + _vm.index,
            name: _vm.step.name,
            type: _vm.step.type
          },
          domProps: {
            value: _vm.step.responses[_vm.index].value,
            value: _vm.selectedResponse
          },
          on: {
            change: _vm.handleEmitResponse,
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.selectedResponse = $event.target.value
            }
          }
        }),
    _vm._v(" "),
    _c(
      "label",
      {
        staticClass: "QuizInput-label",
        attrs: { for: "step.name" + _vm.index }
      },
      [_vm._v(_vm._s(_vm.step.responses[_vm.index].label))]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }