const CONTAINER_SELECTOR = '[data-passwordreveal]'
const BUTTON_SELECTOR = '[data-passwordreveal-btn]'
const INPUT_SELECTOR = '[data-passwordreveal-input]'

function init() {

  const CONTAINERS = document.querySelectorAll(CONTAINER_SELECTOR)

  if (!CONTAINERS) return

  CONTAINERS.forEach(container => {

    const button = container.querySelector(BUTTON_SELECTOR)
    const input = container.querySelector(INPUT_SELECTOR)
    
    button.addEventListener('click', function() {

      if (input.type === 'password') {
        input.type = "text"
      } else {
        input.type = "password"
      }
      
    })

  })
}

export default { init }