/**
 * Filtered List Loader
 * Handle list with filters and load more
 */

import axios from 'axios'
import {clickNavigationInit} from "./tracking";


const LIST_CONTAINER_SELECTOR = '[data-tag-loader-container]'
const LOAD_MORE_BUTTON_SELECTOR = '[data-tag-loader-load-more]'
const LOADING_SKELETON_SELECTOR = '[data-tag-laoder-loading-skeleton]'
const HOME_CARD_SELECTOR = '[data-tag-card]'
const FILTERTAGS_SELECTOR = "[data-tag-loader-filter]"

let baseUrl = ''
let filter = null

function init() {
  const loadMoreButton = document.querySelector(LOAD_MORE_BUTTON_SELECTOR)
  if (loadMoreButton) {
    loadMoreButton.addEventListener('click', handleLoadMoreClick)
  }

  const filterTags = document.querySelectorAll(FILTERTAGS_SELECTOR)
  if (filterTags.length > 0) {
    filterTags.forEach(filterTag => {

      filterTag.addEventListener('click', (e) => {
        filter = filterTag.dataset.filterType
        handleFilterClick(false)
      })
    });
  }
}

async function handleLoadMoreClick() {
  const axiosInstance = axios.create();
  axiosInstance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

  const queryParameters = []

  const tagCards = document.querySelectorAll(HOME_CARD_SELECTOR)

  const idsToExclude = []
  const tagIdsToExclude = []
  const FAMIterator = {'page': 0, 'idsFound' : 0}

  tagCards.forEach((tagCard) => {
    idsToExclude.push(tagCard.dataset.contentId)
    if (typeof tagCard.dataset.recipePage !== 'undefined') {
      FAMIterator.page = tagCard.dataset.recipePage
      FAMIterator.idsFound += 1;
      //  actualy useles, FAM's API can't handle exclude IDs
    } else if (typeof tagCard.dataset.tagId !== 'undefined') {
      tagIdsToExclude.push(tagCard.dataset.tagId)
    }
  })

  queryParameters.push(`ajax=true`)

  const idsToExcludeString = idsToExclude.join(',')
  queryParameters.push(`idsToExclude[]=` + idsToExcludeString)

  const tagIdsToExcludeString = tagIdsToExclude.join(',')
  queryParameters.push(`tagIdsToExclude[]=` + tagIdsToExcludeString)

  queryParameters.push(`FAMIterator[page]=` + FAMIterator.page)
  queryParameters.push(`FAMIterator[idsFound]=` + FAMIterator.idsFound)

  if (filter && filter !== "all") {
    queryParameters.push(`filter=` + filter)
  }

  const queryString = queryParameters.join('&')

  let url = baseUrl

  if (queryString !== "") {
    url = `${baseUrl}?${queryString}`
  }

  const currentResults = document.querySelector(LIST_CONTAINER_SELECTOR).innerHTML
  document.querySelector(LIST_CONTAINER_SELECTOR).innerHTML += document.querySelector(LOADING_SKELETON_SELECTOR).innerHTML

  const response = await axiosInstance.get(url)

  if (response.data.last_page) {
    document.querySelector(LOAD_MORE_BUTTON_SELECTOR).setAttribute('aria-hidden', true)
  }

  if (response.data === 'no_more_results') {
    document.querySelector(LOAD_MORE_BUTTON_SELECTOR).setAttribute('aria-hidden', true)
    document.querySelector(LIST_CONTAINER_SELECTOR).innerHTML = currentResults
  } else {
    document.querySelector(LIST_CONTAINER_SELECTOR).innerHTML = currentResults + response.data.content
  }

    // call navigation tracking init to add click listener on ajax reponse
    clickNavigationInit()

}

async function handleFilterClick() {
  const axiosInstance = axios.create();
  axiosInstance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

  const queryParameters = []

  queryParameters.push(`ajax=true`)

  if (filter && filter !== "all") {
    queryParameters.push(`filter=` + filter)
  }

  const queryString = queryParameters.join('&')

  let url = baseUrl

  if (queryString !== "") {
    url = `${baseUrl}?${queryString}`
  }

  document.querySelector(LIST_CONTAINER_SELECTOR).innerHTML = document.querySelector(LOADING_SKELETON_SELECTOR).innerHTML

  const response = await axiosInstance.get(url)

  if (response.data.content === 'no_more_results') {
    //what going on if after apply a filter, nothing he's found ?
    document.querySelector(LOAD_MORE_BUTTON_SELECTOR).setAttribute('aria-hidden', true)
    document.querySelector(LIST_CONTAINER_SELECTOR).innerHTML = "<p class='Gridcard-emptyText'>" + response.data.msg + "</p"
  } else {
    document.querySelector(LOAD_MORE_BUTTON_SELECTOR).setAttribute('aria-hidden', response.data.last_page)
    document.querySelector(LIST_CONTAINER_SELECTOR).innerHTML = response.data.content
  }

    // call navigation tracking init to add click listener on ajax reponse
    clickNavigationInit()
}

export default {init}