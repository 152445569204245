<template>
  <div>
    <input
      class="QuizInput-inputRadio"
      :type="step.type"
      :id="`step.name${index}`"
      :name="step.name"
      :value="step.responses[index].value"
      v-model="selectedResponse"
      @change="handleEmitResponse"
    >
    <label
      class="QuizInput-label"
      :for="`step.name${index}`"
    >{{ step.responses[index].label }}</label>
  </div> 
</template>

<script>
export default {
  name: "QuizRadio",
  props:{
    step: {
      required: true,
      type: Object
    },
    index: {
      required: true,
      type: Number
    },
  },
  data(){
    return {
      selectedResponse: []
    }
  },
  methods:{
    handleEmitResponse() {
      if(this.selectedResponse.length){
        if (this.step.responses[this.index].hasOwnProperty('skipToId')) {
          this.$emit('QuizRadio::handle-selected-response', this.step.responses[this.index])
        } else {
          this.$emit('QuizRadio::handle-selected-response', this.selectedResponse)
        }
      }
    }
  },
}
</script>