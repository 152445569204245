/**
 * Home Loader
 * Handle home list card loading
 */

import axios from 'axios'
import { hashCode } from './utils.js'
import { clickNavigationInit } from "./tracking.js";

const LIST_CONTAINER_SELECTOR = '[data-home-loader-container]'
const LOAD_MORE_BUTTON_SELECTOR = '[data-home-loader-load-more]'
const LOADING_SKELETON_SELECTOR = '[data-home-laoder-loading-skeleton]'
const HOME_CARD_SELECTOR = '[data-home-card]'
const FILTERTAGS_SELECTOR = "[data-home-loader-filter]"

let baseUrl = ''
let filter = null

function init() {
  const loadMoreButton = document.querySelector(LOAD_MORE_BUTTON_SELECTOR)
  if (location.hash === '#filtertags' && document.querySelector(FILTERTAGS_SELECTOR)) {
    let ContentSection = document.querySelector(FILTERTAGS_SELECTOR)
    ContentSection.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"})
  }

  if (loadMoreButton) {
    loadMoreButton.addEventListener('click', handleLoadMoreClick)
  }

  const filterTags = document.querySelectorAll(FILTERTAGS_SELECTOR)
  if (filterTags.length > 0) {
    filterTags.forEach(filterTag => {

      filterTag.addEventListener('click', (e) => {
        filter = filterTag.dataset.filterType
        handleFilterClick(false)
      })
    });
    handleFilterClick(true)
  }
}

async function handleLoadMoreClick() {
  const axiosInstance = axios.create();
  axiosInstance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

  const queryParameters = []

  const homeCards = document.querySelectorAll(HOME_CARD_SELECTOR)

  const idsToExclude = []
  const tagIdsToExclude = []
  const FAMIterator = {'page': 0, 'idsFound' : 0}

  homeCards.forEach((homeCard) => {
    idsToExclude.push(homeCard.dataset.contentId)
    if (typeof homeCard.dataset.recipePage !== 'undefined') {
      FAMIterator.page = homeCard.dataset.recipePage
      FAMIterator.idsFound += 1;
    //  actualy useles, FAM's API can't handle exclude IDs
    } else if (typeof homeCard.dataset.tagId !== 'undefined') {
      tagIdsToExclude.push(homeCard.dataset.tagId)
    }
  })

  queryParameters.push(`ajax=true`)

  const idsToExcludeString = idsToExclude.join(',')
  queryParameters.push(`idsToExclude[]=` + idsToExcludeString)

  const tagIdsToExcludeString = tagIdsToExclude.join(',')
  queryParameters.push(`tagIdsToExclude[]=` + tagIdsToExcludeString)

  queryParameters.push(`FAMIterator[page]=` + FAMIterator.page)
  queryParameters.push(`FAMIterator[idsFound]=` + FAMIterator.idsFound)

  if (filter && filter !== "all") {
    queryParameters.push(`filter=` + filter)
  }

  let queryString = queryParameters.join('&')

  let quizResult = localStorage.getItem('QuizPersoResults');

  // generate an unique hash from quizResult to add to ajax url, in order to generate a new varnish cache variation
  if( quizResult ) {
      let quizHash = hashCode(quizResult)
      queryString = queryString + '&perso=' + quizHash
  }

  let url = baseUrl

  if (queryString !== "") {
    url = `${baseUrl}?${queryString}`
  }

  const currentResults = document.querySelector(LIST_CONTAINER_SELECTOR).innerHTML
  document.querySelector(LIST_CONTAINER_SELECTOR).innerHTML += document.querySelector(LOADING_SKELETON_SELECTOR).innerHTML


  const response = await axiosInstance.get(url)

  if (response.data.last_page) {
    document.querySelector(LOAD_MORE_BUTTON_SELECTOR).setAttribute('aria-hidden', true)
  }

  if (response.data === 'no_more_results') {
    document.querySelector(LOAD_MORE_BUTTON_SELECTOR).setAttribute('aria-hidden', true)
    document.querySelector(LIST_CONTAINER_SELECTOR).innerHTML = currentResults
  } else {
    document.querySelector(LIST_CONTAINER_SELECTOR).innerHTML = currentResults + response.data.content

    // call navigation tracking init to add click listener on ajax reponse
    clickNavigationInit()
  }
}

async function handleFilterClick(isFirstTime = false) {
  const axiosInstance = axios.create();
  axiosInstance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

  const queryParameters = []

  queryParameters.push(`ajax=true`)

  if (!isFirstTime) {
    if (filter && filter !== "all") {
      queryParameters.push(`filter=` + filter)
    }
  } else {
    queryParameters.push(`firstTime=true`)
  }

  let queryString = queryParameters.join('&')

  let quizResult = localStorage.getItem('QuizPersoResults');

  // generate an unique hash from quizResult to add to ajax url, in order to generate a new varnish cache variation
  if( quizResult ) {
      let quizHash = hashCode(quizResult)
      queryString = queryString + '&perso=' + quizHash
  }

  let url = baseUrl

  if (queryString !== "") {
    url = `${baseUrl}?${queryString}`
  }

  document.querySelector(LIST_CONTAINER_SELECTOR).innerHTML = document.querySelector(LOADING_SKELETON_SELECTOR).innerHTML

  const response = await axiosInstance.get(url)

  if (response.data === 'no_more_results') {
    //what going on if after apply a filter, nothing he's found ?
    document.querySelector(LOAD_MORE_BUTTON_SELECTOR).setAttribute('aria-hidden', true)
    document.querySelector(LIST_CONTAINER_SELECTOR).innerHTML = '<h3 class="title2 titleBackground" style="width: 100%;text-align: center;padding-left: 40px;"><span>OUPS !</span></h3><p class="Gridcard-emptyText">Aucun contenu ne correspond</p>'
  } else {
    document.querySelector(LOAD_MORE_BUTTON_SELECTOR).setAttribute('aria-hidden', response.data.last_page)
    document.querySelector(LIST_CONTAINER_SELECTOR).innerHTML = response.data.content
  }

    // call navigation tracking init to add click listener on ajax reponse
    clickNavigationInit()

}

function getFilterTagsSelector() {
  return FILTERTAGS_SELECTOR;
}

export default {init, handleFilterClick, getFilterTagsSelector}