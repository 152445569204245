
// const MODAL_SELECTOR = '[data-modal]'
const MODAL_TOGGLE_SELECTOR = '[data-modal-toggle]' 

// const modals = document.querySelectorAll(MODAL_SELECTOR)
let modalsToggle = document.querySelectorAll(MODAL_TOGGLE_SELECTOR)

function init(forceTargetString = null) {

  if (forceTargetString) {
    const target = document.querySelector(forceTargetString)

    if(target == null) return

    modalsToggle = target.querySelectorAll(MODAL_TOGGLE_SELECTOR)
  }

  if(!modalsToggle.length) return

  initModal(modalsToggle)
}

function initModal(modalsToggle) {
  modalsToggle.forEach(toggleElement => {
    toggleElement.addEventListener('click', (e) => {

      e.stopPropagation();

      const modalId = toggleElement.getAttribute('data-modal-toggle') // which modal is clicked
      const modal = document.querySelector('[data-modal=' + modalId + ']') // get the modal linked to the button clicked
      const isHidden = (modal.getAttribute('aria-hidden') === 'true' ) // is the modal hide/visible
      const body = document.querySelector('body') // get the body

      if(isHidden){
        showModal(modal, body)
      }
      else{
        hideModal(modal, body)
      }

    })
  });
}

function showModal(modal, body) {

  body.setAttribute('data-modal-open', 'open') 
  
  modal.style.display = "flex" // make the display first then change the aria props so we can transition it in css

  setTimeout(() => {
    modal.setAttribute('aria-hidden', 'false') // access attr
  },100)
}

function hideModal(modal, body) {
  
  const transitionTime = 500 // time of the transition in modal.scss

  modal.setAttribute('aria-hidden', 'true') // make the transition first through aria props in css then change the display and body overflow

  var vids = document.getElementsByTagName('video') // pause video player if any in modal

  for( var i = 0; i < vids.length; i++ ){
     vids.item(i).pause();
  }

  var audios = document.getElementsByTagName('audio') // pause audio player if any in modal

  for( var j = 0; j < audios.length; j++ ){
     audios.item(j).pause();
  }

  setTimeout(() => {
    body.setAttribute('data-modal-open', 'close')
    modal.style.display = "none"
  },transitionTime)
}

export default { init }