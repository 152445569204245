/**
 * Crosslink loader for content's with personnalization Crosslink
 **/

import axios from 'axios'
import { hashCode } from './utils.js'
import { clickNavigationInit } from './tracking.js'

const LIST_CONTAINER_SELECTOR = '[data-crosslink-content-loader-container]'
const LOADING_SKELETON_SELECTOR = '[data-crosslink-content-loading-skeleton]'

let baseUrl = ''

function init(forceUrl = null, queryParameters = []) {
  const currentSection = document.querySelector(LIST_CONTAINER_SELECTOR)
  if (currentSection) {
    if (forceUrl !== null) {
      baseUrl = forceUrl
    }
    handleLoad(queryParameters)
  }
}

async function handleLoad(queryParameters = []) {
  const axiosInstance = axios.create();
  axiosInstance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

  queryParameters.push(`ajax=true`)
  queryParameters.push(`crosslink=true`)

  let queryString = queryParameters.join('&')

  let url = baseUrl

  let quizResult = localStorage.getItem('QuizPersoResults');

  // generate an unique hash from quizResult to add to ajax url, in order to generate a new varnish cache variation
  if( quizResult ) {
      let quizHash = hashCode(quizResult)
      queryString = queryString + '&perso=' + quizHash
  }

  if (queryString !== "") {
    url = `${baseUrl}?${queryString}`
  }

  const response = await axiosInstance.get(url)
  document.querySelector(LOADING_SKELETON_SELECTOR).setAttribute('aria-hidden', true)

  if (response.data === 'no_more_results') {
    document.querySelector(LIST_CONTAINER_SELECTOR).getElementsByClassName('container-inner')[0].innerHTML = ''
  } else {
    document.querySelector(LIST_CONTAINER_SELECTOR).getElementsByClassName('container-inner')[0].innerHTML = response.data.content

    // call navigation tracking init to add click listener on ajax reponse
    clickNavigationInit()

  }

}

export default {init}