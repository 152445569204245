var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "QuizQuestion" }, [
    _c("div", { staticClass: "QuizQuestion-container" }, [
      _c(
        "div",
        { staticClass: "QuizQuestion-left" },
        [
          _c("QuizCta", {
            attrs: {
              isNext: false,
              labels: _vm.labels,
              currentStepIndex: _vm.currentStepIndex,
              steps: _vm.steps,
              selectedResponse: _vm.selectedResponse
            },
            on: { "QuizPrevious::previous-step": _vm.handlePreviousStep }
          }),
          _vm._v(" "),
          _c(
            "figure",
            {
              staticClass: "QuizQuestion-image",
              class: {
                "QuizQuestion-image--standalone": _vm.currentStepIndex == 0
              }
            },
            [
              _c("img", {
                attrs: { src: _vm.step.picture, alt: _vm.step.pictureDesc }
              })
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "QuizQuestion-right" }, [
        _c("h3", { staticClass: "QuizQuestion-title title3 titleBackground" }, [
          _c("span", [_vm._v(_vm._s(_vm.step.title))])
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "QuizQuestion-text" }, [
          _vm._v(_vm._s(_vm.step.text))
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            class:
              _vm.quiz.type === "Activity"
                ? "QuizQuestionActivity-QuizInputList"
                : "QuizInputList"
          },
          _vm._l(_vm.step.responses, function(question, index) {
            return _c(
              "div",
              {
                key: _vm.currentStepIndex + "-" + index,
                staticClass: "QuizInput QuizQuestionWrapper",
                class: "QuizInput-" + _vm.step.type
              },
              [
                _c(_vm.questionType, {
                  tag: "component",
                  attrs: { step: _vm.step, index: index },
                  on: {
                    "QuizRadio::handle-selected-response":
                      _vm.handleSelectedResponse,
                    "QuizCheckbox::handle-selected-response":
                      _vm.handleSelectedResponse
                  }
                })
              ],
              1
            )
          }),
          0
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "QuizQuestion-footer" },
          [
            _c("QuizCta", {
              attrs: {
                isNext: true,
                labels: _vm.labels,
                steps: _vm.steps,
                quiz: _vm.quiz,
                currentStepIndex: _vm.currentStepIndex,
                selectedResponse: _vm.selectedResponse
              },
              on: { "QuizNext::next-step": _vm.handleQuestionSubmit }
            })
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }