import "matchmedia-polyfill/matchMedia"
import "matchmedia-polyfill/matchMedia.addListener"

let cardMaxHeight = defineMaxHeight()
const reduceTick = 1

function init() {
  defineMaxHeight()
  listenDom()
  window.addEventListener('resize', changeFontSize);
}

function defineMaxHeight() {
  if (window.matchMedia("(min-width: 1100px)").matches) {
    cardMaxHeight = 216
  }else if (window.matchMedia("(min-width: 700px) and (max-width: 1100px)").matches){
    cardMaxHeight = 155
  }else if (window.matchMedia("(max-width: 700px)").matches){
    cardMaxHeight = 65
  }

  return cardMaxHeight
}

function listenDom() {
  const mutationObserver = new MutationObserver(function(mutations) {
    mutations.forEach(function(mutation) {
  
      const mutatedElement = mutation.target

      if(mutatedElement && mutatedElement.classList) {

        const conseilCards = mutatedElement.querySelectorAll('.CardConseil')

        if(conseilCards.length) {

          conseilCards.forEach( conseilCard => {
            initDynamicText(conseilCard)
          });

          return
        }
      }
    });
  });
  
  // Starts listening for changes in the root HTML element of the page.
  mutationObserver.observe(document.documentElement, {
    attributes: true,
    characterData: true,
    childList: true,
    subtree: true,
    attributeOldValue: true,
    characterDataOldValue: true
  });
}

function initDynamicText(card) {
  const cardText = card.querySelector('.CardConseil-title')
  const cardStyles = getComputedStyle(cardText);
  const cardHeight = parseInt(cardStyles.height.split('.')[0])
  const cardFontSize = parseInt(cardStyles.fontSize.slice(0, -2))
  
  if(cardHeight > cardMaxHeight) {
    reduceFontSize(card, cardText, cardFontSize)
  }
}

function reduceFontSize(card, cardText, fontSize) {
  if(fontSize > (0 + reduceTick)) {
    const newFontSize = fontSize - reduceTick
    cardText.style.fontSize = `${newFontSize/10}rem`
    initDynamicText(card)
  }
}

function changeFontSize() {
  defineMaxHeight()
  const conseilCards = document.querySelectorAll('.CardConseil')

  if(conseilCards.length) {
    conseilCards.forEach(card => {
      initDynamicText(card)
    });
  }
}

export default { init }
