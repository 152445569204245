<template>
  <div class="SPFPlayer-player" data-tracking='action' :data-tracking-name="'lancer_' + mediaType +'_#page_template#_mb'" data-tracking-chapter1='fichiers ressources'   :class="{'is-playing': isPlaying }" :popin_name= " isModal ? 'popin lancer_'+ originMedias[0].title + '_mb' : '' " >
    <button class="SPFPlayer-playerButton" v-if="mediaType === 'video' && playButton.isVisible" @click="playMedia"  >
      <img v-if="mediaPoster" :src="mediaPoster.src" :alt="mediaPoster.alt">
      <div class="SPFPlayer-playerButton--svg">
        <svg width="700pt" height="700pt" viewBox="0 0 615 600" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <path d="M510.31 264.34 209.29 90.54c-6.0586-3.5-12.473-3.5-18.543 0-6.0703 3.5039-8.7461 9.9727-8.7461 16.98v347.6c0 7 2.6758 13.473 8.7422 16.973 3.0352 1.7539 5.8867 2.625 9.2734 2.625 3.3828 0 6.5-.875 9.5352-2.625l300.89-173.8c6.0664-3.5 9.7344-9.9727 9.7344-16.973 0-7.0078-3.8047-13.473-9.8672-16.98z"/>
        </svg>
      </div>
    </button>
    <video  v-if="mediaType === 'video'" class="SPFPlayer-mediaPlayer" data-spf-player-mediaPlayer controls disablePictureInPicture controlsList="nodownload noplaybackrate">
      <source v-for="(source, index) in mediaSources" :key="`source-${index}`" :src="source.src" :type="source.type ? source.type : ''">
      <track
        v-for="(subtitle, index) in mediaSubtitles" :key="`subtitle-${index}`"
        :label="subtitle.label ? subtitle.label : ''"
        :srclang="subtitle.srcLang ? subtitle.srcLang : ''"
        :src="subtitle.src"
        kind="subtitles"
      >
    </video>
    <audio v-else-if="mediaType === 'audio'" class="SPFPlayer-mediaPlayer" data-spf-player-mediaPlayer controls disablePictureInPicture controlsList="nodownload noplaybackrate">
      <source v-for="(source, index) in mediaSources" :key="`source-${index}`" :src="source.src" :type="source.type ? source.type : ''">
    </audio>
  </div>
</template>

<script>
export default {
  name: 'Player',
  props: {
    eventBus: {
      required: true,
      type: Object
    },
    mediaType: {
      required: false,
      type: String,
      default: 'video'
    },
    originMedias: {
      required: true,
      type: Array
    },
    originMediaSources: {
      required: true,
      type: Array
    },
    originMediaPoster: {
      required: true,
      type: Object
    },
    originSubtitles: {
      required: false,
      type: Array
    },
    originAutoplay: {
      required: false,
      type: Boolean,
      default: false
    },
    playButton: {
      required: false,
      type: Object,
      default: function() {
        return {
          isVisible: false,
          hasIcon: false
        }
      }
    },
    isModal: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      medias: [],
      currentMediaIndex: '',
      mediaSources: [],
      mediaPoster: {},
      mediaSubtitles: [],
      isPlaying: false,
      autoplay: false,
      mediaNode: null
    }
  },
  methods: {
    playMedia() {
      this.mediaNode.play()
      this.isPlaying = true
    },
    // Preload current media
    loadMedia() {
      this.mediaNode.load()
      this.mediaNode.addEventListener('loadeddata', () => {
        this.playMedia()
      })
    },
    // Play next media if autoplay is enabled
    mediaEnded() {
      this.mediaNode.addEventListener('ended', () => {
        if (this.autoplay) {
          this.updateMedia(this.currentMediaIndex + 1)
        }
      })
    },
    // Change current media and send new media to other widgets
    updateMedia(mediaIndex) {
      if (this.medias[mediaIndex] !== undefined) {
        this.eventBus.$emit('Player::UpdateMedia', {index: mediaIndex, media: this.medias[mediaIndex]})
        this.updatePlayer(this.medias[mediaIndex], mediaIndex)
        this.checkCurrentMedia()
      }
    },
    // Update media data with new one
    updatePlayer(media, index) {
      this.currentMediaIndex = index
      this.mediaSources = media.media_sources
      this.mediaPoster = media.image
      this.mediaSubtitles = media.subtitles
      this.loadMedia()
    },
    // Check if the current is first or last and notify Controls widget
    checkCurrentMedia() {
      if (this.medias[this.currentMediaIndex - 1] === undefined) {
        this.eventBus.$emit('Player::IsFirstMedia')
      }

      if (this.medias[this.currentMediaIndex + 1] === undefined) {
        this.eventBus.$emit('Player::IsLastMedia')
      }
    }
  },
  mounted() {
    this.medias = this.originMedias
    this.currentMediaIndex = 0
    this.mediaSources = this.originMediaSources
    this.mediaPoster = this.originMediaPoster
    this.mediaSubtitles = this.originSubtitles
    this.autoplay = this.originAutoplay
    this.mediaNode = this.$el.querySelector('[data-spf-player-mediaPlayer]')

    // Add event listener on media ended
    this.mediaEnded()

    this.eventBus.$on('Playlist::UpdateMedia', (index) => {
      this.updateMedia(index)
    })

    this.eventBus.$on('Controls::PreviousMedia', () => {
      this.updateMedia(this.currentMediaIndex - 1)
    })

    this.eventBus.$on('Controls::NextMedia', () => {
      this.updateMedia(this.currentMediaIndex + 1)
    })

    this.eventBus.$on('Autoplay::AutoplayState', (state) => {
      this.autoplay = state
    })
  },
}
</script>

<style lang="scss" scoped>
.SPFPlayer-player {
  position: relative;

  &.is-playing {
    .SPFPlayer-playerButton {
      opacity: 0;
      visibility: hidden;
      transition:
        opacity .3s ease-in-out,
        visibility 0s linear .3s;
    }
  }
}

.SPFPlayer-playerButton {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  visibility: visible;
  transition:
    opacity .5s ease-in-out,
    visibility 0s linear;


  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  // svg {
  //   position: absolute;
  //   left: 50%;
  //   top: 50%;
  //   width: 75px;
  //   height: 75px;
  //   transform: translate(-50%, -50%);
  // }
}

.SPFPlayer-mediaPlayer {
  display: block;
}
</style>
