<template>
  <div class="Quiz-Cta">

    <button
      v-if="isNext"
      @click="handleNextStep"
      class="Cta Cta--dark"
      :class="typeof this.selectedResponse !== 'undefined' ? this.selectedResponse.length !== 0 ? '' : 'Cta--inactive' : 'Cta--inactive'"
      :data-tracking-name="getTrackingName"
      data-form-selector="quiz-form"
      data-tracking="action"
      data-tracking-chapter1='quiz personnalisation contenu mb'
      data-tracking-chapter2=""
      data-tracking-chapter3=""
    >
      <span v-if="currentStepIndex < steps.length - 1" class="Cta-label">{{labels.nextQuestion}}</span>
      <span v-if="currentStepIndex === steps.length - 1" class="Cta-label">{{labels.lastQuestion}}</span>
    </button>

    <div v-if="currentStepIndex > 0 && isNext !== true" class="QuizCta-previousButton">
      <button @click="handlePreviousStep()" class="Cta Cta--light">
        <span>
          <svg class="icon icon-arrow-left" width="20" height="20" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path fill='currentColor' d='M492 236H68.442l70.164-69.824c7.829-7.792 7.859-20.455.067-28.284-7.792-7.83-20.456-7.859-28.285-.068l-104.504 104c-.007.006-.012.013-.018.019-7.809 7.792-7.834 20.496-.002 28.314.007.006.012.013.018.019l104.504 104c7.828 7.79 20.492 7.763 28.285-.068 7.792-7.829 7.762-20.492-.067-28.284L68.442 276H492c11.046 0 20-8.954 20-20s-8.954-20-20-20z'/></svg>
          <span class="Cta-label">{{labels.previousQuestion}}</span>
        </span>
      </button>
    </div>
    
  </div>
</template>

<script>
export default {
  name: "QuizNext",
  props:{
    labels: {
      required: true,
      type: Object
    },
    steps: {
      required: true,
      type: Array
    },
    currentStepIndex: {
      required: true,
      type: Number
    },
    selectedResponse: {
      required: true,
      type: [String, Array, Object]
    },
    isNext: {
      required: true,
      type: Boolean
    },
    quiz:{
      required: true,
      type: Object,
    }
  },
  computed:{
     getTrackingName: function() {
       let resp = (typeof this.selectedResponse === 'object') ? this.selectedResponse.value : this.selectedResponse
       let t = this.steps[this.currentStepIndex].title + '_' + resp + '_' + this.quiz.type +'_formulaire_mb_test'
      return t
    }
  },
  methods:{
    handleNextStep() {
      if(typeof this.selectedResponse !== 'undefined'){
        this.$emit("QuizNext::next-step", this.selectedResponse)
      }
    },
    handlePreviousStep() {
      this.$emit("QuizPrevious::previous-step")
    },
  }
}
</script>