import "matchmedia-polyfill/matchMedia"
import "matchmedia-polyfill/matchMedia.addListener"

const SEARCHBAR_COVER_SELECTOR = '[data-search-cover]'
const SEARCHBAR_NAVIGATION_SELECTOR = '[data-search-navigation]'
const SHOW_FORM_BUTTON_SELECTOR = '[data-searchbar-trigger]'
const SEARCH_FORM_SELECTOR = '[data-searchbar-form]'
const NAVIGATION_SELECTOR = '[data-navigation-container]'
const NAVIGATION_TRIGGERS_SELECTOR = '[data-trigger]'

const transitionTime = 300

const coverSearchbar = document.querySelector(SEARCHBAR_COVER_SELECTOR)
const navigationSearchbar = document.querySelector(SEARCHBAR_NAVIGATION_SELECTOR)
const navigation = document.querySelector(NAVIGATION_SELECTOR)
const navigationTriggers = document.querySelectorAll(NAVIGATION_TRIGGERS_SELECTOR)

function init () {
  
  if(!coverSearchbar && !navigationSearchbar) return

  if(coverSearchbar){
    initSearchCover(coverSearchbar) 
  }

  if(navigationSearchbar){
    initSearchNavigation(navigationSearchbar, navigation) 
  }

  initSearch(navigationSearchbar, coverSearchbar)

  const searchForm = navigationSearchbar.querySelector(SEARCH_FORM_SELECTOR)
  const linksContainer = navigation.querySelector('.Navigation-links')
  const container = navigation.querySelector('[data-container="firstlevel"]')
  const containerForm = container.firstElementChild

  navigationTriggers.forEach(element => {
    element.addEventListener('click', () => {
      hideNavigationForm(searchForm, linksContainer, container, containerForm)
    })
  });

}

function initSearch(navigationSearchbar, coverSearchbar){
  
  if(coverSearchbar){

    const coverSearchbarForm = coverSearchbar.querySelector('[data-searchbar-form]')
    const notFound = (coverSearchbarForm.getAttribute('data-notfound') === 'true')

    if(notFound){
      searchCover(coverSearchbar, true)
    }else{
      searchCover(coverSearchbar, false)
    }
  }
  
  if(navigationSearchbar){
    searchNav(navigationSearchbar)
  }

}

function searchCover (coverSearchbar, isGlobal) {

  const coverSearchbarForm = coverSearchbar.querySelector('[data-searchbar-form]')

  coverSearchbarForm.addEventListener('submit', (e) => {
    e.preventDefault()

    let formAction = coverSearchbarForm.getAttribute('action')
    let formType = coverSearchbarForm.getAttribute('data-form-type')
    let formValue = coverSearchbarForm.querySelector('.SearchBar-input').value

    if(isGlobal){
      formAction = formAction + '?searchName=' + formValue
    }else{
      formAction = formAction + '?isForced=true&filter='+ formType + "&searchName=" + formValue
    }

    coverSearchbarForm.setAttribute('action', formAction)
    window.location.href = formAction;
  })
}

function searchNav(navigationSearchbar){
  const navigationSearchbarForm = navigationSearchbar.querySelector('[data-searchbar-form]')

  navigationSearchbar.addEventListener('submit', (e) => {
    e.preventDefault()
    let formAction = navigationSearchbarForm.getAttribute('action')
    let formType = navigationSearchbarForm.getAttribute('data-form-type')
    let formValue = navigationSearchbarForm.querySelector('.SearchBar-input').value
    formAction = formAction + '?searchName=' + formValue
    navigationSearchbarForm.setAttribute('action', formAction)
    window.location.href = formAction;
  })
}

function initSearchCover(coverSearchbar) {

  const showFormButton = coverSearchbar.querySelector(SHOW_FORM_BUTTON_SELECTOR)

  hideCoverButton(showFormButton)
  showCoverForm(coverSearchbar, showFormButton)

}

function hideCoverButton (showFormButton) {
  showFormButton.addEventListener('click', () => {

    showFormButton.style.top = "-30px"
    showFormButton.style.opacity = 0

    setTimeout(() => {
      showFormButton.setAttribute('aria-hidden', "true")
    }, transitionTime);
  })
}

function showCoverForm(coverSearchbar, showFormButton) {

  const searchForm = coverSearchbar.querySelector(SEARCH_FORM_SELECTOR)

  showFormButton.addEventListener('click', () => {
    searchForm.style.display= "block"
    searchForm.setAttribute('aria-hidden', "false")

    setTimeout(() => {
      searchForm.style.top = "0"
      searchForm.style.opacity = 1
      searchForm.querySelector('.SearchBar-input').focus()
      coverSearchbar.setAttribute('data-search-cover', 'open')
    }, 10);

  })
}

function initSearchNavigation(navigationSearchbar, navigation) {

  const toggleFormButtons = navigation.querySelectorAll(SHOW_FORM_BUTTON_SELECTOR)

  toggleFormButtons.forEach(toggleFormButton => {
    toggleFormButton.addEventListener('click', () => {
  
      changeIcon(toggleFormButton)
      toggleForm(navigationSearchbar, navigation)
  
    })
  });
}

function changeIcon(toggleFormButton) {
  
  const searchcontainer = toggleFormButton.querySelector('span')

  const searchSvg = '<svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="8.73" cy="8.73" r="6.5" stroke="currentColor" stroke-width="3"/><path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M12.22 12.35a1.95 1.95 0 012.75 0l6.1 6a1.95 1.95 0 01-2.74 2.78l-6.1-6c-.77-.77-.78-2.01-.01-2.78z" fill="currentColor"/></svg>'
  const crossSvg = '<svg style="position:relative;left:-1px;" width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 4l14 14M18 4L4 18" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/></svg>'

  let toggleFormButtonStatus = toggleFormButton.getAttribute('data-searchbar-trigger')
  
  if(toggleFormButtonStatus === "search"){

    toggleFormButton.setAttribute('data-searchbar-trigger', 'cross')
    searchcontainer.innerHTML = crossSvg

  }else if(toggleFormButtonStatus === "cross"){

    toggleFormButton.setAttribute('data-searchbar-trigger', 'search')
    searchcontainer.innerHTML = searchSvg

  }

}

function toggleForm (navigationSearchbar, navigation) {
  
  const searchForm = navigationSearchbar.querySelector(SEARCH_FORM_SELECTOR)

  // init the top value the transition happen at first time
  if (window.matchMedia("screen and (max-width: 1100px)").matches) {
    searchForm.style.top = "-250px"
  }

  const isHidden = (searchForm.getAttribute('aria-hidden') == 'true' )
  const linksContainer = navigation.querySelector('.Navigation-links')
  const container = navigation.querySelector('[data-container="firstlevel"]')
  const containerForm = container.firstElementChild
  const navigationMobile = document.querySelector('.Navigation-mobile')

  if(isHidden){
    showNavigationForm(searchForm, linksContainer, container, containerForm)
    navigationMobile.setAttribute('aria-hidden', 'true')
  }else{
    hideNavigationForm(searchForm, linksContainer, container, containerForm)
    navigationMobile.setAttribute('aria-hidden', 'false')
  }
  
}

function showNavigationForm(searchForm, linksContainer, container, containerForm) {

  if (window.matchMedia("screen and (min-width: 1100px)").matches) {
    linksContainer.style.opacity = 0
  }

  setTimeout(() => {

    if (window.matchMedia("screen and (min-width: 1100px)").matches) {
      linksContainer.style.display = "none"
      container.style.width = "100%"
      containerForm.style.width = "100%"
    }

    searchForm.style.display = "block"
    searchForm.setAttribute('aria-hidden', 'false')

    setTimeout(() => {
      searchForm.style.opacity = 1
      if (window.matchMedia("screen and (min-width: 1100px)").matches) {

        searchForm.style.top = 0

      }else if(window.matchMedia("(min-width: 700px) and (max-width: 1100px)").matches){

        searchForm.style.top = "-120px"

      }else{

        searchForm.style.top = "-80px"

      }

      searchForm.querySelector('.SearchBar-input').focus()
    }, 100);
  }, 200);
}

function hideNavigationForm(searchForm, linksContainer, container, containerForm) {

  searchForm.style.opacity = 0

  if (window.matchMedia("screen and (max-width: 1100px)").matches) {
    searchForm.style.top = "-250px"
  }

  setTimeout(() => {
    searchForm.setAttribute('aria-hidden', 'true')
    searchForm.style.display = "none"

    if (window.matchMedia("screen and (min-width: 1100px)").matches) {

      container.removeAttribute('style')
      containerForm.removeAttribute('style')
      linksContainer.style.display = "flex"

      setTimeout(() => {
        linksContainer.style.opacity = 1
      }, 100);

    }
  }, 200);
}

export default { init }


