import {axiosClient} from './axiosClient'
import slideContent from './slidecontent';
import foodlListDrawer from "../components/foodlistdrawer/foodlistdrawer"
import {clickActionInit} from "./tracking.js";

document.addEventListener('DOMContentLoaded', (event) => {

  //Selectors
  const SEASON_FILTER_SELECTOR = '[data-foodList-seasonfilter]'
  const FIELD_SEARCH_FOOD_SELECTOR = '[data-search-food]'
  const FOODS_LIST_SELECTOR = '[data-foods-list]'
  const NEXT_MONTH_SELECTOR = '[data-nextMonth]'
  const PREVIOUS_MONTH_SELECTOR = '[data-previousMonth]'
  const PAGE_TITLE_SELECTOR = '.Cover-title span'


  const init = () => {
    const seasonfilter = document.querySelector(SEASON_FILTER_SELECTOR);

    if (!seasonfilter) {
      return
    }

    /****************/
    /* Ajax handler */

    const searchInput = document.querySelectorAll(FIELD_SEARCH_FOOD_SELECTOR)[0]

    // Call ajax to filters foods by search food name
    function ajaxSearchFoodsByName(element) {
      if (element.target.value.length > 2) {
        axiosClient.get('/search/foods', {params: {name: element.target.value}})
          .then((response) => {
            console.log(response.data)
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }

    // Call ajax to filters foods by params selected (month and/or categories)
    function ajaxFiltersFoods() {
      let url = window.location.pathname
      if (window.location.hostname === 'localhost') {
        url = '/foods'
      }

      axiosClient.get(url, {params: filtersParams})
        .then((response) => {
          if (window.location.hostname === 'localhost') {
            console.log('response', response)
          } else {
            document.querySelectorAll(FOODS_LIST_SELECTOR)[0].innerHTML = response.data.updated_foods
            document.querySelectorAll(PAGE_TITLE_SELECTOR)[0].innerHTML = response.data.page_title
            window.history.pushState('', response.data.page_title, response.data.page_url)
            // we relaunch slide content to handle new html
            slideContent.init()
            foodlListDrawer.init()

            // call navigation tracking init to add click listener on ajax reponse
            clickActionInit()
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    /***********************/
    /* Search food handler */

    if (searchInput) {
      searchInput.addEventListener('input', ajaxSearchFoodsByName);
      searchInput.addEventListener('propertychange', ajaxSearchFoodsByName);
    }

    /************************/
    /* Month filter handler */

    const month = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre']
    const nextMonthBtn = document.querySelector(NEXT_MONTH_SELECTOR)
    const previousMonthBtn = document.querySelector(PREVIOUS_MONTH_SELECTOR)
    const listeners = ['click']
    const filters = document.querySelectorAll('.Seasonfilter-filters .Cta');

    const monthData = document.querySelector(".Seasonfilter-month").getAttribute('data-month')
    let monthDisplayContainer = document.querySelector('.Seasonfilter-month span')
    let currentMonth = monthData !== null ? parseInt(monthData) : new Date().getMonth()
    let monthToDisplay = month[currentMonth]

    let filtersParams = {
      month: currentMonth,
      categories: []
    };

    // Display the current Month when the Dom is loaded
    monthDisplayContainer.innerHTML = monthToDisplay

    // Write the Dom with the selectedMonth
    let showMonth = (selectedMonth) => {
      monthDisplayContainer.innerHTML = selectedMonth
    }

    // Write the Next month value in the DOM
    nextMonthBtn.addEventListener('click', (e) => {
      monthToDisplay = month[currentMonth + 1]
      currentMonth = month.indexOf(monthToDisplay)
      if (currentMonth === -1) {
        monthToDisplay = month[0]
        currentMonth = month.indexOf(monthToDisplay)
      }
      showMonth(monthToDisplay)
      filtersFoodsPerMonth(currentMonth)
    })

    // Write the Previous month value in the DOM
    previousMonthBtn.addEventListener('click', (e) => {
      monthToDisplay = month[currentMonth - 1]
      currentMonth = month.indexOf(monthToDisplay)
      if (currentMonth === -1) {
        monthToDisplay = month[11]
        currentMonth = month.indexOf(monthToDisplay)
      }
      showMonth(monthToDisplay)
      filtersFoodsPerMonth(currentMonth)
    })

    // Filters foods per month selected
    function filtersFoodsPerMonth(currentMonth) {
      filtersParams.month = currentMonth;
      ajaxFiltersFoods();
    }

    /***************************/

    /* Category filter handler */

    function resetAttribute(array) {
      array.forEach((elem) => {
        elem.setAttribute('data-active', 'false')
      })
    }

    // Loop trough all filters
    filters.forEach((filter) => {
      // Add every listeners to the filter
      listeners.forEach((listener) => {
        // Listen the object
        filter.addEventListener(listener, (e) => {
          resetAttribute(filters);
          filter.setAttribute('data-active', 'true')
          filtersFoodsPerCategories(filters)
        })
      })
    })

    // Add or remove category from filters categories params corresponding to selected category
    function setFiltersCategoriesParams(filters, isRecursive = false) {
      filtersParams.categories = [];

      filters.forEach((filter) => {
        if (isRecursive && filter.dataset.category !== 'all') {
          filter.dataset.active = 'false';
        } else if (filter.dataset.active === 'true' && !isRecursive) {
          if (filter.dataset.category === 'all') {
            filtersParams.categories = [];
            setFiltersCategoriesParams(filters, true);
          }
          filtersParams.categories.push(filter.dataset.category)
        }
      });
    }

    // TODO : fix call 3 times ajax
    // Filters foods per categories
    function filtersFoodsPerCategories(filters) {
      setFiltersCategoriesParams(filters);
      ajaxFiltersFoods();
    }

  }

  init();

})
