<template>
  <div class="QuizStep">
      
    <!-- <h2 v-if="quiz.type !== 'Activity'" class="title2 titleBackground">
      <span>{{quiz.title}}</span>
    </h2> -->

    <component
      :is="quiz.type !== 'Activity' ? 'QuizQuestion': 'QuizQuestionActivity'"
      :step="steps[currentStepIndex]"
      :steps="steps"
      :currentStepIndex="currentStepIndex"
      :labels="labels"
      :quiz="quiz"
      :key="currentStepIndex"
      @QuizQuestion::next-step="handleNextStep"
      @QuizQuestion::previous-step="handlePreviousStep"
      @QuizQuestion::on-stage-answer="handleStageAnswer"
      @QuizQuestionActivity::next-step="handleNextStep"
      @QuizQuestionActivity::previous-step="handlePreviousStep"
      @QuizQuestionActivity::on-stage-answer="handleStageAnswer"
    ></component>

  </div>
</template>

<script>
import QuizQuestion from "../QuizQuestion/QuizQuestion.vue"
import QuizQuestionActivity from "../QuizQuestionActivity/QuizQuestionActivity.vue"
import QuizStepper from "../QuizStepper/QuizStepper.vue"

export default {
  name: "QuizStep",
  components:{
    QuizQuestion,
    QuizStepper,
    QuizQuestionActivity
  },
  props: {
    step: {
      required: true,
      type: Object
    },
    steps: {
      required: true,
      type: Array
    },
    currentStepIndex: {
      required: true,
      type: Number
    },
    labels: {
      required: true,
      type: Object
    },
    quiz:{
      required: true,
      type: Object,
    },
    picture: {
      required: true,
      type: String,
    },
  },
  beforeMount() {

    if(this.steps[this.currentStepIndex] !== 'undefined'){

      if(this.quiz.type === "Perso"){
        tc_vars.form_name = "Quiz de personnalisation"
      }else if(this.quiz.type === "Activity"){
        // do not reload container for first question, cause it is down by "Commencer le test" button
        if(this.currentStepIndex === 0){
          return
        }

        tc_vars.form_name = "Bilan d'activité physique"
      }

      tc_vars.contains_form_infos = true
      tc_vars.form_has_step = true
      tc_vars.form_step_name= this.steps[this.currentStepIndex].id +"- " + this.steps[this.currentStepIndex].title
      tc_vars.form_step_number = this.steps[this.currentStepIndex].id
      tc_vars.form_is_confirmation = false;

      tC.container.reload({events: {pageLoad: [{},{}]}});
    }
  },
  methods:{
    handleNextStep(selectedResponse) {
      if(typeof selectedResponse !== 'undefined'){
        this.$emit("QuizStep::next-step", selectedResponse)
      }
    },
    handlePreviousStep() {
      this.$emit("QuizStep::previous-step")
    },
    handleStageAnswer(selectedResponse) {
      this.$emit('QuizStep::on-stage-answer', selectedResponse)
    },
  },
}
</script>
