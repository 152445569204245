var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "QuizHeader" }, [
    _c("h2", { staticClass: "title2 titleBackground" }, [
      _c("span", [_vm._v(_vm._s(_vm.step.title))])
    ]),
    _vm._v(" "),
    _c("figure", { staticClass: "QuizHeader-image" }, [
      _c("img", { attrs: { src: _vm.step.picture, alt: _vm.step.pictureDesc } })
    ]),
    _vm._v(" "),
    _vm.step.infos
      ? _c("div", { staticClass: "QuizHeader-infos" }, [
          _c("div", { staticClass: "QuizHeader-infoscontainer" }, [
            _c("span", { staticClass: "QuizHeader-infosBubble" }, [
              _vm._v("i")
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "QuizHeader-infosText" }, [
              _vm._v(_vm._s(_vm.step.infosTitle))
            ])
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "QuizHeader-infosText" }, [
            _vm._v(_vm._s(_vm.step.infos))
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.step.text
      ? _c("p", {
          staticClass: "QuizHeader-text",
          domProps: { innerHTML: _vm._s(_vm.step.text) }
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.step.exemple
      ? _c("p", { staticClass: "QuizHeader-exemple" }, [
          _vm._v(_vm._s(_vm.step.exemple))
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }