/*! Drawer Javascript */

class Drawer {

  constructor(inPageButtons, drawer, drawerButtons) {
    this.inPageButtons = inPageButtons
    this.drawer = drawer
    this.drawerButtons = drawerButtons
    this.animationDelayTimer = 500
    this.body = document.querySelector('body')
  }

  init() {
    if (this.inPageButtons.length < 1 || this.drawer === null) {
      return
    } else {
      this.initTwoStepOpener(this.drawer)
      this.KeyboardCloseDrawer(this.inPageButtons, this.drawer) // Accessibility
      this.keyboardLoopTabulation(this.drawer) // Accessibility
      this.initDrawer(
        this.inPageButtons,
        this.drawer,
        this.drawerButtons
      )
      this.onHashChange(this.drawer)

    }
  }

  initDrawer(inPageButtons, drawer, drawerButtons) {

    inPageButtons.forEach(inPageButton => {
      inPageButton.addEventListener('click', (e) => {

        this.onButtonClick(inPageButton)

        if (inPageButton.getAttribute('aria-expanded') === "true") {
          this.closeCurrentDrawer(inPageButtons, drawer)
        } else {
          this.openTargetDrawer(inPageButton, drawer, inPageButtons)
        }

      })
    });

    this.initButtonDrawer(drawerButtons, inPageButtons, drawer)

  }

  KeyboardCloseDrawer(inPageButtons, drawer) {    
    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        const isOpen = (document.querySelector('body').getAttribute('data-drawer-open') === "open")
        if(isOpen) {
          this.closeCurrentDrawer(inPageButtons, drawer)
        }
      }
    });
  }

  keyboardLoopTabulation(drawer) {

    const loopStartElement = drawer.querySelector('[data-drawer-loop-start]')
    const loopEndElement = drawer.querySelector('[data-drawer-loop-end]')

    const dummyStart = drawer.querySelector('[data-drawer-dummytoFocus-start]')
    const dummyEnd = drawer.querySelector('[data-drawer-dummytoFocus-end]')

    loopStartElement.addEventListener('focus', function(e){
      dummyEnd.focus()
    }, true )
      
    loopEndElement.addEventListener('focus', function(e){
      dummyStart.focus()
    }, true )
  }

  initButtonDrawer(drawerButtons, inPageButtons, drawer) {
    drawerButtons.forEach(drawerButton => {      
      drawerButton.addEventListener('click', () => {
        this.closeCurrentDrawer(inPageButtons, drawer)
      })
    });
  }

  closeCurrentDrawer(buttons, drawer) {
    this.closeDrawer(drawer)
    this.resetButtonsAttribute(buttons)
  }
  
  closeDrawer(drawer) {
    this.body.setAttribute('data-drawer-open', 'close')
    drawer.setAttribute('aria-hidden', 'true')
    this.removeElementAttribute(drawer, 'style')
    this.onCloseDrawer ()
  }

  resetButtonsAttribute(buttons) {
    buttons.forEach(button => {
      this.resetButtonAttribute(button)
    });
  }

  resetButtonAttribute(button) {
    button.setAttribute('aria-expanded', 'false')
  }

  openTargetDrawer(button, drawer, inPageButtons) {

    if(this.body.getAttribute('data-drawer-open') === "open"){
      this.closeCurrentDrawer(inPageButtons, drawer)
    }


    // A timeout is used so the animation of the drawer when it has to close then re-open has time to be played 
    setTimeout(() => {

      // Start functions that can be overridden
      this.onOpenDrawer(button, drawer)
      this.addDrawerContentOverflow(drawer)
      // End functions that can be overridden
      
      this.activeButtonAttribute(button)
      this.openDrawer(drawer);
      
    }, this.animationDelayTimer);

  }
    
  activeButtonAttribute(button) {
    button.setAttribute('aria-expanded', 'true')
  }
  
  openDrawer(drawer) {
    this.body.setAttribute('data-drawer-open', 'open')
    drawer.setAttribute('aria-hidden', 'false')

    this.focusDrawerOnOpen(drawer)
    // drawer.focus()
  }

  focusDrawerOnOpen(drawer) {
    const isTwostep = (drawer.getAttribute('data-drawer-twostep') !== null)
    if(isTwostep){
      const twostepbtn = drawer.querySelector('[data-drawer-twostepbtn]')
      twostepbtn.focus()
    }else{
      const dummyStart = drawer.querySelector('[data-drawer-dummytoFocus-start]')
      dummyStart.focus()
    }

  }


  // This function can be overridden to implement a custom feature on Drawer opening
  onOpenDrawer (button, drawer, inPageButtons) {}

  // This function can be overridden to implement a custom feature on Drawer opening
  onCloseDrawer () {}

  // This function can be overridden to implement a custom feature on Drawer opening
  initTwoStepOpener (drawer) {}
  
  // This function can be overridden to implement a custom feature on Drawer closing
  removeElementAttribute(element, attribute) {}

  // This function can be overridden to implement a custom feature on Drawer opening
  addHeightToDrawer(drawer, HeightValue) {}
  
  // This function can be overridden to implement a custom feature on Drawer opening
  addDrawerContentOverflow(drawer) {}

  onHashChange(drawer){}

  onButtonClick(inPageButton) {}

}

// Export the instance 
export default Drawer