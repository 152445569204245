/**
 * Filtered List Loader
 * Handle list with filters and load more
 */

import axios from 'axios'
import {hashCode} from "./utils";
import {clickNavigationInit} from "./tracking";


const LIST_CONTAINER_SELECTOR = '[data-rubric-content-loader-container]'
const LOADING_SKELETON_SELECTOR = '[data-rubric-content-loader-loading-skeleton]'

let baseUrl = ''

function init() {
  const currentSection = document.querySelector(LIST_CONTAINER_SELECTOR)
  if (currentSection) {
    handleLoad()
  }
}

async function handleLoad() {
  const axiosInstance = axios.create();
  axiosInstance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

  const queryParameters = []

  queryParameters.push(`ajax=true`)
  queryParameters.push(`content=true`)

  let queryString = queryParameters.join('&')

  let quizResult = localStorage.getItem('QuizPersoResults');

  // generate an unique hash from quizResult to add to ajax url, in order to generate a new varnish cache variation
  if( quizResult ) {
    let quizHash = hashCode(quizResult)
    queryString = queryString + '&perso=' + quizHash
  }

  let url = baseUrl

  if (queryString !== "") {
    url = `${baseUrl}?${queryString}`
  }

  document.querySelector(LIST_CONTAINER_SELECTOR).innerHTML = document.querySelector(LOADING_SKELETON_SELECTOR).innerHTML

  const response = await axiosInstance.get(url)

  if (response.data === 'no_more_results') {
    document.querySelector(LIST_CONTAINER_SELECTOR).innerHTML = '<h3 class="title2 titleBackground" style="width: 100%;text-align: center;padding-left: 40px;"><span>OUPS !</span></h3><p class="Gridcard-emptyText">Aucun contenu ne correspond</p>'
  } else {
    document.querySelector(LIST_CONTAINER_SELECTOR).innerHTML = response.data.content
  }

    // call navigation tracking init to add click listener on ajax reponse
    clickNavigationInit()
}

export default {init}