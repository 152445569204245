function init() {
  const BTN_INTRO_QUIZ_SELECTOR = "[show-activity-quiz]"
  const btnIntroQuiz = document.querySelector(BTN_INTRO_QUIZ_SELECTOR)

  const quizActivity = document.querySelector(".quiz-bilan-activity")
  const introQuizActivity = document.querySelector(".intro-quiz-bilan-activity")

  if(btnIntroQuiz && quizActivity && introQuizActivity){
    btnIntroQuiz.addEventListener('click', (e) => {
      // show quiz
      introQuizActivity.setAttribute('aria-hidden', "true")
      setTimeout(() => {
        quizActivity.setAttribute('aria-hidden', "false")
      }, 100);

      // init tag container reload with step 1
      tc_vars.form_name = "Bilan d'activité physique"
      tc_vars.contains_form_infos = true
      tc_vars.form_has_step = true
      tc_vars.form_step_name= "1 - Activités de travail et domestiques"
      tc_vars.form_step_number = 1
      tc_vars.form_is_confirmation = false;

      tC.container.reload({events: {pageLoad: [{},{}]}});

    })

  }
}

export default { init }



