/**
 * dynamic flash message handler
 * Handle Gridcard for ActivityQuiz
 */

const FLASH_MESSAGE_SELECTOR = "[data-flash-message]"
const FLASH_MESSAGE_BUTTON_SELECTOR = "[data-flash-message-trigger]"
const FLASH_MESSAGE_TEXT_SELECTOR = "[data-flash-message-text]"

const flashMessage = document.querySelector(FLASH_MESSAGE_SELECTOR)
const flashMessageButton = document.querySelector(FLASH_MESSAGE_BUTTON_SELECTOR)
const flashMessageText = document.querySelector(FLASH_MESSAGE_TEXT_SELECTOR)

let text = ''

function init(forceParams = []) {

  if(!flashMessage) return

  flashMessageBehavior()

  const urlParams = new URLSearchParams(window.location.search)
  const isOk = forceParams.length !== 3 && urlParams.has('action') && urlParams.get('action').length && urlParams.has('result') && urlParams.get('result').length

  if (isOk) {

    if (urlParams.get('result') === "KO") {

      switch (urlParams.get('action')) {
        case 'add' :
          text = "Échec de l'ajout au favoris, veuillez réessayer plus tard"
          break;
        case 'remove' :
          text = "Échec de la suppression des favoris, veuillez réessayer plus tard"
          break;
        default:
          return false;
      }

      flashMessageText.setAttribute('data-flash-message-text', 'invalid')
      flashMessage.classList.add('FlashMessage-alert')

    } else {

      switch (urlParams.get('action')) {
        case 'add' :
          text = "Contenu ajouté aux favoris avec succès !"
          break;
        case 'remove' :
          text = "Contenu retiré des favoris avec succès !"
          break;
        default:
          return false;
      }
      
      flashMessageText.setAttribute('data-flash-message-text', 'valid')
      flashMessage.classList.add('FlashMessage-notice')
    }

    removeCurrentUrlParameter('result');
    removeCurrentUrlParameter('action');
    showFlashMessage()

  } else if ('for' in forceParams && 'action' in forceParams && 'msg' in forceParams) {

    if( forceParams['for'] !== '' ) {
      text = forceParams['for'] + ' - ' + forceParams['msg']
    } else {
      text = forceParams['msg']
    }

    switch (forceParams['action']) {
      case 'success' :
        flashMessageText.setAttribute('data-flash-message-text', 'valid')
        flashMessage.classList.add('FlashMessage-notice')
        break;
      case 'failed' :
        flashMessageText.setAttribute('data-flash-message-text', 'invalid')
        flashMessage.classList.add('FlashMessage-alert')
        break;
      default:
        return false;
    }

    showFlashMessage()
  }
  
  return false
}

function showFlashMessage() {
  
  flashMessageText.innerHTML = text
  flashMessage.setAttribute('aria-hidden', 'false')

  setTimeout(() => {
    hideFlashMessage()
  }, 5000);
}

function flashMessageBehavior() {
  flashMessageButton.addEventListener('click', () => {
    flashMessage.setAttribute('aria-hidden', 'true')
  })
}

function hideFlashMessage() {
  const isHidden = (flashMessage.getAttribute('aria-hidden') === "true")
  
  if(!isHidden){
    flashMessage.setAttribute('aria-hidden', 'true')
  }
}

function removeCurrentUrlParameter(param){

  const url = new URL(window.location.href)
  const params = new URLSearchParams(url.search.slice(1))
  params.delete(param)

  let newUrl = window.location.href;

  if(Array.from(params).length > 0) {
    newUrl = `${window.location.pathname}?${params}${window.location.hash}`;
  } else {
    newUrl = `${window.location.pathname}${window.location.hash}`;
  }

  window.history.replaceState(
    {},
    '',
    newUrl,
  )

}

export default { init }