<template>
  <div class="QuizVue" data-quiz-name="customisation" data-theme="green">

    <h3 v-if="currentStepIndex !== null && quiz.type !== 'Activity'" class="Quiz-name title2 titleBackground titleBackground--green">
      <span>Quiz de personnalisation</span>
    </h3>

    <transition name="fade" mode="out-in">
      <QuizStepper
        v-if="currentStepIndex !== null"
        :currentStepIndex="currentStepIndex"
        :steps="steps"
      />
    </transition>

    <transition name="fade" mode="out-in">
      <component
        :is="componentType"
        :labels="labels"
        :quiz="quiz"
        :steps="steps"
        :assets="assets"
        :step="steps[currentStepIndex]"
        :picture="assets.quizStartPicture"
        :currentStepIndex="currentStepIndex"
        :key="currentStepIndex"
        :buttonText="buttonText"
        @QuizStart::next-step="handleNextStep"
        @QuizStep::next-step="handleNextStep"
        @QuizStep::previous-step="handlePreviousStep"
        @QuizStep::on-stage-answer="handleStageAnswer"
        @QuizStep::reset-step="handleResetStep"
        @QuizStep::save-quiz="handleSaveQuiz"
        @QuizEnd::reset-soft="resetSoft"
      ></component>
    </transition>

    <transition name="fade" mode="out-in">
      <div class="Quiz-loader" aria-hidden="true" data-quiz-loader></div>
    </transition>

  </div>
</template>

<script>
import QuizStart from "./components/QuizStart/QuizStart.vue"
import QuizStep from "./components/QuizStep/QuizStep.vue"
import QuizEnd from "./components/QuizEnd/QuizEnd.vue"
import QuizQuestion from "./components/QuizQuestion/QuizQuestion.vue"
import QuizStepper from "./components/QuizStepper/QuizStepper.vue"
import {axiosClient} from '../../scripts/axiosClient'
import activityQuizGridcard from '../../scripts/activityQuizGridcard'
import flashMessage from '../flashMessage/flashMessage'


export default {
  name: "Quiz",
  components: {
    QuizStart,
    QuizStep,
    QuizQuestion,
    QuizStepper,
    QuizEnd,
  },
  props: {
    quiz: {
      required: true,
      type: Object,
    },
    steps: {
      required: true,
      type: Array
    },
    labels: {
      required: true,
      type: Object
    },
    assets: {
      required: true,
      type: Object
    },
    render: {
      required: false,
      type: Object
    }
  },
  data() {
    return {
      currentStepIndex: this.quiz.type === "Activity" ? 0 : null,
      stagedAnswers: [],
      componentType: this.quiz.type === "Activity" ? 'QuizStep' : 'QuizStart', // TODO faire une computed
      skipManager: [],
      buttonText: "Je sauvegarde mon résultat"
    }
  },
  computed: {},
  methods: {
    resetSoft(){
      this.componentType = 'QuizStart'
      this.currentStepIndex = null
    },
    handleNextStep(answer) {
      if (this.currentStepIndex === null) {

        this.currentStepIndex = 0
        this.componentType = 'QuizStep'
        this.stagedAnswers = []

      } else {
        if (this.currentStepIndex < this.steps.length) {
          if (typeof answer === 'object' && answer.hasOwnProperty('skipToId')) {
            let currentStepIndexOld = this.currentStepIndex

            while (this.currentStepIndex in this.steps && this.steps[this.currentStepIndex].id !== answer.skipToId && this.currentStepIndex < this.steps.length) {
              this.stagedAnswers.push("")
              this.currentStepIndex++
            }

            this.skipManager[this.currentStepIndex] = currentStepIndexOld;
            localStorage.setItem('Quiz'+this.quiz.type+'Results', this.stagedAnswers);

          } else {
            this.currentStepIndex++
          }
        }else{
          this.componentType = 'QuizEnd'
        }

      }
    },
    handlePreviousStep() {

      let currentStepIndexOld
      
      if (this.currentStepIndex > 0) {
        if (this.currentStepIndex in this.skipManager) {

          currentStepIndexOld = this.currentStepIndex;
          this.currentStepIndex = this.skipManager[this.currentStepIndex]
          delete this.skipManager[this.currentStepIndex]
        
        } else {
          this.currentStepIndex--
        }
      }

      if (typeof currentStepIndexOld !== 'undefined' && currentStepIndexOld in this.stagedAnswers && this.currentStepIndex in this.stagedAnswers) {

        this.stagedAnswers.splice(this.currentStepIndex, currentStepIndexOld - this.currentStepIndex)
        localStorage.setItem('Quiz'+this.quiz.type+'Results', this.stagedAnswers);
        
      } else {
        
        if (this.stagedAnswers[this.currentStepIndex] || this.stagedAnswers[this.currentStepIndex].length) {
          this.stagedAnswers.splice(this.currentStepIndex, 1)
          localStorage.setItem('Quiz'+this.quiz.type+'Results', this.stagedAnswers);
        }
        
      }
    },
    async handleStageAnswer(answer) {
    
      if (this.quiz.type === 'Activity') {
        if (typeof answer !== 'object' ) {
          this.stagedAnswers.push(answer)
        }
      }else{
        this.stagedAnswers.push(answer)
      }

      localStorage.setItem('Quiz'+this.quiz.type+'Results', this.stagedAnswers);

      if (this.currentStepIndex === this.steps.length) {

        if (this.quiz.type === "Perso") {
          this.componentType = 'QuizEnd'
          axiosClient.post(
            '/save/quiz/personalization',
            {personalization_quiz: JSON.stringify(this.stagedAnswers)}
          )
            .then((response) => {
              flashMessage.init({
                'for': 'Quiz de personnalisation',
                'action': 'success',
                'msg': 'Sauvegarde Reussie'
              })
            })
            .catch((error) => {
              flashMessage.init({
                'for': 'Quiz de personnalisation',
                'action': 'failed',
                'msg': 'Sauvegarde échoué'
              })
              console.log(error);
            });
        } else if (this.quiz.type === "Activity") {
          await this.handleSaveQuiz(false)
        }
      }
    },
    AnswerRender() {
      // is answered, show quizz and hide intro
      const quizActivity = document.querySelector(".quiz-bilan-activity")
      const introQuizActivity = document.querySelector(".intro-quiz-bilan-activity")

      if(quizActivity && introQuizActivity){
          introQuizActivity.setAttribute('aria-hidden', "true")
          quizActivity.setAttribute('aria-hidden', "false")
      }

      this.quiz.quizResult =  this.render

      const crossLink = document.querySelector('#quiz-crosslink')
      if(crossLink !== undefined)
      {
          crossLink.innerHTML = this.render.render.crosslinks
      }

      this.componentType = 'QuizEnd'
      this.currentStepIndex = this.steps.length - 1
      activityQuizGridcard.init()
    },
    handleResetStep() {
      this.currentStepIndex = 0
      this.componentType = 'QuizStep'
      this.stagedAnswers = []

      const crossLink = document.querySelector('#quiz-crosslink')
      if(crossLink !== undefined)
      {
          crossLink.innerHTML = ""
      }
    },
    async handleSaveQuiz(isSave = false) {
      const quizLoader = document.querySelector('[data-quiz-loader]');
      quizLoader.setAttribute('aria-hidden', 'false')

      await axiosClient.post(
        '/save/quiz/activity',
        {activity_quiz: JSON.stringify(this.stagedAnswers), isSave: isSave}
      )
        .then(async (response) => {

          if (isSave) {
            let saveButton = document.querySelector('[data-save-quiz]')
            this.buttonText= "Sauvegarde Effectuée"
            flashMessage.init({
              'for': 'Quiz de Bilan d\'activité',
              'action': 'success',
              'msg': 'Sauvegarde Reussie'
            })
            saveButton.removeAttribute("disabled")
          }

          this.quiz.quizResult =  await response.data
          this.componentType = 'QuizEnd'

          if (response.data.render.crosslinks !== 'no_results') {
            document.querySelector('#quiz-crosslink').innerHTML = await response.data.render.crosslinks
            activityQuizGridcard.init()
          }

          if(response.data){
            quizLoader.setAttribute('aria-hidden', 'true')
          }
        })
        .catch((error) => {
          console.log(error);
          if (isSave) {
            let saveButton = document.querySelector('[data-save-quiz]')
            flashMessage.init({
              'for': 'Quiz de Bilan d\'activité',
              'action': 'failed',
              'msg': 'Sauvegarde échoué'
            })
            this.buttonText= "Sauvegarde Non Effectuée"
            saveButton.removeAttribute("disabled")
          } else {
            flashMessage.init({
              'for': 'Quiz de Bilan d\'activité',
              'action': 'failed',
              'msg': 'Echec Chargement'
            })
          }
        });
    }
  },
  beforeMount(){
    if (this.render !== null && this.render.render && this.quiz.type === "Activity") {
      this.AnswerRender()
    }
  },
  created() {
    const quizDrawer = document.querySelector('[data-drawer-quiz]')
    
    if (quizDrawer) {
      const quizCross = quizDrawer.querySelector('[data-drawer-btn]')
      
      quizCross.addEventListener('click', () => {
        this.componentType = 'QuizStart'
        this.currentStepIndex = null
        this.stagedAnswers = []
      })

    }

  }
}
</script>