<template>
  <div class="QuizHeader">

    <h2 class="title2 titleBackground">
      <span>{{step.title}}</span>
    </h2>

    <figure class="QuizHeader-image">
      <img :src="step.picture" :alt="step.pictureDesc">
    </figure>

    <div v-if="step.infos" class="QuizHeader-infos">

      <div class="QuizHeader-infoscontainer">
        <span class="QuizHeader-infosBubble">i</span>
        <span class="QuizHeader-infosText">{{step.infosTitle}}</span>
      </div>

      <p class="QuizHeader-infosText">{{step.infos}}</p>
      
    </div>
    
    <p v-if="step.text" v-html="step.text" class="QuizHeader-text"></p>

    <p v-if="step.exemple" class="QuizHeader-exemple">{{step.exemple}}</p>

    
  </div>
</template>

<script>

export default {
  name: "QuizHeader",
  props:{
    step: {
      required: true,
      type: Object
    },
  }
}
</script>