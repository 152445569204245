
const CONTAINER_SELECTOR = '[data-foodlist-seasonfilter]'
const NAVIGATION_SELECTOR = '[data-navigation-container]'
const HEADER_SELECTOR = '[data-header]'


const container = document.querySelector(CONTAINER_SELECTOR)
const navigation = document.querySelector(NAVIGATION_SELECTOR)
const header = document.querySelector(HEADER_SELECTOR)

let happenOnce = false


let offsetValue // value of the scroll to make the element sticky
let breakpoint // When the element is set to fix (scroll less offset)
let scrollValue // Value of the scroll
let navigationHeight = navigation.offsetHeight; // Should be around 110px 
let containerParent // the container dom parent element 
let containerPaddingTop
let containerPaddingBottom

function init() {
  
  if(!container) return

  containerParent = container.parentElement
  containerPaddingTop = parseInt(window.getComputedStyle(container, null).getPropertyValue('padding-top').slice(0, -2))
  containerPaddingBottom = parseInt(window.getComputedStyle(container, null).getPropertyValue('padding-bottom').slice(0, -2))

  initSticky()
}

function initSticky() {
  offsetValue = getTotalOffset()
  
  goSticky() // launch once so everything is after page load
  
  window.addEventListener("scroll", goSticky) // add the listener on scroll 
}

function goSticky () {

  scrollValue = window.pageYOffset;
  toggleSticky(scrollValue)

}

// Make the element sticky on top of the page (less the offset if there is one)
function toggleSticky (scrollValue) {

  let offsetCover 
  if (window.matchMedia("screen and (max-width: 700px)").matches) {
    offsetCover = 40
  }else{
    offsetCover = 60
  }
  breakpoint = scrollValue  <= offsetValue - offsetCover
  
  

  if (!breakpoint) {
    if(!happenOnce) {
      happenOnce = true
      container.classList.add('isSticky')
      header.classList.add('isSticky')
      container.style.top = `${navigationHeight}px`
      containerParent.style.minHeight =  `${container.offsetHeight}px`;
      containerParent.parentElement.style.zIndex =  10;
    }
  }else{
    if(happenOnce) {
      container.classList.remove('isSticky')
      header.classList.remove('isSticky')
      container.style.removeProperty('top')
      // containerParent.style.removeProperty('min-height')
      containerParent.parentElement.style.removeProperty('z-index')
      happenOnce = false
    }
  }
}

// Get the value of the scroll needed to make the element sticky 
// Return the position.top of the element less his own padding 
function getTotalOffset() {
  const containerPadding = getVerticalPadding(container)
  const containerTopPosition = offsetElement(container).top

  return containerTopPosition - containerPadding
}

// Get padding of an element
// Return element padding top + and bottom
function getVerticalPadding(el) {
  const containerPaddingTop = parseInt(window.getComputedStyle(el, null).getPropertyValue('padding-top').slice(0, -2))
  const containerPaddingBottom = parseInt(window.getComputedStyle(el, null).getPropertyValue('padding-top').slice(0, -2))

  return containerPaddingTop + containerPaddingBottom
}

// Get The Offet of an element 
// Return an Object with top and left position 
function offsetElement(el) {
  var rect = el.getBoundingClientRect(),
  scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
  scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
}

export default { init }