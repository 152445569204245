/**
 * Slide Content
 * Ths script open a div with an animation based on height
 */

const CONTAINER_SELECTOR = "[data-slide]";
const BUTTON_SELECTOR = "[data-slide-btn]";
const CONTENT_SELECTOR = "[data-slide-content]";

function init() {
  const container = document.querySelectorAll(CONTAINER_SELECTOR);
  container.forEach((element) => {
    const buttons = element.querySelectorAll(BUTTON_SELECTOR);
    const content = element.querySelectorAll(CONTENT_SELECTOR);
    content.forEach(value => {
      initSlide(element, buttons, value);
    })
  })
}

function initSlide(element, buttons, content) {
  // Get all element in content which can be focused
  // It allow us to change tabindex
  const focusableElements = content.querySelectorAll('a')

  // We calc inital height of the element to add correct height on the element
  // It prevent the animation to not trigger on the first click
  let isInitiallyHidden = content.getAttribute("aria-hidden");
  let initialHeight = null;


  if (isInitiallyHidden === "false") {

    initialHeight = content.offsetHeight;
    swapAttribute(element, "data-slide", "opened")
    setHeight(content, initialHeight)
    toggleTabIndex(focusableElements, '0');
  
  } else {
    
    // If the element is collapsed by default, we open it to calc the height and close it right after
    swapAttribute(content, "aria-hidden", "false")
    initialHeight = content.offsetHeight;
    swapAttribute(content, "aria-hidden", "true")
    // endif

    swapAttribute(element, "data-slide", "closed")
    setHeight(content)
    toggleTabIndex(focusableElements, '-1');
  }

  // Manage the button click to toggle content
  buttons.forEach(btn => {
    btn.addEventListener("click", e => {
      slideToggle(element, btn, content, initialHeight, focusableElements, e);
    });
  })
}

// Change the value of an element attribute
// @param {var} element : dom element  
// @param {string} attr : Name of the attribute you want to change  
// @param {string} attrValue : Value you want to have in your attribute 
function swapAttribute (element, attr, attrValue) {
  element.setAttribute(attr, attrValue)
}

// Change the value of all array elements attribute
// @param {var} element : dom element  
// @param {var} value : value to change  
function setHeight (element, value = 0) {
  if(value === 0 ){
    element.style.height = value;
  }else{
    element.style.height = `${value}px`;
  }
}

// Change the value of an element attribute
// @param {array} focusableElements : dom element (<a></a>) in a container (data-slide)  
// @param {string} value : Value of tabIndex  
function toggleTabIndex(focusableElements, value) {
  focusableElements.forEach(focusableElement => {
    focusableElement.setAttribute('tabindex', value)
  })
}

/**
 * slideToggle
 * Change multiple things :
 * - isHidden variable, used to determine if the slide is open or not
 * - the data-slide on the parent, used to display or not the buttons
 * - the aria-attrbute on content and button
 * - the tabindex on focusable element within the content
 */
function slideToggle(element, btn, content, initialHeight, focusableElements, e) {
  
  // get the aria-expanded attribute of the button clicked and convert it to a boolean 
  const isCurrentOpened = JSON.parse(e.target.getAttribute('aria-expanded').toLowerCase());
  
  // If the tab/slide is open close it 
  if(isCurrentOpened) {
    changeLabel(e.target, e.target.getAttribute('data-slide-btnClose'))
    resetAllState()
    return
  }
  
  // If the tab/slide clicked is close, close all tabs/slides then open the clicked one 
  resetAllState()
  if(!isCurrentOpened) {
    changeLabel(e.target, e.target.getAttribute('data-slide-btnOpen'))

    swapAttribute(element, "data-slide", "opened")
    swapAttribute(btn, "aria-expanded", "true")
    swapAttribute(content, "aria-hidden", "false")
    content.style.height = `${initialHeight}px`;
    toggleTabIndex(focusableElements, '0');
  }
  // open the the current tab clicked
}

// Change the value of an element attribute
// @param {array} focusableElements : dom element (<a></a>) in a container (data-slide)  
// @param {string} value : Value of tabIndex  
function changeLabel (element, text) {
  const hasLabel = element.getAttribute('data-slide-btn');
  if (hasLabel === "text"){
    element.querySelector('span').innerHTML = text
  }
}

// Close all "tabs" and reset their values 
function resetAllState(){
  // Get all the interactive slide content Element
  const containers = document.querySelectorAll(CONTAINER_SELECTOR);
  const contents = document.querySelectorAll(CONTENT_SELECTOR);
  const buttons = document.querySelectorAll(BUTTON_SELECTOR);
  const focusableElements = document.querySelectorAll('[data-slide] a')

  // Reset all values 
  swapAttributes(containers, "data-slide", "closed")
  swapAttributes(buttons, "aria-expanded", "false")
  swapAttributes(contents, "aria-hidden", "true")
  contents.forEach(content => {setHeight(content)});
  toggleTabIndex(focusableElements, '-1');
}

// Change the value of all array elements attribute
// @param {array} array : Array of dom elements  
// @param {string} attr : Name of the attribute you want to change  
// @param {string} attrValue : Value you want to have in your attribute 
function swapAttributes (array, attr, attrValue) {
  array.forEach(element => {
    element.setAttribute(attr, attrValue)
  });
}



export default { init }