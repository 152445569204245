<template>
  <div :class="quiz.type === 'Activity' ?'QuizQuestionActivity-QuizInputList' : 'QuizInputList' ">
    <div
      v-for="(question, index) in step.responses"
      :key="`${currentStepIndex}-${index}`"
      :class="`QuizInput-${step.type}`" class="QuizInput QuizQuestionWrapper"
    >
      <slot :index="index"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "QuizQuestionWrapper",
  props:{
    step: {
      required: true,
      type: Object
    },
    currentStepIndex: {
      required: true,
      type: Number
    },
    quiz:{
      required: true,
      type: Object,
    },
  },
}
</script>