import axios from 'axios'

let axiosClient = axios.create();

if (window.location.hostname === 'localhost') {
  axiosClient.defaults.baseURL = 'http://localhost:3000/'
}

axiosClient.defaults.headers.common['Strict-Transport-Security'] = 'max-age=84600; includeSubDomains;'
axiosClient.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axiosClient.defaults.headers.common['Access-Control-Allow-Origin'] = '*'

export { axiosClient }
