

const FILTERTAGS_SELECTOR = "[data-FilterTags]"
const FILTERTAGS_ATTRIBUTE_SELECTOR = "data-FilterTags-active"

function init() {
  
  const filterTags = document.querySelectorAll(FILTERTAGS_SELECTOR)
  
  if(!filterTags) return

  initFilters(filterTags)
  
}

function initFilters(filterTags){

  filterTags.forEach(filterTag => {
    
    filterTag.addEventListener( 'click' , (e) => {
      resetActiveFiltersAttribute(filterTags)
      toggleActive(filterTag)
    })
    
  });

}

function resetActiveFiltersAttribute(filterTags){
  filterTags.forEach(filterTag => {
    filterTag.setAttribute(FILTERTAGS_ATTRIBUTE_SELECTOR, 'false')
  });
}

//toggle the data-attribute
function toggleActive(filterTag) {
  
  // convert dataAttribute in Boolean 
  let isActive = isFilterActive(filterTag)
  filterTag.setAttribute(FILTERTAGS_ATTRIBUTE_SELECTOR, `${!isActive}`)

}

function isFilterActive(filterTag) {
  return (filterTag.getAttribute(FILTERTAGS_ATTRIBUTE_SELECTOR) === "true")
}

export default { init }


