document.addEventListener('DOMContentLoaded', (event) => {

  const HAMBURGER_BUTTON_SELECTOR = '[data-hamburger]'
  const NAVIGATION_TRIGGERS_SELECTOR = '[data-trigger]'
  const NAVIGATION_INTERACTIVE_ELEMENTS_SELECTOR = '[data-navigation]'


  let init = () => {

    const hamburgerBtn = document.querySelector(HAMBURGER_BUTTON_SELECTOR)
    const navigationTriggers = document.querySelectorAll(NAVIGATION_TRIGGERS_SELECTOR)
    // get all the element that we want to trigger the navigation
    const navigationInteractiveElements = document.querySelectorAll(NAVIGATION_INTERACTIVE_ELEMENTS_SELECTOR);
    
    if(isHidden(hamburgerBtn) !== 'true' ){
      return 
    }
    
    navigationTriggers.forEach(element => {
      element.addEventListener('click', () => {
        toggleNavigation(navigationInteractiveElements)
      })
    });
    
    // Show | hide the hambuger button depending of the screen width
    toggleHamburger(hamburgerBtn)


    /*
    //  This part(below) is commented because it create issues when the 
    //  When the user focus the input in searchBar on mobile device 
    //  Triggerring the resize event and so close the navigation 
    */

    // reset the navigation state on resize
    // window.addEventListener('resize', () =>{
    //   init()
    //   reset the navigation state
    //   closeNavigation(navigationInteractiveElements)
    // })

  }

  // @Element : element that trigger navigation closing 
  let closeNavigation = (elements) => {
    elements.forEach(element => {
      element.setAttribute('data-navigation', 'close')
    });
  }

  // @Element : element you want to hide 
  let hideElement = (element) => {
    element.setAttribute('aria-hidden' , 'true')
  }
  
  // @Element : element you want to show 
  let showElement = (element) => {
    element.setAttribute('aria-hidden' , 'false')
  }
  
  // @Element : is the hamburger button
  // @Element-HTML markup : [data-hamburger]
  let toggleHamburger = (element) =>{
    if (window.matchMedia("(min-width: 1100px)").matches){
      hideElement(element);
    }else{
      showElement(element);
    }

    element.addEventListener('click', () => {
      let isOpen = element.getAttribute('aria-expanded')  
      let focusLinkOnOpen = document.querySelector('.Navigation-homelink .Navigation-link')

      toggleAttribute(element, 'aria-expanded', 'true', 'false')
      
      if(isOpen === "true") {
        focusLinkOnOpen.focus()
      }

    })
  }

  // @param {array} Elements : All elements that have the [data-navigation] attribute
  let toggleNavigation = (elements) => {
    elements.forEach((element) => {
      toggleAttribute(element, 'data-navigation', 'close', 'open')
    });
    const navigationMobile = document.querySelector('.Navigation-mobile')
    navigationMobile.setAttribute('aria-hidden', 'false')
  }

  // @param {var} Element : dom element
  let isHidden = (element) => {
    return element.getAttribute('aria-hidden')
  }

  // @param {var} Element : dom element
  // @param {string} Attr : attribute name you want to toggle
  // @param {string} Value : the value at her fer state
  // @param {string} toggledValue : the value once the toggle has worked
  let toggleAttribute = (element, attr, value, toggledValue) => {
    if(element.getAttribute(attr) === value){
      element.setAttribute(attr, toggledValue)
    }else{
      element.setAttribute(attr, value)
    }
  }

  init()

});


