var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Quiz-Cta" }, [
    _vm.isNext
      ? _c(
          "button",
          {
            staticClass: "Cta Cta--dark",
            class:
              typeof this.selectedResponse !== "undefined"
                ? this.selectedResponse.length !== 0
                  ? ""
                  : "Cta--inactive"
                : "Cta--inactive",
            attrs: {
              "data-tracking-name": _vm.getTrackingName,
              "data-form-selector": "quiz-form",
              "data-tracking": "action",
              "data-tracking-chapter1": "quiz personnalisation contenu mb",
              "data-tracking-chapter2": "",
              "data-tracking-chapter3": ""
            },
            on: { click: _vm.handleNextStep }
          },
          [
            _vm.currentStepIndex < _vm.steps.length - 1
              ? _c("span", { staticClass: "Cta-label" }, [
                  _vm._v(_vm._s(_vm.labels.nextQuestion))
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.currentStepIndex === _vm.steps.length - 1
              ? _c("span", { staticClass: "Cta-label" }, [
                  _vm._v(_vm._s(_vm.labels.lastQuestion))
                ])
              : _vm._e()
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.currentStepIndex > 0 && _vm.isNext !== true
      ? _c("div", { staticClass: "QuizCta-previousButton" }, [
          _c(
            "button",
            {
              staticClass: "Cta Cta--light",
              on: {
                click: function($event) {
                  return _vm.handlePreviousStep()
                }
              }
            },
            [
              _c("span", [
                _c(
                  "svg",
                  {
                    staticClass: "icon icon-arrow-left",
                    attrs: {
                      width: "20",
                      height: "20",
                      xmlns: "http://www.w3.org/2000/svg",
                      viewBox: "0 0 512 512"
                    }
                  },
                  [
                    _c("path", {
                      attrs: {
                        fill: "currentColor",
                        d:
                          "M492 236H68.442l70.164-69.824c7.829-7.792 7.859-20.455.067-28.284-7.792-7.83-20.456-7.859-28.285-.068l-104.504 104c-.007.006-.012.013-.018.019-7.809 7.792-7.834 20.496-.002 28.314.007.006.012.013.018.019l104.504 104c7.828 7.79 20.492 7.763 28.285-.068 7.792-7.829 7.762-20.492-.067-28.284L68.442 276H492c11.046 0 20-8.954 20-20s-8.954-20-20-20z"
                      }
                    })
                  ]
                ),
                _vm._v(" "),
                _c("span", { staticClass: "Cta-label" }, [
                  _vm._v(_vm._s(_vm.labels.previousQuestion))
                ])
              ])
            ]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }