import * as navigationHelper from './helpers.js'

document.addEventListener('DOMContentLoaded', (event) => {

	// *************************
	// Navigation animation handlers
	// *************************

	// SELECTORS
	const HEADER_SELECTOR = '[data-header]'
	const NAVIGATION_CONTAINER_SELECTOR = '[data-navigation-container]'
	const NAVIGATION_CONTAINER_FIRSTLEVEL_SELECTOR = '[data-container=firstlevel]'
	const NAVIGATION_CONTAINER_SECONDLEVEL_SELECTOR = '[data-container=secondlevel]'
	const NAVIGATION_CONTAINER_THIRDLEVEL_SELECTOR = '[data-container=thirdlevel]'
	const NAVIGATION_LINKS_FIRSTLEVEL_SELECTOR = '[data-link="first-level"]'
	const NAVIGATION_LINKS_SECONDLEVEL_SELECTOR = '[data-link="second-level"]'
	const NAVIGATION_LINKS_THIRDLEVEL_SELECTOR = '[data-link="third-level"]'
	const NAVIGATION_RETURN_BUTTONS_SELECTOR = '[data-link-mobile] .Cta'
	const NAVIGATION_HEADER_LINKS_SELECTOR = '[data-header] a:not([data-link])'
	const NAVIGATION_SKIP_SELECTOR = '[data-navigation-skip]'
	const NAVIGATION_SKIP_TARGET_SELECTOR = '[data-navigation-skip-target]'

	// Container of links depending on their level
	const firstLevelNavigation = document.querySelector(NAVIGATION_CONTAINER_FIRSTLEVEL_SELECTOR)
	const secondLevelNavigation = document.querySelector(NAVIGATION_CONTAINER_SECONDLEVEL_SELECTOR)
	const thirdLevelNavigation = document.querySelector(NAVIGATION_CONTAINER_THIRDLEVEL_SELECTOR)
	// Links depending on their level
	const firstLevelNavigationLinks = document.querySelectorAll(NAVIGATION_LINKS_FIRSTLEVEL_SELECTOR)
	const secondLevelNavigationLinks = document.querySelectorAll(NAVIGATION_LINKS_SECONDLEVEL_SELECTOR)
	const thirdLevelNavigationLinks = document.querySelectorAll(NAVIGATION_LINKS_THIRDLEVEL_SELECTOR)
	// All non navigation links 
	const headerLinks = document.querySelectorAll(NAVIGATION_HEADER_LINKS_SELECTOR)
	// The "retour" back buttons in links containers (responsive)"
	const backButtons = document.querySelectorAll(NAVIGATION_RETURN_BUTTONS_SELECTOR)
	// button made for accessibility to access content without navigate through the navigation
	const navigationSkip = document.querySelector(NAVIGATION_SKIP_SELECTOR)
	const navigationSkipTarget = document.querySelector(NAVIGATION_SKIP_TARGET_SELECTOR)

	const toolLinks = Array.prototype.slice.call(document.querySelectorAll('.Navigation-tools .Cta'));
	const header = document.querySelector('.Header')

	function toolCtaFocus () {
		toolLinks.forEach(link => {
			link.addEventListener('focus', (e) => {
				e.stopPropagation();
				const subContainer = e.target.parentElement.parentElement.parentElement.parentElement
				header.setAttribute('data-navigation', 'open')
				subContainer.setAttribute('aria-hidden' , 'false')
			})
		});
	}

	// *************************
	// Navigation with focus triggers
	// *************************

	// Access behavior to skip navigation 
	function initSkipButtons(navigationSkip, navigationSkipTarget) {
		if(navigationSkip && navigationSkipTarget ){
			navigationSkip.addEventListener('keydown', (e) => {
				if (e.keyCode === 13) {
					navigationSkipTarget.focus()
				}
			}, true )
		}
	}

	function initFocusListenerOnNavigation(array) {

		const firstLevellinks = Array.prototype.slice.call(firstLevelNavigation);
		const secondLevellinks = Array.prototype.slice.call(secondLevelNavigationLinks);
		const thirdLevellinks = Array.prototype.slice.call(thirdLevelNavigationLinks);
		const links = [...firstLevellinks, ...secondLevellinks, ...thirdLevellinks, ...toolLinks]

		array.forEach((element) => {
			const nextSiblings = element.nextElementSibling
			element.addEventListener('focus', (e) => {

				// get the data-attribute that telle us if the link is first/second/third level
				const dataLink = element.getAttribute('data-link')
				const navigation = document.querySelector(NAVIGATION_CONTAINER_SELECTOR)

				navigation.setAttribute('data-navigation', 'open')

				// Close other subNavigation if focus is on a sublink without submenu and open the current one
				openCurrentNavigationSubmenu(element, dataLink, 'second-level', 'secondlevel')


				// remove the aria-expanded true on link that are not focused and close navigation subMenu
				closeThirdLevelNavigation(dataLink, 'first-level')

				// When the focus is on an element that has no sub-Navigation
				if (element.getAttribute('aria-expanded') === null) {

					// Close other subMenus (third level) (if you are on the the last link level) and open the current active one    
					closeAllThirdLevelNavigation(element)

					// Close other subMenus(second level) (if you are on the the last link level) and open the current active one    
					closeAllFirstLevelNavigation(element)

				} else {

					// if the link has a submenus, close all other submenus and open his own
					navigationHelper.resetValue(array)
					navigationHelper.openMenu(element, nextSiblings)
					
				}
				
			}, false)
		})

		firstLevelNavigationLinks.forEach(link => {
			link.addEventListener('blur', (e) => {
				if(header.getAttribute('data-navigation') === "open"){
					header.setAttribute('data-navigation', 'close')
				}
			})
		});
		
		firstLevelNavigationLinks.forEach(link => {
			link.addEventListener('focus', (e) => {
				if(header.getAttribute('data-navigation') === "close"){
					header.setAttribute('data-navigation', 'open')
				}
			})
		});

		links.forEach(link => {				
			link.addEventListener('focus', (e) => {
				header.setAttribute('data-navigation', 'open')
			})
		});

	}
	

	function openCurrentNavigationSubmenu(element, elementAttributeToTest, ElementAtribute, containerAttribute) {
		
		if (elementAttributeToTest === ElementAtribute) {
			navigationHelper.resetValue(firstLevelNavigationLinks);
			navigationHelper.openMenu(navigationHelper.getParentSibling(element, containerAttribute), navigationHelper.getParentSibling(element, containerAttribute).nextElementSibling)
		}

	}

	function closeThirdLevelNavigation(elementAttributeToTest, ElementAtribute) {

		if (elementAttributeToTest === ElementAtribute) {

			secondLevelNavigationLinks.forEach((link) => {

				if (link.nextSibling !== null) {

					navigationHelper.hideChildElement(link)
					navigationHelper.resetValue(firstLevelNavigationLinks)
					navigationHelper.resetValue(secondLevelNavigationLinks)

				}

			})

		}
	}

	function closeAllThirdLevelNavigation(element) {

		// get the link(in navigaiton lvl2) that have a subNavigation when the link is focus
		const currenParent = navigationHelper.getParentSibling(element, 'thirdlevel')
		if(currenParent !== undefined && currenParent !== null){

			const currenParentAttribute = currenParent.getAttribute('data-link')
			
			if (currenParentAttribute === "second-level") {
				
				navigationHelper.resetValue(secondLevelNavigationLinks)
				navigationHelper.openMenu(currenParent, currenParent.nextElementSibling)
				
			}
		}
	}

	function closeAllFirstLevelNavigation(element) {

		// get the link(in navigaiton lvl1) that have a subNavigation when the link is focus
		const firstParent = navigationHelper.getParentSibling(element, 'secondlevel')
		const currenFirstParentAttribute = firstParent.getAttribute('data-link')

		if (currenFirstParentAttribute == "first-level") {

			navigationHelper.resetValue(firstLevelNavigationLinks)
			navigationHelper.openMenu(firstParent, firstParent.nextElementSibling)

		}
	}

	// *************************
	// Navigation with hover triggers
	// *************************

	// @param {array} Array : List of all naviagationLinks
	function initHoverListenerOnNavigation(links) {
		links.forEach((link) => {

			// get the data-container (Navigation-subLinksContainer) (Navigation-lastLinks)
			const nextSibling = link.nextElementSibling
			const navigation = document.querySelector(NAVIGATION_CONTAINER_SELECTOR)
			const header = document.querySelector(HEADER_SELECTOR)

			if (nextSibling !== null) {

				link.addEventListener('mouseover', (e) => {
					navigation.setAttribute('data-navigation', 'open')
					header.setAttribute('data-navigation', 'open')
					navigationHelper.resetValue(links);
					navigationHelper.openMenu(link, nextSibling)
				}, false)
				
				link.addEventListener('mouseout', (e) => {
					navigation.setAttribute('data-navigation', 'close')
					header.setAttribute('data-navigation', 'close')
					navigationHelper.closeMenu(link, nextSibling)
				}, false)
				
				nextSibling.addEventListener('mouseover', (e) => {
					navigation.setAttribute('data-navigation', 'open')
					header.setAttribute('data-navigation', 'open')
					navigationHelper.openMenu(link, nextSibling)
				}, false)
				
				nextSibling.addEventListener('mouseout', (e) => {
					navigation.setAttribute('data-navigation', 'close')
					header.setAttribute('data-navigation', 'close')
					navigationHelper.closeMenu(link, nextSibling)
				}, false)

			}
		})
	}

	// *************************
	// Navigation with click triggers
	// *************************

	function initClickListenerOnNavigation(links) {

		links.forEach((link) => {

			const nextSiblings = link.nextElementSibling
			const navigation = document.querySelector(NAVIGATION_CONTAINER_SELECTOR)

			if (nextSiblings) {
				link.addEventListener('click', (e) => {
					
					if (link.getAttribute('aria-haspopup')) {
						e.preventDefault()
						navigationHelper.openMenu(link, nextSiblings)
					}

					if(link.getAttribute('data-sublink-menu') !== null){
						navigation.setAttribute('data-last-navigation-open', 'open')
					}
				}, false)
			}

		})
	}

	// Close the current menu when the return button is clicked 
	// @param {array} Array : array of all return buttons
	function closCurrentMenu(buttons) {

		buttons.forEach((button) => {
			button.addEventListener('click', (e) => {

				const currentParent = e.target.closest("[data-link-list]")
				let currentParentSibling = currentParent.previousElementSibling
				const currentParentAttribute = currentParent.getAttribute('data-link-list')
				const navigation = document.querySelector(NAVIGATION_CONTAINER_SELECTOR)

				if (currentParentAttribute !== null) {

					if (currentParentAttribute === "subLinks") {

						navigationHelper.hideElement(e.target.closest("[data-container=secondlevel]"))
						currentParentSibling = currentParentSibling.closest('[data-container]').previousElementSibling
						navigationHelper.hideChildElement(currentParentSibling)

					} else if (currentParentAttribute === "lastLinks") {

						navigation.setAttribute('data-last-navigation-open', 'close')
						navigationHelper.hideChildElement(currentParentSibling)
						navigationHelper.hideElement(e.target.closest("[data-container=thirdlevel]"))

					}

				}

			})
		})
	}

	function preventBackButtonsFocus() {
		backButtons.forEach(button => {
			button.setAttribute('tabindex', '-1')
		});
	}

	// Hide all submenus 
	function hideAll() {

		navigationHelper.hideChildElement(thirdLevelNavigation)
		navigationHelper.hideChildElement(secondLevelNavigation)
		navigationHelper.hideElement(thirdLevelNavigation)
		navigationHelper.hideElement(secondLevelNavigation)
		navigationHelper.hideElement(firstLevelNavigation)

	}

	// Init all script for navigation to work for desktop
	function initDesktop() {

		preventBackButtonsFocus()
		initHoverListenerOnNavigation(firstLevelNavigationLinks)
		initHoverListenerOnNavigation(secondLevelNavigationLinks)
		initFocusListenerOnNavigation(firstLevelNavigationLinks)
		initFocusListenerOnNavigation(secondLevelNavigationLinks)
		initFocusListenerOnNavigation(thirdLevelNavigationLinks)
		initSkipButtons(navigationSkip,navigationSkipTarget)
	
		// Close navigation if a link that is not a navigation link is focused
		headerLinks.forEach((elem) => {
			elem.addEventListener('focus', (e) => {
				navigationHelper.resetValue(firstLevelNavigationLinks)
			})
		})

		toolCtaFocus()

	}

	// Init all script for navigation to work for tablet and mobile
	function initMobile() {

		closCurrentMenu(backButtons)
		initClickListenerOnNavigation(firstLevelNavigationLinks)
		initClickListenerOnNavigation(secondLevelNavigationLinks)
		initSkipButtons(navigationSkip,navigationSkipTarget)

	}

	function init() {

		const header = document.querySelector('[data-header]')

		if (header === null) {
			return
		}

		if (window.matchMedia("(min-width: 1100px)").matches) {
			initDesktop()
		} else {
			initMobile()
		}

		window.addEventListener('resize', (e) => {
			hideAll()
		});

	}

	init()

	// closest polyfill
	if (!Element.prototype.closest) {
		if (!Element.prototype.matches) {
			Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
		}
		Element.prototype.closest = function (s) {
			var el = this;
			var ancestor = this;
			if (!document.documentElement.contains(el)) return null;
			do {
				if (ancestor.matches(s)) return ancestor;
				ancestor = ancestor.parentElement;
			} while (ancestor !== null);
			return null;
		};
	}

});


