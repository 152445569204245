<template>
  <ul class="SPFPlayer-playlist">
    <li class="SPFPlayer-playlistItem" :class="{ 'is-active' : currentMediaIndex == index }" v-for="(media, index) in medias" :key="media.id">
      <button class="SPFPlayer-playlistButton" @click="updateMedia(index)" :disabled="currentMediaIndex == index ? true : false">
        <header class="SPFPlayer-playlistButtonHeader">
          <img class="SPFPlayer-playlistButtonImage" :src="media.image.src" :alt="media.image.alt">
        </header>
        <main class="SPFPlayer-playlistButtonContent">
          <span class="SPFPlayer-playlistButtonTitle">{{ media.title }}</span>
          <span class="SPFPlayer-playlistButtonDuration">{{ media.duration }}</span>
          <div class="SPFPlayer-playlistButtonDate" v-if="dateOnPreview">{{ media.date }}</div>
          <div class="SPFPlayer-playlistButtonDescription" v-if="descriptionOnPreview" v-html="media.description"></div>
        </main>
      </button>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'Playlist',
  props: {
    eventBus: {
      required: true,
      type: Object
    },
    originMedias: {
      required: true,
      type: Array
    },
    descriptionOnPreview: {
      required: false,
      type: Boolean,
      default: false
    },
    dateOnPreview: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      medias: [],
      currentMediaIndex: ''
    }
  },
  methods: {
    // Notify the player to change the current media on click on playlist element
    updateMedia(mediaIndex) {
      if (this.medias[mediaIndex] !== undefined) {
        this.eventBus.$emit('Playlist::UpdateMedia', mediaIndex)
      }
    }
  },
  mounted() {
    this.medias = this.originMedias
    this.currentMediaIndex = 0

    this.eventBus.$on('Player::UpdateMedia', ({ index } = payload) => {
      this.currentMediaIndex = index
    })
  }
}
</script>
