<template>
  <div class="QuizInput-row">

    <div>
      <label class="QuizInput-label">Nombre d'heures</label>
      <input
        v-model="selectedHoursResponse"
        class="QuizInput-Number"
        :type="step.type"
        :id="`step.name${index}`"
        :name="step.name[index]"
        :value="step.responses[index].value"
        min="0"
        autocomplete="off"
        @input="handleEmitResponse"
        @change="handleEmitResponse"
      >
      <div class="error" v-if="!isNumberValid(selectedHoursResponse, 0, 23)">
        chiffre invalide (entre 0 et 23)
      </div>
    </div>

    <div>

      <label class="QuizInput-label">Nombre de minutes</label>

      <input
        v-model="selectedMinutesResponse"
        class="QuizInput-Number"
        :type="step.type"
        :id="`step.name${index}`"
        :name="step.name[index]"
        :value="step.responses[index].value"
        min="0"
        autocomplete="off"
        @input="handleEmitResponse"
        @change="handleEmitResponse"
      >

      <div class="error" v-if="!isNumberValid(selectedMinutesResponse, 0, 59)">chiffre invalide (entre 0 et 59)</div>

    </div>

  </div>
</template>

<script>
export default {
  name: "QuizNumber",
  props: {
    step: {
      required: true,
      type: Object
    },
    index: {
      required: true,
      type: Number
    },
  },
  data() {
    return {
      selectedResponse: [],
      selectedHoursResponse: null,
      selectedMinutesResponse: null
    }
  },
  methods: {
    convertValueTominutes() {
      const minutes = this.selectedMinutesResponse !== null ? this.selectedMinutesResponse : 0
      const hours = this.selectedHoursResponse !== null ? this.selectedHoursResponse : 0
      return (parseInt(hours) * 60 + parseInt(minutes)).toString()
    },
    isNumberValid: function (number, min, max) {
      var regex = /^\d+$/g;
      return !!((number === null) || (number !== null & regex.test(number) && number >= min && number <= max));
    },
    handleEmitResponse() {
      if (this.isNumberValid(this.selectedMinutesResponse, 0, 59) && this.isNumberValid(this.selectedHoursResponse, 0, 23)) {
        this.$emit('QuizNumber::handle-selected-response', this.convertValueTominutes())
      } else {
        this.$emit('QuizNumber::handle-selected-response', [])
      }
    }
  },
}
</script>