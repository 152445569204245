var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "QuizQuestionActivity" },
    [
      _c("QuizCta", {
        attrs: {
          isNext: false,
          labels: _vm.labels,
          currentStepIndex: _vm.currentStepIndex,
          steps: _vm.steps,
          selectedResponse: _vm.selectedResponse
        },
        on: { "QuizPrevious::previous-step": _vm.handlePreviousStep }
      }),
      _vm._v(" "),
      _c("QuizHeader", { attrs: { step: _vm.step } }),
      _vm._v(" "),
      _c(
        "div",
        {
          class:
            _vm.quiz.type === "Activity"
              ? "QuizQuestionActivity-QuizInputList"
              : "QuizInputList"
        },
        _vm._l(_vm.step.responses, function(question, index) {
          return _c(
            "div",
            {
              key: _vm.currentStepIndex + "-" + index,
              staticClass: "QuizInput QuizQuestionWrapper",
              class: "QuizInput-" + _vm.step.type
            },
            [
              _c(_vm.getcomponentType, {
                tag: "component",
                attrs: {
                  step: _vm.step,
                  currentStepIndex: _vm.currentStepIndex,
                  index: index
                },
                on: {
                  "QuizRadio::handle-selected-response":
                    _vm.handleSelectedResponse,
                  "QuizCheckbox::handle-selected-response":
                    _vm.handleSelectedResponse,
                  "QuizSelect::handle-selected-response":
                    _vm.handleSelectedResponse,
                  "QuizNumber::handle-selected-response":
                    _vm.handleSelectedResponse
                }
              })
            ],
            1
          )
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "QuizQuestionActivity-footer" },
        [
          _c("QuizCta", {
            attrs: {
              isNext: true,
              labels: _vm.labels,
              steps: _vm.steps,
              quiz: _vm.quiz,
              currentStepIndex: _vm.currentStepIndex,
              selectedResponse: _vm.selectedResponse
            },
            on: { "QuizNext::next-step": _vm.handleQuestionSubmit }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.step.quote ? _c("QuizQuote", { attrs: { step: _vm.step } }) : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }