var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "SPFPlayer-player",
      class: { "is-playing": _vm.isPlaying },
      attrs: {
        "data-tracking": "action",
        "data-tracking-name": "lancer_" + _vm.mediaType + "_#page_template#_mb",
        "data-tracking-chapter1": "fichiers ressources",
        popin_name: _vm.isModal
          ? "popin lancer_" + _vm.originMedias[0].title + "_mb"
          : ""
      }
    },
    [
      _vm.mediaType === "video" && _vm.playButton.isVisible
        ? _c(
            "button",
            {
              staticClass: "SPFPlayer-playerButton",
              on: { click: _vm.playMedia }
            },
            [
              _vm.mediaPoster
                ? _c("img", {
                    attrs: {
                      src: _vm.mediaPoster.src,
                      alt: _vm.mediaPoster.alt
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "SPFPlayer-playerButton--svg" }, [
                _c(
                  "svg",
                  {
                    attrs: {
                      width: "700pt",
                      height: "700pt",
                      viewBox: "0 0 615 600",
                      xmlns: "http://www.w3.org/2000/svg",
                      "xmlns:xlink": "http://www.w3.org/1999/xlink"
                    }
                  },
                  [
                    _c("path", {
                      attrs: {
                        d:
                          "M510.31 264.34 209.29 90.54c-6.0586-3.5-12.473-3.5-18.543 0-6.0703 3.5039-8.7461 9.9727-8.7461 16.98v347.6c0 7 2.6758 13.473 8.7422 16.973 3.0352 1.7539 5.8867 2.625 9.2734 2.625 3.3828 0 6.5-.875 9.5352-2.625l300.89-173.8c6.0664-3.5 9.7344-9.9727 9.7344-16.973 0-7.0078-3.8047-13.473-9.8672-16.98z"
                      }
                    })
                  ]
                )
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.mediaType === "video"
        ? _c(
            "video",
            {
              staticClass: "SPFPlayer-mediaPlayer",
              attrs: {
                "data-spf-player-mediaPlayer": "",
                controls: "",
                disablePictureInPicture: "",
                controlsList: "nodownload noplaybackrate"
              }
            },
            [
              _vm._l(_vm.mediaSources, function(source, index) {
                return _c("source", {
                  key: "source-" + index,
                  attrs: {
                    src: source.src,
                    type: source.type ? source.type : ""
                  }
                })
              }),
              _vm._v(" "),
              _vm._l(_vm.mediaSubtitles, function(subtitle, index) {
                return _c("track", {
                  key: "subtitle-" + index,
                  attrs: {
                    label: subtitle.label ? subtitle.label : "",
                    srclang: subtitle.srcLang ? subtitle.srcLang : "",
                    src: subtitle.src,
                    kind: "subtitles"
                  }
                })
              })
            ],
            2
          )
        : _vm.mediaType === "audio"
        ? _c(
            "audio",
            {
              staticClass: "SPFPlayer-mediaPlayer",
              attrs: {
                "data-spf-player-mediaPlayer": "",
                controls: "",
                disablePictureInPicture: "",
                controlsList: "nodownload noplaybackrate"
              }
            },
            _vm._l(_vm.mediaSources, function(source, index) {
              return _c("source", {
                key: "source-" + index,
                attrs: { src: source.src, type: source.type ? source.type : "" }
              })
            }),
            0
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }