var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "label",
      {
        staticClass: "QuizInput-selectLabel",
        attrs: { for: "step.name" + _vm.index }
      },
      [_vm._v("Nombre de jours")]
    ),
    _vm._v(" "),
    _c(
      "select",
      {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.selectedResponse,
            expression: "selectedResponse"
          }
        ],
        staticClass: "QuizInput-selectInput",
        attrs: { name: _vm.step.name, id: "step.name" + _vm.index },
        on: {
          change: [
            function($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function(o) {
                  return o.selected
                })
                .map(function(o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.selectedResponse = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            },
            _vm.handleEmitResponse
          ]
        }
      },
      [
        _c("option", { attrs: { value: "", selected: "", disabled: "" } }, [
          _vm._v("Choisir Une option")
        ]),
        _vm._v(" "),
        _vm._l(_vm.step.options, function(option, index) {
          return _c(
            "option",
            {
              key: _vm.currentStepIndex + "-" + index,
              domProps: { value: option.value }
            },
            [_vm._v(_vm._s(option.label))]
          )
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }