import "matchmedia-polyfill/matchMedia"
import "matchmedia-polyfill/matchMedia.addListener"

const USER_MENU_BUTTON_SELECTOR = '[data-user-menu-button]'
const USER_MENU_SELECTOR = '[data-user-menu]'

const userMenu = document.querySelector(USER_MENU_SELECTOR)
const userMenuButton = document.querySelector(USER_MENU_BUTTON_SELECTOR)

function init() {
  
  if(!userMenu || !userMenuButton) return
  
  if (window.matchMedia("screen and (min-width: 1100px)").matches) {
    initMenu()
  }
  
}

function initMenu() {
  userMenuButton.addEventListener('click', (e) => {
    e.preventDefault();
    toggleMenu()
  })

  document.addEventListener('click', (e) => {
    if(e.target !== userMenuButton){
      hideMenu()
    }
  })
}

function toggleMenu() {
  let isVisible = (userMenuButton.getAttribute('aria-expanded') === 'true')
  
  if(isVisible){
    hideMenu()
  }else {
    showMenu()
  }
}

function showMenu() {
  userMenuButton.setAttribute('aria-expanded', 'true')
  userMenu.setAttribute('aria-hidden', 'false')
}

function hideMenu() {
  userMenuButton.setAttribute('aria-expanded', 'false')
  userMenu.setAttribute('aria-hidden', 'true')
}

export default { init }