var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "QuizStep" },
    [
      _c(
        _vm.quiz.type !== "Activity" ? "QuizQuestion" : "QuizQuestionActivity",
        {
          key: _vm.currentStepIndex,
          tag: "component",
          attrs: {
            step: _vm.steps[_vm.currentStepIndex],
            steps: _vm.steps,
            currentStepIndex: _vm.currentStepIndex,
            labels: _vm.labels,
            quiz: _vm.quiz
          },
          on: {
            "QuizQuestion::next-step": _vm.handleNextStep,
            "QuizQuestion::previous-step": _vm.handlePreviousStep,
            "QuizQuestion::on-stage-answer": _vm.handleStageAnswer,
            "QuizQuestionActivity::next-step": _vm.handleNextStep,
            "QuizQuestionActivity::previous-step": _vm.handlePreviousStep,
            "QuizQuestionActivity::on-stage-answer": _vm.handleStageAnswer
          }
        }
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }