var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "QuizStart" }, [
    _c("img", {
      staticClass: "QuizStart-image",
      attrs: { src: _vm.picture, alt: "Image du Quiz" }
    }),
    _vm._v(" "),
    _c("h2", { staticClass: "QuizStart-title title2 titleBackground" }, [
      _c("span", [_vm._v(_vm._s(_vm.labels.startTitle))])
    ]),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "Cta Cta--dark",
        attrs: {
          "data-tracking": "action",
          "data-tracking-name": "amorcer_commencer_quizz_#page_template#_mb",
          "data-tracking-chapter1": "quiz personnalisation contenu mb",
          "data-tracking-chapter2": "",
          "data-tracking-chapter3": ""
        },
        on: {
          click: function($event) {
            return _vm.handleNextStep()
          }
        }
      },
      [
        _c("span", { staticClass: "Cta-label" }, [
          _vm._v(_vm._s(_vm.labels.startButtonLabel))
        ])
      ]
    ),
    _vm._v(" "),
    !_vm.quiz.isConnected
      ? _c("p", { staticClass: "QuizStart-connectionText" }, [
          _vm._v(_vm._s(_vm.labels.startConnectionLabel))
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.quiz.isConnected
      ? _c(
          "a",
          {
            staticClass: "Cta Cta--light",
            attrs: {
              href: _vm.keycloakConnectionUrl,
              "data-tracking": "action",
              "data-tracking-name": "amorcer_je_m identifie_#page_template#_mb",
              "data-tracking-chapter1": "action mon compte mb",
              "data-tracking-chapter2": "",
              "data-tracking-chapter3": ""
            }
          },
          [
            _c("span", { staticClass: "Cta-label" }, [
              _vm._v(_vm._s(_vm.labels.startConnectionButtonLabel))
            ])
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }