const CONTAINER_SEL = "[data-see-more]"
const BUTTON_SEL = "[data-see-more-btn]"
const TEXT_SEL = "[data-see-more-text]"
const IS_SLIDER = document.querySelectorAll('.PromotedSliderIntro-intro[data-see-more]')

function init() {
  const containers = document.querySelectorAll(CONTAINER_SEL)

  if (!containers.length) return

  // Corresponding to 3 lines
  // Will convert to px

  let heightToDisplay
  if (window.matchMedia("(min-width: 1024px)").matches) {
    heightToDisplay = '275'
  }else {
    heightToDisplay = '72'
  }
  
  containers.forEach(container => {
    const btn = container.querySelector(BUTTON_SEL)

    const text = container.querySelector(TEXT_SEL)
    const hasHeadings = text.querySelectorAll('h2, h3, h4').length
    
    if (hasHeadings) {
      let headingsHeight = 0
      text.querySelectorAll('h2, h3, h4').forEach(heading => {
        headingsHeight += heading.clientHeight
      }
      )
      heightToDisplay = 275 + headingsHeight
    }
    
    // Get initial height
    const initialTextHeight = text.clientHeight

    // If the text is smaller than our limit, we quit (for slider desktop if text is bigger than 308px)
    if(IS_SLIDER) {
      if (initialTextHeight <= 308 && window.matchMedia("(min-width: 1024px)").matches) return
    } else {
      if (initialTextHeight <= heightToDisplay) return
    }

    // display the btn
    btn.style.display = 'block'

    // Shrink text
    text.style.maxHeight = `${heightToDisplay}px`

    // Add toogle to button
    btn.addEventListener('click', function() {
      toggleExpand(container, text, initialTextHeight, heightToDisplay, btn)
    })
  });
}

function toggleExpand(container, text, initialTextHeight, heightToDisplay, btn) {

  let isOpen = container.dataset.seeMoreIsOpen

  if (isOpen === 'false') {

    text.style.maxHeight = `10000px`
    container.dataset.seeMoreIsOpen = 'true'

  } else if (isOpen === 'true') {

    text.style.maxHeight = `${heightToDisplay}px`
    container.dataset.seeMoreIsOpen = 'false'

  }

  toggleBtnLabel(btn, isOpen)
}

function toggleBtnLabel(btn, isOpen) {

  const btnLabelOpened = btn.querySelector('[data-see-more-btn-opened]')
  const btnLabelClosed = btn.querySelector('[data-see-more-btn-closed]')

  if (isOpen === 'false') {

    btnLabelOpened.style.display = 'block'
    btnLabelClosed.style.display = 'none'

  } else if (isOpen === 'true') {
    
    btnLabelOpened.style.display = 'none'
    btnLabelClosed.style.display = 'block'
    
  }
}

export default { init }