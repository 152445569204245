import Drawer from '../../scripts/drawer' ; 

const BUTTON_INPAGE_SELECTOR = "[data-drawer-filter-inPageBtn]";
const BUTTON_SELECTOR = "[data-drawer-btn]";
const DRAWER_SELECTOR = "[data-drawer]";

// Get necessary element so the Drawer can be used
const inPageButtons = document.querySelectorAll(BUTTON_INPAGE_SELECTOR);
const drawerContainer = document.querySelector(DRAWER_SELECTOR)
const drawerButtons = document.querySelectorAll(BUTTON_SELECTOR)

// Create an instance of the drawerContainer
const filterDrawerInstance = new Drawer(inPageButtons, drawerContainer, drawerButtons)

// Export the instance 
export default filterDrawerInstance