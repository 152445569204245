var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "SPFPlayer-playlist" },
    _vm._l(_vm.medias, function(media, index) {
      return _c(
        "li",
        {
          key: media.id,
          staticClass: "SPFPlayer-playlistItem",
          class: { "is-active": _vm.currentMediaIndex == index }
        },
        [
          _c(
            "button",
            {
              staticClass: "SPFPlayer-playlistButton",
              attrs: {
                disabled: _vm.currentMediaIndex == index ? true : false
              },
              on: {
                click: function($event) {
                  return _vm.updateMedia(index)
                }
              }
            },
            [
              _c("header", { staticClass: "SPFPlayer-playlistButtonHeader" }, [
                _c("img", {
                  staticClass: "SPFPlayer-playlistButtonImage",
                  attrs: { src: media.image.src, alt: media.image.alt }
                })
              ]),
              _vm._v(" "),
              _c("main", { staticClass: "SPFPlayer-playlistButtonContent" }, [
                _c("span", { staticClass: "SPFPlayer-playlistButtonTitle" }, [
                  _vm._v(_vm._s(media.title))
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  { staticClass: "SPFPlayer-playlistButtonDuration" },
                  [_vm._v(_vm._s(media.duration))]
                ),
                _vm._v(" "),
                _vm.dateOnPreview
                  ? _c("div", { staticClass: "SPFPlayer-playlistButtonDate" }, [
                      _vm._v(_vm._s(media.date))
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.descriptionOnPreview
                  ? _c("div", {
                      staticClass: "SPFPlayer-playlistButtonDescription",
                      domProps: { innerHTML: _vm._s(media.description) }
                    })
                  : _vm._e()
              ])
            ]
          )
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }