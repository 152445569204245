//Selectors
const SEASON_FILTER_SELECTOR = '[data-search-seasonFilter]'
const FOOD_SELECTOR = '[data-search-food]'

function init() {

  const seasonFilter = document.querySelectorAll(SEASON_FILTER_SELECTOR);
  const listeners = ['click', 'focus']
  const filters = document.querySelectorAll('.Seasonfilter-filters .Cta')

  if (!seasonFilter) {
    return
  }

  // Loop trough all filters
  filters.forEach((filter) => {
    // Add every listeners to the filter
    listeners.forEach((listener) => {
      // Listen the object
      filter.addEventListener(listener, (e) => {
        resetAttribute(filters);
        filter.setAttribute('data-active', 'true')
        ajaxFiltersFoods(filter)
      })
    })
  })
}

function resetAttribute(array) {
  array.forEach((elem) => {
    elem.setAttribute('data-active', 'false')
  })
}

function ajaxFiltersFoods(filter) {

  const foodList = document.querySelectorAll(FOOD_SELECTOR)

  foodList.forEach((food) => {
    if (filter.dataset.category === food.dataset.searchFoodCategory || filter.dataset.category === 'all') {
      food.style.display = null
    } else {
      food.style.display = 'none'
    }
  })
}

export default {init}