import "matchmedia-polyfill/matchMedia"
import "matchmedia-polyfill/matchMedia.addListener"

document.addEventListener('DOMContentLoaded', (event) => {
	// *************************
	// Sticky header handler
	// *************************

	const HEADER_SELECTOR = '[data-header]'
	const TOPBAR_SELECTOR = '[data-topbar]'
	
	// get the element(Header) we interact with
	const header = document.querySelector(HEADER_SELECTOR);
	const topbar = document.querySelector(TOPBAR_SELECTOR);

	let topbarHeight = topbar.offsetHeight; // Should be around 110px
	let scrollValue = window.scrollY;
	let topValue

	let init = () => {
		
		if(header === null){
			return
		}

		// To be sure the header is well positionned when the page loads
		header.style.top = topValue;
		// passive true to tell browser you don't mess with the scroll itself
		window.addEventListener("scroll", runOnScroll, {passive: true});
	}
	
	let runOnScroll = () =>{

		scrollValue = window.pageYOffset;
		topValue = topbarHeight - scrollValue

		if (window.matchMedia("screen and (min-width: 1100px)").matches) {

			if ( scrollValue >= topbarHeight ){

				header.style.top = -topbarHeight + "px";

			}else if( scrollValue >= 0 && scrollValue <= topbarHeight ){

				header.style.top = -topbarHeight + topValue + "px"
				
			}

		} else {
			header.style.top = 0
		}
	}

	init()

})